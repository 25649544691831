import { PushPinSimple } from '@phosphor-icons/react'
import clsx from 'clsx'
import React from 'react'

import HeaderSytle from './Line.module.scss'

interface LineHeaderProps {
  title?: string
  color?:
    | 'indigo'
    | 'blue'
    | 'pink'
    | 'green'
    | 'red'
    | 'gray'
    | 'default'
    | 'lightgray'
  pinned?: boolean
  align?: 'start' | 'center' | 'end'
  className?: string
  headerClassName?: string
  icon?: React.ReactNode
}

export default function LineHeader({
  className,
  headerClassName,
  title,
  color = 'gray',
  pinned,
  align = 'start',
}: LineHeaderProps) {
  const HeaderClass = [HeaderSytle['hnui-line-header']]
  const AlignClass = [HeaderSytle['hnui-line-header-align']]

  if (align) AlignClass.push(HeaderSytle[`hnui-line-header-align-${align}`])
  if (align) HeaderClass.push(HeaderSytle[`hnui-line-header-${align}`])
  if (color) HeaderClass.push(HeaderSytle[`hnui-line-header-${color}`])
  if (headerClassName) HeaderClass.push(headerClassName)

  return (
    <div className={clsx(className, 'relative')}>
      <div className={HeaderSytle['hnui-line-container']} aria-hidden='true'>
        <div className={HeaderSytle['hnui-line-line']} />
      </div>
      <div className={[...AlignClass, 'px-2'].join(' ')}>
        <span className={HeaderClass.join(' ')}>
          {pinned && (
            <PushPinSimple className='h-[14px] w-[14px]' weight='fill' />
          )}
          <span>{title || 'Hello!'}</span>
        </span>
      </div>
    </div>
  )
}
