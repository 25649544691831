import React, { useContext, useMemo } from 'react'

import FieldBlock from '@/components/shared/components/FieldBlock'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Input from '@/components/shared/ui/Input'
import Textarea from '@/components/shared/ui/Textarea'
import HNContext from '@/context/HNContext'
import type { IBucketMetaCustomField } from '@/types/organization'

interface IPropTypes {
  bucketId?: string
  onChange?: (field: string, value: string | boolean | number) => void
  errors?: Record<string, string>
}

export default function NewPostCustomFieldsForm({
  bucketId,
  errors,
  onChange,
}: IPropTypes) {
  const { buckets_meta } = useContext(HNContext)

  const bucketMeta = useMemo(() => {
    if (!bucketId) return null
    return buckets_meta?.find(
      (meta) => meta.bucket_id.toString() === bucketId.toString()
    )
  }, [buckets_meta, bucketId])

  const renderField = (field: IBucketMetaCustomField) => {
    switch (field.field_type) {
      // Based on USER_ATTRIBUTE_FIELD_TYPES
      case 'short_text':
        return (
          <Input
            onChange={(e) => onChange?.(field.slug, e.target.value)}
            placeholder={field.meta_info?.placeholder}
            maxLength={255}
          />
        )
      case 'long_text':
        return (
          <Textarea
            onChange={(e) => onChange?.(field.slug, e.target.value)}
            placeholder={field.meta_info?.placeholder}
          />
        )
      case 'integer':
        return (
          <Input
            type='number'
            onChange={(e) => {
              const value = Number(e.target.value)
              if (Number.isNaN(value)) return
              onChange?.(field.slug, value)
            }}
            placeholder={field.meta_info?.placeholder}
          />
        )
      case 'boolean':
        return (
          <Checkbox
            label={field.title}
            mandatory={field.mandatory}
            onCheckedChange={(checked) => onChange?.(field.slug, checked)}
            description={field.meta_info?.description}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {bucketMeta?.custom_fields
        .filter((field) => field.show_in_form)
        .map((field) => (
          <FieldBlock
            key={field.slug}
            error={errors?.[field.slug]}
            label={field.field_type === 'boolean' ? '' : field.title}
            mandatory={field.mandatory}
            wrapLabel={field.field_type === 'boolean'}
          >
            {renderField(field)}
          </FieldBlock>
        ))}
    </>
  )
}
