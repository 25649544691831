import clsx from 'clsx'
import { observer } from 'mobx-react'
import dynamic from 'next/dynamic'
import React, { memo, useContext } from 'react'
import { areEqual } from 'react-window'

import AdminPostItem from '@/components/admin/posts/AdminPostItem'
import AdminPostItemSentimentIcon from '@/components/admin/posts/AdminPostItemSentimentIcon'
import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import {
  ContextMenu,
  ContextTrigger,
} from '@/components/shared/ui/ContextMenu/ContextMenu'
import Spinner from '@/components/shared/ui/Loader'
import HNContext from '@/context/HNContext'
import postStore from '@/stores/PostListStore'
import type { IPost, IPostItemModules } from '@/types/post'

const AdminPostContextMenu = dynamic(
  () => import('@/components/admin/posts/AdminPostContextMenu'),
  {
    ssr: false,
  }
)

interface IPropTypes {
  index: number
  style: any
  data: {
    showHighlight?: boolean
    showPostDescription?: boolean
    posts: IPost[]
    onCheck: (event: any, postId: string) => void
    onUpdate: (post: IPost) => void
    isModeration?: boolean
    newTabOpen?: boolean
    hideModules: IPostItemModules
    canFetchMore: boolean
  }
}
const AdminPostItemRow = observer(({ index, style, data }: IPropTypes) => {
  const { userProfile } = useContext(HNContext)
  const { selection } = postStore
  const {
    posts,
    showPostDescription,
    showHighlight,
    onCheck,
    hideModules,
    newTabOpen,
    onUpdate,
    isModeration,
    canFetchMore,
  } = data

  const post = posts[index]

  if (!post) {
    if (!canFetchMore) {
      return null
    }

    return (
      <div key={index} style={style}>
        {<Spinner />}
      </div>
    )
  }

  return (
    <div key={index} style={{ ...style, display: 'flex' }}>
      <AdminPostItemSentimentIcon post={post} />
      <ContextMenu>
        <ContextTrigger
          asChild
          className='w-full !border-solid hover:!bg-primary/10 data-[state=open]:bg-gray5'
        >
          <div
            className={clsx(
              'flex items-center border-b-[0.5px] border-b-gray4 transition-colors duration-150 focus-visible:bg-gray6/40 focus-visible:outline-none   ',
              !showHighlight || post?.viewed
                ? 'data-[state=closed]:bg-gray3'
                : 'transition-colors duration-150 data-[state=closed]:bg-gray1',
              showPostDescription ? 'h-[47.5px]' : 'h-[38px]'
            )}
          >
            <div className='mx-auto flex w-full max-w-7xl shrink-0 items-center justify-between gap-2 overflow-x-hidden px-2 font-medium md:pl-2 md:pr-4'>
              <AuthenticatedView
                shouldShow={
                  Boolean(userProfile?.is_member_of_organization) &&
                  !hideModules.includes('selectBox')
                }
                accessableRoles={['admin', 'member']}
              >
                <span className='hidden md:block'>
                  <Checkbox
                    id={post.id}
                    checked={
                      selection.selectedIds.includes(post.id) ||
                      (selection.selectedAll &&
                        !selection.ignoredIds.includes(post.id))
                    }
                    onClick={(event: any) => onCheck(event, post.id)}
                  />
                </span>
              </AuthenticatedView>
              <AdminPostItem
                post={post}
                key={post.id}
                onUpdate={onUpdate}
                isModeration={isModeration}
                newTabOpen={newTabOpen}
                hideModules={hideModules}
                highlight={showHighlight ? !post?.viewed : false}
              />
            </div>
          </div>
        </ContextTrigger>
        {post && <AdminPostContextMenu post={post} />}
      </ContextMenu>
    </div>
  )
})

export default memo(AdminPostItemRow, areEqual)
