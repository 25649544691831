import { X } from '@phosphor-icons/react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import clsx from 'clsx'
import type { FC, ReactNode, Ref } from 'react'
import React, { useState } from 'react'

import clsxm from '@/lib/clsxm'

import DialogStyle from './Dialog.module.scss'

interface DialogPropType extends DialogPrimitive.DialogProps {
  children?: React.ReactNode
  onOpen?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
  modal?: boolean
  className?: string
  asChild?: boolean
}

interface DialogContentPropType extends DialogPrimitive.DialogContentProps {
  backdrop?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full'
  container?: HTMLElement | undefined | null
  overlay?: Boolean
  mode?: 'full' | 'aside' | 'drawer'
  className?: string
  headerTitle?: string
  rightComponents?: ReactNode
}

export const DialogContent = ({
  size = 'sm',
  children,
  backdrop,
  container,
  // overlay = true,
  className = '',
  mode,
  headerTitle,
  rightComponents,
  ...props
}: DialogContentPropType) => {
  const [backdropAnimate, setBackdropAnimate] = useState(false)
  const DialogClasses =
    size && !mode
      ? [DialogStyle['hnui-dialog-content']]
      : [DialogStyle['hnui-dialog-mode']]
  if (size && !mode)
    DialogClasses.push(DialogStyle[`hnui-dialog-content-${size}`])
  if (mode) DialogClasses.push(DialogStyle[`hnui-dialog-mode-${mode}`])
  if (backdropAnimate)
    DialogClasses.push(DialogStyle['hnui-dialog-content-animation'])

  const handleBackdrop = (event: any) => {
    if (backdrop) {
      event.preventDefault()
      if (
        event &&
        event.target &&
        typeof event.target.className === 'string' &&
        (event.target.className.includes('aside') ||
          event.target.dataset.modalOverlay)
      ) {
        setBackdropAnimate(true)
        setTimeout(() => setBackdropAnimate(false), 150)
      }
    }
    return false
  }

  return (
    <DialogPrimitive.Portal container={container || undefined}>
      <DialogPrimitive.Overlay
        className={DialogStyle['hnui-dialog-overlay']}
        data-modal-overlay
      />
      <DialogPrimitive.Content
        onPointerDownOutside={(event: any) => handleBackdrop(event)}
        onEscapeKeyDown={(event: any) => handleBackdrop(event)}
        {...props}
        className={clsxm(DialogClasses.join(' '), className)}
        data-modal={true}
      >
        {size === 'full' ? (
          <div className='w-full'>
            <div className='flex w-full items-center justify-between bg-snow px-4 '>
              <span>{headerTitle}</span>
              <span className='flex items-center space-x-2'>
                <span className='space-x-2 pr-3'>{rightComponents}</span>
              </span>
            </div>
            {children}
          </div>
        ) : (
          children
        )}
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  )
}

export const DialogTitle = ({ children, ...props }: DialogPropType) => (
  <DialogPrimitive.Title
    {...props}
    className={clsxm(DialogStyle['hnui-dialog-title'], props.className)}
  >
    {children}
  </DialogPrimitive.Title>
)

export const DialogDescription = ({
  children,
  className,
  ...props
}: DialogPropType) => (
  <DialogPrimitive.Description
    {...props}
    className={clsx(DialogStyle['hnui-dialog-description'], className)}
  >
    {children}
  </DialogPrimitive.Description>
)

export const DialogClose = React.forwardRef(
  (props: DialogPrimitive.DialogCloseProps, forwardedRef: Ref<any>) => (
    <DialogPrimitive.Close
      {...props}
      ref={forwardedRef}
      className={clsx(DialogStyle['hnui-dialog-close'], props.className)}
      data-testid='dialog-close'
    >
      <X weight='bold' className={DialogStyle['hnui-dialog-close-icon']} />
    </DialogPrimitive.Close>
  )
)

interface DialogTriggerPropType extends DialogPrimitive.DialogTriggerProps {
  onOpen?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
}

export const DialogTrigger = ({
  children,
  ...props
}: DialogTriggerPropType) => (
  <DialogPrimitive.Trigger {...props}>{children}</DialogPrimitive.Trigger>
)

export const Dialog: FC<DialogPropType> = ({
  children,
  open,
  onClose,
  onOpen,
  modal = true,
  ...props
}) => {
  const currentState = React.useRef(open)
  const [dialogOpen, setDialogOpen] = React.useState(open)

  React.useEffect(() => {
    setDialogOpen(open)
    return () => {
      setDialogOpen(false)
    }
  }, [open])

  React.useEffect(() => {
    if (dialogOpen && !currentState.current && onOpen) {
      onOpen()
    }
    if (!dialogOpen && currentState.current && onClose) {
      onClose()
    }
    currentState.current = dialogOpen
  }, [dialogOpen])

  return (
    <DialogPrimitive.Root
      open={dialogOpen}
      modal={modal}
      onOpenChange={setDialogOpen}
      {...props}
    >
      {children}
    </DialogPrimitive.Root>
  )
}

DialogClose.displayName = 'DialogClose'
Dialog.displayName = 'Dialog'
export default Dialog
