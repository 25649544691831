import { useContext, useEffect, useMemo, useState } from 'react'

import Select from '@/components/shared/ui/Select'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import HNContext from '@/context/HNContext'
import { listAllCustomAttributes } from '@/models/User'
import type { ICustomAttribute } from '@/types/customAttributes'
import toaster from '@/utils/toast'

interface ICustomAttributeOption
  extends Omit<ISelectOption, 'id'>,
    ICustomAttribute {}
interface IPropTypes {
  onChange?: (
    key: string,
    value: string[],
    selectedValues?: ICustomAttributeOption[]
  ) => void
  filters: {
    attribute_id?: string[]
  }
  placeholder?: string
  disabled?: boolean
  behaviour?: 'select' | 'filter'
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  includeBuiltInFields?: boolean
  refetch?: boolean
  className?: string
  clearable?: boolean
  filterOptions?: (option: ICustomAttributeOption) => boolean
}

export const BUILT_IN_CUSTOM_ATTRIBUTES_FIELDS: ICustomAttribute[] = [
  {
    id: 0,
    title: 'Starred',
    slug: 'starred',
    field_type: 'boolean',
    isInternal: true,
  },

  {
    id: 0,
    title: 'Created On',
    slug: 'creation_period',
    field_type: 'date',
    isInternal: true,
  },
  {
    id: 0,
    title: 'Domain',
    slug: 'domains',
    field_type: 'array',
    isInternal: true,
  },
]

export default function AdminUserCustomAttributesFilter({
  onChange,
  filters = {},
  placeholder,
  disabled = false,
  behaviour = 'filter',
  size = 'sm',
  includeBuiltInFields,
  refetch = false,
  className,
  clearable = true,
  filterOptions = () => true,
}: IPropTypes) {
  const [loading, setLoading] = useState(false)
  const { customAttributes, updateContext } = useContext(HNContext)

  const options = useMemo(() => {
    const attributes = includeBuiltInFields
      ? [...BUILT_IN_CUSTOM_ATTRIBUTES_FIELDS, ...(customAttributes || [])]
      : customAttributes
    return (
      attributes?.map((attribute) => ({
        ...attribute,
        label: attribute.title,
        value: attribute.slug,
        group: attribute.isInternal
          ? 'Built-in Attributes'
          : 'Custom Attributes',
      })) || []
    ).filter(filterOptions)
  }, [customAttributes, filters])

  const fetchData = () => {
    return listAllCustomAttributes({
      resource_type: 'user',
    })
      .then((attributes) => {
        updateContext?.({
          customAttributes: attributes,
        })
      })
      .catch((err) => {
        toaster.error(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleChange = (value: ISelectOptionGroup) => {
    if (!value) return
    if (!onChange) return

    const selectedValue = Array.isArray(value) ? value : [value]
    const tagId = selectedValue?.map((item: ISelectOption) => item.value)
    onChange('attribute_id', tagId, selectedValue as any[])
  }

  const value = useMemo(() => {
    if (filters && filters.attribute_id) {
      const filteredTags = Array.isArray(filters.attribute_id)
        ? filters.attribute_id
        : [filters.attribute_id]
      return options.filter((b) =>
        filteredTags
          ?.map((id: string) => id.toString())
          .includes(b.value.toString())
      )
    }
    return null
  }, [filters, options])

  useEffect(() => {
    if (!customAttributes || refetch) {
      fetchData()
    }
  }, [])

  return (
    <Select
      size={size}
      placeholder={placeholder || ''}
      multiple={behaviour === 'filter'}
      clearable={clearable}
      options={options}
      group={true}
      onChange={handleChange}
      value={value}
      loading={loading}
      disabled={disabled}
      creatable={behaviour === 'select'}
      className={className}
    />
  )
}
