import type { ISocialProviders } from '@/types/common'
import type { IVoteType } from '@/types/post'
import type { INotificationActions } from '@/types/user'

// BASE URL
export const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL || 'http://lvh.me:3000'
export const BASE_HOST_URL = process.env.NEXT_PUBLIC_HOSTED_URL
export const { BASE_HOST_LOCAL_URL } = process.env
export const BASE_API_LOCAL_HOST = process.env.SERVER_BASE_API_LOCAL_HOST
export const BASE_API_LOCAL_URL = `${BASE_API_LOCAL_HOST}/api/v2`

// App Routes
export const META_PATH = `${BASE_API_LOCAL_URL}/meta`
export const USER_ROOT_PATH = `${BASE_API_LOCAL_URL}/root.json`
export const ADMIN_ROOT_PATH = `${BASE_API_LOCAL_URL}/manage/root.json`
export const TRANSLATION_INTERNAL_PATH = (section: string, lang: string) =>
  `${BASE_HOST_LOCAL_URL || BASE_HOST_URL}/locale/${section}/${lang}.json`
export const TRANSLATION_PATH = (section: string, lang: string) =>
  `/locale/${section}/${lang}.json`
export const REAL_TIME_SYNC_AUTHENTICATION_PATH = `${BASE_URL}/authentication/sync`

// Auth Routes
export const SIGN_IN_INTERNAL_PATH = '/api/auth/signin'
export const SIGN_IN_PATH = `${BASE_URL}/authentication/sign_in`
export const SIGN_IN_WITH_TOKEN_INTERNAL_PATH = '/api/auth/loginWithToken'
export const SIGN_IN_WITH_SSO_TOKEN_INTERNAL_PATH =
  '/api/auth/loginWithSSOToken'
export const SIGN_OUT_INTERNAL_PATH = '/api/auth/signout'
export const SIGN_UP_INTERNAL_PATH = `/api/auth/signup`
export const SIGN_UP_WITH_CODE_INTERNAL_PATH = '/api/auth/signInWithCode'
export const SIGN_UP_PATH = `${BASE_URL}/authentication/sign_up`
export const VERIFY_AUTH_TOKEN_PATH = `${BASE_URL}/authentication/verify_token`
export const VERIFY_AUTH_TOKEN_INTERNAL_FULL_PATH = `${BASE_HOST_URL}/api/auth/verifyAuthToken`
export const VERIFY_AUTH_TOKEN_INTERNAL_PATH = `/api/auth/verifyAuthToken`
export const VERIFY_SSO_PATH = `${BASE_URL}/authentication/verify_sso_token`
export const VERIFY_SSO_INTERNAL_FULL_PATH = `${BASE_HOST_URL}/api/auth/verifySSOToken`
export const VERIFY_SSO_INTERNAL_PATH = `/api/auth/signup`
export const SEND_OTP_TO_EMAIL_PATH = `${BASE_URL}/authentication/one_time_sign_in`
export const SIGN_IN_WITH_OTP_PATH = (userId: string) =>
  `${BASE_URL}/authentication/one_time_sign_in/${userId}`
export const CONFIRM_PASSWORD_AND_SIGNIN_PATH = '/api/auth/confirmPassword'
export const RESEND_CONFIRMATION_EMAIL_PATH = `${BASE_URL}/authentication/confirmations/resend_with_captcha`
export const CONFIRM_PASSWORD_WITH_TOKEN_PATH = (token: string) =>
  `${BASE_URL}/authentication/confirmations/${token}`
export const SIGN_IN_WITH_PROVIDER_PATH = (provider: ISocialProviders) =>
  `${BASE_URL}/authentication/social_signin/${provider}`
export const RESET_PASSWORD_CONFIRMATION_PATH = (token: string) =>
  `${BASE_URL}/authentication/forgot_password/${token}`
export const PROCESS_EMAIL_CONFIRMATION_PATH = `${BASE_URL}/authentication/confirmations`
export const RESEND_EMAIL_CONFIRMATION_PATH = `${BASE_URL}/authentication/confirmations/resend`
export const FORGOT_PASSWORD_PATH = `${BASE_URL}/authentication/forgot_password`
export const MAGIC_LINK_PATH = `${BASE_URL}/authentication/one_time_sign_in`
export const MAGIC_LINK_PASSWORD_CHECK_PATH = `${BASE_URL}/authentication/one_time_sign_in/validate`
export const CONFIRM_EMAIL_WITH_TOKEN_PATH = (token: string) =>
  `${BASE_URL}/authentication/confirmations?token=${token}`
export const INVITATION_PATH = `${BASE_URL}/authentication/invitations`
export const USER_OWNED_ORGANIZATIONS_PATH = `${BASE_URL}/user/owned_organizations`

export const GET_APP_AUTH_TOKEN = (app: string) =>
  `${BASE_URL}/authentication/${app}`

// Posts Routes
export const USER_POSTS_PATH = `${BASE_URL}/feature_requests`
export const USER_POST_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}`
export const POST_UPVOTE_PATH = (slug: string, type: IVoteType) =>
  `${BASE_URL}/feature_requests/${slug}/${type}`
export const ADMIN_POSTS_PATH = `${BASE_URL}/manage/feature_requests`
export const ADMIN_OPTIMIZED_POSTS_PATH = `${BASE_URL}/manage/posts`
export const OPTIMIZED_POSTS_PATH = `${BASE_URL}/posts`
export const ADMIN_POST_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}`
export const ADMIN_POST_STATUS_UPDATE_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/statuses`
export const POST_ROADMAPS_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/roadmaps`
export const POST_LIST_COUNTS_PATH = `${BASE_URL}/manage/counts/posts`

export const POST_ASSIGNEES_PATH = `${BASE_URL}/manage/team_members`
export const POST_ADD_TAGS_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/tag`
export const ADMIN_SUBSCRIBE_USER_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/subscribe`
export const POST_SUBSCRIBER_LIST_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/subscribers`
export const POST_VOTE_ON_BEHALF_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/vote_on_behalf`
export const POST_SUBSCRIBE_USER_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/subscribe`
export const POTS_SUBSCRIBE_ETC_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/etc_subscribe`
export const POST_UPVOTERS_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/upvoters`
export const POST_DOWNVOTERS_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/downvoters`
export const POST_RATING_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/rate`
export const POST_REMOVE_TAG_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/remove_tag`
export const POST_REMOVE_ETC_DATE_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/remove_etc_date`
export const POST_SHOW_DESCRIPTION_PATH = (id: string) =>
  `${BASE_URL}/manage/user_organizations/${id}`

export const POST_SUBMITTERS_PATH = `${BASE_URL}/manage/feature_requests/submitters`
export const EMAIL_DOMAINS_PATH = `${BASE_URL}/manage/feature_requests/email_domains`
export const POST_MAX_VOTE_VALUE_PATH = `${BASE_URL}/manage/organizations/votes_count`
export const RESET_VOTES_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/reset_votes`
export const RESET_DOWNVOTES_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/reset_downvotes`
export const REMOVE_VOTE = (slug: string, voterId: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/votes/${voterId}`

export const POST_BULK_UPDATE_PATH = `${BASE_URL}/manage/feature_requests/bulk_update`
export const POST_BULK_DELETE_PATH = `${BASE_URL}/manage/feature_requests/bulk_delete`

export const POST_ACTIVITIES_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/activities`
export const POST_ASSIGNEE_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/assign`
export const POST_REMOVE_ASSIGNEE_PATH = (slug: string, id: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/assign/${id}`
export const POST_UNMERGE_PATH = (slug: string, child_id: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/merge_posts/${child_id}`
export const MERGE_POST_COUNT_PATH = (slug: string, id: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/merge/${id}`
export const MERGE_POST_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/merge_posts`
export const POST_TAT_SUMMARY_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/tat_summaries`
export const POST_MERGED_POSTS_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/merge_posts`

export const POST_MERGE_RECOMMENDATION_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/analysis/merge_suggestions`
export const POSTS_EXPORT_PATH = `${BASE_URL}/manage/feature_requests/export`
export const POST_VOTERS_EXPORT_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/export_voters`

export const GET_VOTE_ON_BEHALF_POST = (slug: string, key: string) =>
  `${BASE_URL}/feature_requests/${slug}/vote_on_behalf/${key}`
export const GET_VOTES_OF_POSTS_PATH = `${BASE_URL}/feature_requests/votes`
export const ORGANIZATION_DOMAINS_PATH = `${BASE_URL}/organizations/domains`
export const POST_AI_RECOMMENCE_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/analysis/regenerate`
export const POST_AI_UPDATE_SENTIMENT_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/analysis/assign`
export const POST_AI_INSIGHTS_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/analysis`
export const LIST_KEYWORDS_PATH = `${BASE_URL}/manage/keywords`
export const MATCHING_KB_ARTICLE_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/articles_suggestions`

export const POST_CUSTOM_FIELDS_PATH = (slug: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/custom_field_values`

export const POST_CUSTOM_FIELD_PATH = (slug: string, id: string) =>
  `${BASE_URL}/manage/feature_requests/${slug}/custom_field_values/${id}`

// Saved Filters
export const SAVED_FILTER_PATH = `${BASE_URL}/manage/filter_views`
export const SINGLE_SAVED_FILTER_PATH = (id: string) =>
  `${BASE_URL}/manage/filter_views/${id}`
export const FAVORITE_FILTER_PATH = (id: string) =>
  `${BASE_URL}/manage/filter_views/${id}/favourites`

// Comments Routes
export const POST_COMMENTS_PATH = (slug: string) =>
  `${BASE_URL}/feature_requests/${slug}/comments`
export const POST_COMMENT_PATH = (postSlug: string, id: string) =>
  `${BASE_URL}/feature_requests/${postSlug}/comments/${id}`
export const POST_COMMENT_MANAGE_PATH = (postSlug: string, id: string) =>
  `${BASE_URL}/manage/feature_requests/${postSlug}/comments/${id}`
export const POST_COMMENT_REACTION_PATH = (postSlug: string, id: string) =>
  `${BASE_URL}/feature_requests/${postSlug}/comments/${id}/react`
export const COMMENT_MODERATION_LIST_PATH = `${BASE_URL}/manage/comments/moderation`

export const ADMIN_POST_COMMENTS_PATH = (postSlug: string) =>
  `${BASE_URL}/manage/feature_requests/${postSlug}/comments`
export const ADMIN_POST_COMMENT_PATH = (postSlug: string, id: string) =>
  `${BASE_URL}/manage/feature_requests/${postSlug}/comments/${id}`
export const ADMIN_COMMENTS_COUNTS_PATH = `${BASE_URL}/manage/counts/comments`
export const ADMIN_COMMENTS_BULK_ACTIONS_PATH = `${BASE_URL}/manage/comments/bulk_actions`
// Boards
export const ADMIN_GLOBAL_BOARD_SETTINGS = `${BASE_URL}/manage/bucket/settings`
export const ADMIN_GLOBAL_BOARD_CUSTOMIZATION = `${BASE_URL}/manage/bucket/customizations`
export const ADMIN_GLOBAL_BOARD_COMMENT_CUSTOMIZATION = `${BASE_URL}/manage/comment_customization`
export const BULK_IMPORT_BOARDS = `${BASE_URL}/manage/buckets/import`
// TODO Remove id as its common to org
export const ADMIN_GLOBAL_BOARD_FORM_CUSTOMIZATION = (id: string) =>
  `${BASE_URL}/manage/form_customizations/${id}`
export const ADMIN_BOARD_LIST_PATH = `${BASE_URL}/manage/buckets`
export const ADMIN_SINGLE_BOARD_PATH = (name: string) =>
  `${BASE_URL}/manage/buckets/${name}`
export const ADMIN_SINGLE_BOARD_INTEGRATION_PATH = (name: string) =>
  `${BASE_URL}/manage/buckets/${name}/integrations`
export const ADMIN_SINGLE_BOARD_OPTIONS_PATH = (slug: string) =>
  `${BASE_URL}/manage/bucket/${slug}/options`
export const ADMIN_SINGLE_BOARD_STATS_PATH = `${BASE_URL}/manage/counts/board`
export const ADMIN_SINGLE_BOARD_IMPORT_POSTS_PATH = (name: string) =>
  `${ADMIN_SINGLE_BOARD_PATH(name)}/import_feature_requests`
export const ADMIN_SINGLE_BOARD_MEMBERS_PATH = (name: string) =>
  `${ADMIN_SINGLE_BOARD_PATH(name)}/bucket_members`
export const ADMIN_SINGLE_BOARD_GENERATE_INVITE_PATH = (name: string) =>
  `${ADMIN_SINGLE_BOARD_PATH(name)}/bucket_members/generate_otl_link`
export const ADMIN_SINGLE_BOARD_MEMBERS_REMOVE_PATH = (
  name: string,
  id: string
) => `${ADMIN_SINGLE_BOARD_PATH(name)}/bucket_members/${id}`
export const ADMIN_SINGLE_BOARD_IMPORT_MEMBERS_PATH = (name: string) =>
  `${ADMIN_SINGLE_BOARD_PATH(name)}/bucket_members/import`
export const ADMIN_BOARD_SLUG_CHECK = `${BASE_URL}/manage/buckets/new`
export const SUBMISSION_FORM_PATH = (key: string) =>
  `${BASE_URL}/feature_requests/submission_form/${key}`
export const LETTERBOX_EMAIL_PATH = (slug: string) =>
  `${BASE_URL}/manage/bucket/${slug}/letterbox`
export const ADMIN_SINGLE_BOARD_AI_OPT_IN = (name: string) =>
  `${ADMIN_SINGLE_BOARD_PATH(name)}/ai_opt_in`
export const ADMIN_SINGLE_BOARD_ANALYSIS_PATH = (name: string) =>
  `${BASE_URL}/manage/buckets/${name}/analysis`
// User Baord
export const ACCEPT_OTL_LINK_PATH = (name: string, key: string) =>
  `${BASE_URL}/b/${name}/invite/${key}`

// Changelog Routes
// Changelog Next Api Routes
export const USER_API_CHANGELOGS_PATH = `${BASE_HOST_URL}/api/changelogs`
export const USER_NEXT_API_CHANGELOGS_PATH = `${BASE_HOST_URL}/api/ver1/changelogs`

// Changelog Rails Api Routes
export const USER_CHANGELOGS_PATH = `${BASE_URL}/changelogs`
export const USER_SSR_CHANGELOGS_PATH = `${BASE_API_LOCAL_URL}/changelogs`
export const USER_CHANGELOG_PATH = (slug: string) =>
  `${BASE_URL}/changelogs/${slug}`
export const USER_SSR_CHANGELOG_PATH = (slug: string) =>
  `${BASE_API_LOCAL_URL}/changelogs/${slug}`
export const ADD_CHANGELOG_REACTION_PATH = `${BASE_URL}/resource_reactions`
export const REMOVE_CHANGELOG_REACTION_PATH = (id: string) =>
  `${BASE_URL}/resource_reactions/${id}`
export const ADMIN_CHANGELOGS_PATH = `${BASE_URL}/manage/changelogs`
export const CHANGELOG_LIST_COUNTS_PATH = `${BASE_URL}/manage/counts/changelog`
export const ADMIN_CHANGELOG_PATH = (slug: string) =>
  `${BASE_URL}/manage/changelogs/${slug}`
export const ADMIN_CHANGELOG_REMOVE_COVER_PATH = (slug: string) =>
  `${BASE_URL}/manage/changelogs/${slug}/remove_feature_image`
export const CHANGELOG_COMPLETED_POSTS_PATH = `${BASE_URL}/manage/changelogs/completed_feature_requests`
export const SHARE_CHANGELOG_PATH = (slug: string) =>
  `${BASE_URL}/manage/changelogs/${slug}/send_published_email`
export const ADD_CHANGELOG_LABEL_PATH = (slug: string) =>
  `${BASE_URL}/manage/changelogs/${slug}/changelog_categories`
export const REMOVE_CHANGELOG_LABEL_PATH = (slug: string, id: string) =>
  `${BASE_URL}/manage/changelogs/${slug}/changelog_categories/${id}`
export const LINK_POST_TO_CHANGELOG_PATH = (slug: string) =>
  `${BASE_URL}/manage/changelogs/${slug}/changelog_feature_requests`
export const UNLINK_POST_FROM_CHANGELOG_PATH = (slug: string, id: string) =>
  `${BASE_URL}/manage/changelogs/${slug}/changelog_feature_requests/${id}`
export const CHANGELOG_AUTHORS_PATH = `${BASE_URL}/manage/team_members`

export const ADMIN_CHANGELOG_SETTINGS_PATH = `${BASE_URL}/manage/changelog_settings/settings`
export const ADMIN_AI_CHANGELOG_PATH = `${BASE_URL}/manage/changelogs/ai_suggestion`
export const ADMIN_AI_ARTICLE_PATH = `${BASE_URL}/manage/articles/ai_suggestion`
// export const ADMIN_CHANGELOG_CUSTOMIZATION_PATH = `${BASE_URL}/manage/changelog/customizations`
// Contributors
export const ADD_CONTRIBUTOR_PATH = `${BASE_URL}/manage/resource_contributors`
export const REMOVE_CONTRIBUTOR_PATH = (id: string) =>
  `${BASE_URL}/manage/resource_contributors/${id}`

// Knowledge Base Routes
export const USER_ARTICLES_PATH = `${BASE_URL}/articles`
export const USER_ARTICLE_PATH = (slug: string) =>
  `${BASE_URL}/articles/${slug}`
export const ADMIN_KNOWLEDGE_BASE_SETTINGS = `${BASE_URL}/manage/knowledge_base/settings`
export const ADMIN_KNOWLEDGE_BASE_SUMMARIZATION = (slug: string) =>
  `${BASE_URL}/manage/articles/${slug}/analysis/regenerate`

export const MEMBER_ARTICLES_PATH = `${BASE_URL}/manage/articles`
export const MEMBER_ARTICLE_PATH = (slug: string) =>
  `${BASE_URL}/manage/articles/${slug}`
export const ARTICLE_PUBLISH_PATH = (slug: string) =>
  `${BASE_URL}/manage/articles/${slug}/publish`
export const ARTICLE_AUTHOR_PATH = (slug: string) =>
  `${BASE_URL}/manage/articles/${slug}/authors`
export const ARTICLE_REMOVE_AUTHOR_PATH = (
  slug: string,
  authorId: string | number
) => `${BASE_URL}/manage/articles/${slug}/authors/${authorId}`
export const ARTICLE_ASSIGNEE_PATH = (slug: string) =>
  `${BASE_URL}/manage/articles/${slug}/assignees`
export const ARTICLE_REMOVE_ASSIGNEE_PATH = (
  slug: string,
  assigneeId: string | number
) => `${BASE_URL}/manage/articles/${slug}/assignees/${assigneeId}`

export const ARTICLE_TAG_PATH = (slug: string) =>
  `${BASE_URL}/manage/articles/${slug}/tags`
export const ARTICLE_REMOVE_TAG_PATH = (slug: string, tagId: string | number) =>
  `${BASE_URL}/manage/articles/${slug}/tags/${tagId}`

export const CREATE_ARTICLE_PATH = `${BASE_URL}/manage/articles`
export const ADMIN_KNOWLEDGE_BASE_REGENERATE_WIDGET_KEY = `${BASE_URL}/manage/knowledge_base/settings/generate_knowledge_base_widget_key`
export const USER_ARTICLE_WITH_KEY = (slug: string) =>
  `${BASE_URL}/articles/browse/${slug}`

export const UPDATE_KB_CUSTOMIZATION_PATH = `${BASE_URL}/manage/article_customization`
export const VERIFY_KB_CUSTOM_DOMAIN = `${BASE_URL}/manage/article_customization/domain_verifications`

export const ADMIN_COLLECTIONS_PATH = `${BASE_URL}/manage/collections`
export const ADMIN_COLLECTION_ARTICLES_PATH = `${BASE_URL}/manage/collections_info`
export const ADMIN_COLLECTION_CREATE_PATH = `${BASE_URL}/manage/collections`
export const ADMIN_COLLECTION_PATH = (id: string) =>
  `${BASE_URL}/manage/collections/${id}`

export const USER_COLLECTIONS_PATH = `${BASE_URL}/collections`
export const USER_COLLECTION_PATH = (slug: string) =>
  `${BASE_URL}/collections/${slug}`

export const ARTICLE_TRANSLATION_PATH = (slug: string) =>
  `${BASE_URL}/manage/articles/${slug}/translates`

export const IMPORT_FROM_ZENDESK_PATH = `${BASE_URL}/manage/articles/import`
// Tags
export const USER_TAGS_PATH = `${BASE_URL}/tags`
export const USER_TAG_PATH = (slug: string) => `${BASE_URL}/tags/${slug}`
export const ADMIN_TAGS_PATH = `${BASE_URL}/manage/tags`
export const ADMIN_TAG_PATH = (id: string) => `${BASE_URL}/manage/tags/${id}`
export const REMOVE_TAG_IMAGE_PATH = (id: string) =>
  `${BASE_URL}/manage/tags/${id}/remove_image`

// Team
export const ADMIN_TEAM_MEMBERS_PATH = `${BASE_URL}/manage/teams`
export const ADMIN_MEMBERS_PATH = `${BASE_URL}/manage/team_members`
export const TEAM_MEMBER_PATH = (id: string) => `${BASE_URL}/manage/teams/${id}`
export const INVITE_TEAM_MEMBER_PATH = `${BASE_URL}/manage/teams/invite`
export const IMPORT_TEAM_MEMBERS_PATH = `${BASE_URL}/manage/teams/import_members`

// User
export const USER_PROFILE_PATH = `${BASE_URL}/user/profile`
export const USER_PROFILE_UPDATE_PATH = `${BASE_URL}/user/profile`
export const REMOVE_USER_PROFILE_PICTURE_PATH = `${BASE_URL}/user/remove_profile_picture`
export const EMAIL_UNSUBSCRIBE_PATH = `${BASE_URL}/unsubscribes`
export const EMAIL_SUBSCRIBE_PATH = (module: string) =>
  `${BASE_URL}/unsubscribes/${module}`
export const USER_PASSWORD_UPDATE_PATH = `${BASE_URL}/authentication/password_update`
export const USER_NOTIFICATIONS_PATH = (type: string) =>
  `${BASE_URL}/user/notifications/${type}`
export const USER_NOTIFICATION_PATH = (id: string) =>
  `${BASE_URL}/user/notifications/${id}`
export const USER_NOTIFICATION_READ_PATH = (id: string) =>
  `${BASE_URL}/user/notifications/${id}/read`
export const BULK_UPDATE_NOTIFICATION_PATH = (action: INotificationActions) =>
  `${BASE_URL}/user/notifications/bulk_actions/${action}`
export const TOGGLE_USER_THEME_PATH = `${BASE_URL}/user/toggle_dark_theme`
export const CLEAR_USER_NOTIFICATIONS_PATH = `${BASE_URL}/user/notifications/clear`
export const DELETE_USER_ACCOUNT_INTERNAL_PATH = '/api/auth/deleteAccount'
export const DELETE_USER_ACCOUNT_PATH = `${BASE_URL}/user`
export const LOGOUT_FROM_ALL_SESSIONS_PATH = `${BASE_URL}/authentication/set_logout_from_all_session`
export const USER_NOTIFICATION_CONFIG_PATH = `${BASE_URL}/user/advanced_notifications`
export const ADMINS_NOTIFICATION_CONFIG_PATH = `${BASE_URL}/manage/advanced_notifications`
export const UPDATE_ADMINS_NOTIFICATION_CONFIG_PATH = (role: string) =>
  `${BASE_URL}/manage/advanced_notifications/${role}`
export const IMPORT_CUSTOMERS_MEMBERS_PATH = `${BASE_URL}/manage/customers/import`
export const TOGGLE_USER_EMAIL_DIGEST_PATH = `${BASE_URL}/user/notifications_config`
export const USERS_SETTINGS_PATH = `${BASE_URL}/manage/user/settings`
export const USERS_CUSTOM_ATTRIBUTES_PATH = `${BASE_URL}/manage/custom_fields`
export const USERS_CUSTOM_ATTRIBUTE_PATH = (id: number) =>
  `${BASE_URL}/manage/custom_fields/${id}`

export const BOOTSTRAP_PATH = `${BASE_URL}/bootstrap`
export const ADMIN_BOOTSTRAP_PATH = `${BASE_URL}/manage/bootstrap`
// Kanban
export const KANBAN_PATH = `${BASE_URL}/kanban`
export const KANBAN_LIST_COLUMN_PATH = (id: string) =>
  `${BASE_URL}/kanban/${id}`
export const KANBAN_COLUMN_ENTRIES_PATH = (id: string) =>
  `${BASE_URL}/kanban/${id}/kanban_entries`
export const KANBAN_COLUMN_ENTRY = (id: string) =>
  `${BASE_URL}/kanban_entries/${id}`
export const KANBAN_TOP_POSTS = `${BASE_URL}/manage/kanban_entry_feature_requests`

export const ADMIN_KANBAN_PATH = `${BASE_URL}/manage/kanban`
export const ADMIN_KANBAN_COLUMN_PATH = (id: string) =>
  `${BASE_URL}/manage/kanban/${id}`
export const ADMIN_KANBAN_COLUMN_ENTRIES_PATH = (id: string) =>
  `${BASE_URL}/manage/kanban/${id}/kanban_entries`
export const ADMIN_KANBAN_ENTRY_PATH = (id: string) =>
  `${BASE_URL}/manage/kanban_entries/${id}`
export const ADMIN_KANBAN_TAG_NEW_POST_TO_ENTRY_PATH = (id: string) =>
  `${BASE_URL}/manage/kanban_entries/${id}/kanban_entry_feature_requests`
export const ADMIN_KANBAN_DELETE_TAGGED_POST_PATH = (id: string) =>
  `${BASE_URL}/manage/kanban_entry_feature_requests/${id}`

// Uploads Paths
export const UPLOAD_EDITOR_ATTACHMENT_PATH = `${BASE_URL}/editor_attachments`
export const UPLOAD_EDITOR_ATTACHMENT_MULTIPLE_PATH = `${BASE_URL}/editor_attachments/multiple`
export const UPLOAD_EDITOR_ATTACHMENT_DOWNLOAD_PATH = `${BASE_URL}/editor_attachments/presigned`
export const ATTACHMENT_UPLOAD_PATH = `${BASE_URL}/manage/attachments`
export const GET_UPLOAD_PARAMS = `${BASE_URL}/editor_attachments/access_info`
export const PROCESS_COMPLETED_INLINE_UPLOAD = `${BASE_URL}/editor_attachments/create_inline_attachment`
export const PROCESS_COMPLETED_EXTERNAL_ATTACHMENTS_UPLOAD = `${BASE_URL}/editor_attachments/create_external_attachment`

// Roadmap
export const ROADMAP_SETTINGS_PATH = `${BASE_URL}/manage/roadmap/settings`
export const ROADMAP_INFO_PATH = `${BASE_URL}/roadmap`
export const ROADMAP_POST_LIST_COUNTS_PATH = `${BASE_URL}/manage/counts/roadmap`
export const ROADMAP_CUSTOMIZATION_PATH = `${BASE_URL}/manage/roadmap/customizations`
export const TRAIL_MODULE_PATH = `${BASE_URL}/manage/module_features`
// Organization
export const ORGANIZATION_SETTINGS_PATH = `${BASE_URL}/manage/organization/settings`
export const ORGANIZATION_INFO_PATH = `${BASE_URL}/manage/organization/info`
export const ORGANIZATION_CUSTOMIZATION_PATH = `${BASE_URL}/manage/organization/customizations`
export const LIST_ORGANIZATIONS_PATH = `${BASE_URL}/organizations`
export const ORGANIZATION_VALIDATION_PATH = `${BASE_URL}/organizations/new`
export const RECENT_ACTIVITIES_PATH = `${BASE_URL}/manage/recent_activities`
export const RESOURCE_ACTIVITIES_PATH = `${BASE_URL}/manage/activities`
export const GENERATE_API_KEY_PATH = `${ORGANIZATION_SETTINGS_PATH}/generate_api_key`
export const GENERATE_SSO_KEY_PATH = `${ORGANIZATION_SETTINGS_PATH}/generate_sso_token`
export const GENERATE_WIDGET_KEY_PATH = `${ORGANIZATION_SETTINGS_PATH}/generate_widget_key`
export const GENERATE_LETTERBOX_MAIL = `${ORGANIZATION_INFO_PATH}/regenerate_letterbox_email_address`
export const ORGANIZATION_DELETION_INFO = `${ORGANIZATION_SETTINGS_PATH}/organization_deletion_info`
export const ORGANIZATION_DELETION_REQUESTS = `${BASE_URL}/manage/organization_deletion_requests`
export const ORGANIZATION_DELETION_REQUEST = (id: string) =>
  `${ORGANIZATION_DELETION_REQUESTS}/${id}`
export const ORGANIZATION_USAGE_PATH = `${BASE_URL}/manage/organization_plan`
export const ORGANIZATION_ANNOUNCEMENT_PATH = `${BASE_URL}/manage/internal_announcements`

// Onboarding
export const GET_ONBOARDING_STEPS_PATH = `${BASE_URL}/onboarding/onboarded_steps`
export const SKIP_ONBOARDING_STEPS_PATH = `${BASE_URL}/onboarding/skip`

export const REMOVE_FAVICON_PATH = `${ORGANIZATION_SETTINGS_PATH}/remove_favicon`
export const REMOVE_LOGO_PATH = `${ORGANIZATION_SETTINGS_PATH}/remove_avatar`
export const REMOVE_OG_IMAGE_PATH = `${ORGANIZATION_SETTINGS_PATH}/remove_og_image`
// Analytics
export const DASHBOARD_API_PATH = `${BASE_URL}/manage/analytics/data`
export const EMPTY_SEARCH_INSIGHTS_API_PATH = `${BASE_URL}/manage/articles/search_insights`

// Users
export const ORGANIZATION_INTERACTORS = `${BASE_URL}/manage/organization_interactors`
export const ORGANIZATION_INTERACTORS_PATH = `${BASE_URL}/manage/organization_interactors`
export const ORGANIZATION_INTERACTORS_SEARCH_PATH = `${BASE_URL}/user/search`

export const CUSTOMERS_PATH = `${BASE_URL}/manage/customers`
export const ORGANIZATION_INTERACTOR_PATH = (id: string) =>
  `${BASE_URL}/manage/organization_interactors/${id}`
export const BLOCK_INTERACTOR_PATH = (id: string) =>
  `${ORGANIZATION_INTERACTOR_PATH(id)}/block`
export const UNBLOCK_INTERACTOR_PATH = (id: string) =>
  `${ORGANIZATION_INTERACTOR_PATH(id)}/unblock`
export const STAR_INTERACTOR_PATH = (id: string) =>
  `${ORGANIZATION_INTERACTOR_PATH(id)}/toggle_star`
export const ADD_INTERACTOR_LABEL_PATH = (id: string) =>
  `${ORGANIZATION_INTERACTOR_PATH(id)}/add_label` //
export const REMOVE_INTERACTOR_LABEL_PATH = (id: string) =>
  `${ORGANIZATION_INTERACTOR_PATH(id)}/remove_label` //
export const INTERACTOR_LABELS_PATH = `${BASE_URL}/manage/user_labels`

export const CUSTOM_ATTRIBUTES_USER_PATH = (id: string) =>
  `${BASE_URL}/manage/customers/${id}/custom_field_values`
export const CUSTOM_ATTRIBUTE_USER_PATH = (id: string, keyId: number) =>
  `${BASE_URL}/manage/customers/${id}/custom_field_values/${keyId}`
export const BULK_UPDATE_CUSTOM_ATTRIBUTES_PATH = `${BASE_URL}/manage/customers/bulk_custom_field_values`

// Common
export const READ_ITEM_PATH = `${BASE_URL}/manage/user_viewed_resources`
export const UNREAD_ITEM_PATH = (id: string) =>
  `${BASE_URL}/manage/user_viewed_resources/${id}`
export const GET_REQUEST_MODULE_PATH = (module: string) =>
  `${BASE_URL}/manage/polls/${module}`
export const REQUEST_MODULE_PATH = `${BASE_URL}/manage/polls`

// Third Party Integrations Paths
export const TP_INTEGRATIONS = (provider: string) =>
  `${BASE_URL}/tp/${provider}`
export const TP_INTEGRATIONS_WO_PROVIDER_PATH = `${BASE_URL}/tp`

export const INTEGRATION_INFO_PATH = `${BASE_URL}/manage/integration_info`

// Admin Integrations
export const ADMIN_INTEGRATIONS_PATH = `${ORGANIZATION_SETTINGS_PATH}/integrations`
export const ADMIN_INTEGRATION_CONFIG_PATH = (provider: string) =>
  `${BASE_URL}/manage/authorizations/${provider}`
export const ADMIN_INTEGRATION_CONFIG_ACTION_PATH = (
  provider: string,
  id: string
) => `${BASE_URL}/manage/authorizations/${provider}/${id}`

// Jira Info
export const ADMIN_JIRA_INFO_PATH = `${BASE_URL}/manage/authorizations/jira_info`
export const GET_JIRA_ISSUE_TYPES = `${BASE_URL}/manage/authorizations/jira_issuetypes`
export const UPDATE_JIRA_CONFIGURATION = `${BASE_URL}/manage/authorizations/update_jira_info`
export const POST_JIRA_ISSUE_PATH = (slug: string) =>
  `${USER_POST_PATH(slug)}/jira_issue`
export const CREATE_ISSUE_TO_JIRA = (slug: string) =>
  `${POST_JIRA_ISSUE_PATH(slug)}/create`
export const SEARCH_JIRA_ISSUES = (slug: string) =>
  `${POST_JIRA_ISSUE_PATH(slug)}/search`
export const TAG_JIRA_ISSUE = (slug: string) =>
  `${POST_JIRA_ISSUE_PATH(slug)}/tag`
export const UNTAG_JIRA_ISSUE = (slug: string) =>
  `${POST_JIRA_ISSUE_PATH(slug)}/untag`
export const CREATE_JIRA_INSIGHT = (slug: string) =>
  `${POST_JIRA_ISSUE_PATH(slug)}/insights`

export const RULE_AUTHORIZATIONS = (provider: string) =>
  `${BASE_URL}/manage/authorizations/${provider}/rules`

// Linear Push Issue Routes
export const LINEAR_AUTH = `${BASE_URL}/manage/linear`
export const LINEAR_ISSUES = `${LINEAR_AUTH}/issues`
export const LINEAR_PROJECTS = `${LINEAR_AUTH}/projects`
export const LINEAR_USERS = `${LINEAR_AUTH}/users`
export const POST_LINEAR_ISSUES = (slug: string) =>
  `${ADMIN_POST_PATH(slug)}/linear_issues`
export const POST_LINEAR_ISSUE = (slug: string, id: string) =>
  `${ADMIN_POST_PATH(slug)}/linear_issues/${id}`
export const LINEAR_CONFIG = `${LINEAR_AUTH}/feature_requests`
export const LINEAR_STATES = `${LINEAR_AUTH}/statuses`

// Single Post Addons
export const POST_ADDONS_PATH = (slug: string) =>
  `${ADMIN_POST_PATH(slug)}/addons`
export const POST_ADDON_PATH = (slug: string, id: string) =>
  `${ADMIN_POST_PATH(slug)}/addons/${id}`

// Integration Member Config
export const BASE_INTEGRATION = (provider: string) =>
  `${BASE_URL}/manage/${provider}`
export const INTEGRATION_CONFIG = (provider: string) =>
  `${BASE_INTEGRATION(provider)}/config`

// ClickUp Task Routes
export const CLICK_UP = BASE_INTEGRATION('click_up')
export const CLICK_UP_TASKS = `${CLICK_UP}/tasks`
export const CLICK_UP_TASK = (id: string) => `${CLICK_UP}/tasks/${id}`
export const CLICK_UP_CUSTOM_FIELDS = `${ADMIN_INTEGRATION_CONFIG_PATH(
  'click_up'
)}/custom_field_rules`

// Github Issue Routes
export const GITHUB = BASE_INTEGRATION('github')
export const GITHUB_ISSUES = `${GITHUB}/issues`
export const GITHUB_REPO_SEARCH = `${GITHUB}/config/repos`

// Gitlab Issue Routes
export const GITLAB = BASE_INTEGRATION('gitlab')
export const GITLAB_PROJECT_SEARCH = `${GITLAB}/config/projects`
export const GITLAB_MILESTONE_SEARCH = `${GITLAB}/config/milestones`
export const GITLAB_LABEL_SEARCH = `${GITLAB}/config/labels`
export const GITLAB_ASSIGNEE_SEARCH = `${GITLAB}/config/users`
export const GITLAB_ISSUES = `${GITLAB}/issues`

// Asana Routes
export const ASANA_PROJECT_SECTIONS_PATH = `${BASE_URL}/manage/asana/sections`
export const ASANA_AUTH_PATH = `${BASE_URL}/manage/asana`
export const ASANA_TASKS_PATH = `${ASANA_AUTH_PATH}/tasks`
export const POST_ASANA_TASK_PATH = (slug: string) =>
  `${ADMIN_POST_PATH(slug)}/tasks`

// Webhooks
export const WEBHOOKS_LIST_PATH = `${BASE_URL}/manage/webhooks`
export const WEBHOOK_SHOW_PATH = (id: string) =>
  `${BASE_URL}/manage/webhooks/${id}`
export const WEBHOOK_EVENTS_PATH = (id: string) =>
  `${BASE_URL}/manage/webhooks/${id}/webhook_events`
export const WEBHOOK_EVENT_UPDATE_PATH = (id: string) =>
  `${BASE_URL}/manage/webhooks/${id}/webhook_subscriptions/`

// Canned Responses
export const CANNED_RESPONSES_PATH = `${BASE_URL}/manage/comment_templates`
export const CANNED_RESPONSE_PATH = (id: string) =>
  `${CANNED_RESPONSES_PATH}/${id}`

// Billing
export const BILLING_INFO = `${BASE_URL}/manage/billing`
export const MIGRATE_TO_STRIPE = `${BILLING_INFO}/migrate_to_stripe`
export const MIGRATE_TO_PADDLE = `${BILLING_INFO}/migrate_to_paddle`
export const PAYMENTS = (source: string) => `${BASE_URL}/payments/${source}`
export const CUSTOMER = (source: string) => `${PAYMENTS(source)}/customers`
export const SUBSCRIPTIONS = (source: string) =>
  `${PAYMENTS(source)}/subscriptions`
export const SUBSCRIPTION = (id: string, source: string) =>
  `${SUBSCRIPTIONS(source)}/${id}`
export const MANAGE_SUBSCRIPTION = (source: string) =>
  `${SUBSCRIPTIONS(source)}/manage`
export const TRIAL_EXTEND_PATH = `${BASE_URL}/manage/organization_plan`
export const INVOICE_PATH = `${BASE_URL}/manage/billing/invoices`
export const PLAN_LIMITS_PATH = `${BASE_URL}/manage/billing/limits`
export const PLAN_UPGRADE_PATH = (source: string, subscription_id: string) =>
  `${PAYMENTS(source)}/subscriptions/${subscription_id}`
export const BILLING_DETAILS_PATH = `${BASE_URL}/manage/billing/details`
export const SUBSCRIPTION_RESUME_PATH = `${BASE_URL}/payments/stripe/subscriptions/resume`
export const SUBSCRIPTION_CHECKOUT_PATH = (source: string) =>
  `${SUBSCRIPTIONS(source)}/checkout`

// PowerUps
export const PRORATE_POWER_UPS_PATH = `${BASE_URL}/manage/billing/prorate`
export const PURCHASE_POWER_UPS = `${BASE_URL}/manage/billing/power_ups`
export const POWER_UPS_INFO_PATH = `${BASE_URL}/manage/billing/power_ups/info`
export const QUANTITY_POWER_UP_PURCHASE_PATH = (powerUpSlug: string) =>
  `${BASE_URL}/manage/billing/power_ups/${powerUpSlug}`
export const PURCHASE_VERIFICATION_PATH = `${BASE_URL}/payments/paddle/callback/verify_subscription`
export const POWER_UPS_PURCHASE_CHECKOUT_PATH = `${BASE_URL}/manage/billing/power_ups/checkout`

// Custom States
export const GET_CUSTOM_STATUS_PATH = `${BASE_URL}/manage/custom_states`
export const CUSTOM_STATUS_PATH = (id: string) =>
  `${BASE_URL}/manage/custom_states/${id}`

// Email Unsubscribe
export const UNSUBCRIBE_INFO_PATH = (token: string) =>
  `${BASE_URL}/unsubscribes?unsubscribe_token=${token}`
export const UNSUBCRIBE_EMAILS_WITH_TOKEN_PATH = (token: string) =>
  `${BASE_URL}/unsubscribes/unsubscribe?unsubscribe_token=${token}`
export const RESUBCRIBE_EMAILS_WITH_TOKEN_PATH = (token: string) =>
  `${BASE_URL}/unsubscribes/subscribe?unsubscribe_token=${token}`

// Multiple Roadmaps
export const MULTIPLE_ROADMAPS_PATH = `${BASE_URL}/manage/roadmaps`
export const MULTIPLE_ROADMAP_SINGLE_PATH = (slug: string) =>
  `${BASE_URL}/manage/roadmaps/${slug}`
export const MULTIPLE_ROADMAP_ITEMS_PATH = (slug: string) =>
  `${BASE_URL}/manage/roadmaps/${slug}/roadmap_items`
export const MULTIPLE_ROADMAP_ITEM_REMOVE_PATH = (slug: string, id: string) =>
  `${BASE_URL}/manage/roadmaps/${slug}/roadmap_items/${id}`
export const UPDATE_ROADMAP_ITEM_SCORE_PATH = `${BASE_URL}/manage/scores`

export const USER_MULTIPLE_ROADMAPS_PATH = `${BASE_URL}/roadmap`
export const USER_MULTIPLE_ROADMAP_SINGLE_PATH = (slug: string) =>
  `${BASE_URL}/roadmap/${slug}`
export const USER_MULTIPLE_ROADMAP_ITEMS_PATH = (slug: string) =>
  `${BASE_URL}/roadmap/${slug}/roadmap_items`
export const USER_MULTIPLE_ROADMAP_ITEM_REMOVE_PATH = (
  slug: string,
  id: string
) => `${BASE_URL}/roadmap/${slug}/roadmap_items/${id}`
export const USER_UPDATE_ROADMAP_ITEM_SCORE_PATH = `${BASE_URL}/scores`

// Resource Paths
export const RESOURCE_MEMBERS_PATH = `${BASE_URL}/manage/resource_members`
export const RESOURCE_MEMBER_PATH = (id: string) =>
  `${BASE_URL}/manage/resource_members/${id}`

export const RESOURCE_SUBSCRIPTIONS_PATH = `${BASE_URL}/manage/resource_subscriptions`
export const RESOURCE_COMMENTS_PATH = `${BASE_URL}/resource_comments`
export const RESOURCE_REACTIONS_PATH = `${BASE_URL}/manage/resource_reactions`
export const RESOURCE_ACTIVITY_LIST_PATH = `${BASE_URL}/manage/resource_activities`

export const UPDATE_VIEW_PREFERENCES = `${BASE_URL}/view_preferences`

export const SCORE_PATH = `${BASE_URL}/manage/scores`
// Custom Domain
export const CUSTOM_DOMAIN_PATH = `${BASE_URL}/manage/organizations`
export const CUSTOM_DOMAIN_VERIFICATION_PATH = `${BASE_URL}/manage/organizations/domain_verifications`

export const IDENITY_VALIDATION_PATH = `${BASE_URL}/authentication/identity`
export const EMOJI_LIST_PATH = '/assets/emojiMap.json'

// HN AI
export const HN_AI_COMPLETION_ENDPOINT = `${BASE_HOST_URL}/api/ai/completion`

// OPEN AI
export const OPENAI_COMPLETION_ENDPOINT =
  'https://api.openai.com/v1/completions'
export const OPENAI_CHAT_ENDPOINT = 'https://api.openai.com/v1/chat/completions'

export const EMAILS_PATH = `${BASE_URL}/manage/emails`

// Passkey Register
export const GET_REGISTRATION_PASSKEY_OPTIONS = `${BASE_URL}/passkey`
export const VERIFY_REGISTRATION_PASSKEY = `${BASE_URL}/passkey/verify`

// Passkey Auth
export const GET_AUTHENTICATE_PASSKEY_OPTIONS = `${BASE_URL}/authentication/passkey`
export const VERIFY_AUTHENTICATE_PASSKEY = `${BASE_URL}/authentication/passkey`

// Passkey Manage
export const LIST_PASSKEYS = `${BASE_URL}/passkey`
export const SINGLE_PASSKEYS = (id: string) => `${BASE_URL}/passkey/${id}`

export const RESOURCE_SUBSCRIPTION_PATH = `${BASE_URL}/manage/resource_subscriptions`
export const RESOURCE_USER_SUBSCRIPTION_PATH = (userId: string) =>
  `${BASE_URL}/manage/resource_subscriptions/${userId}`
export const RESOURCE_SUBSCRIBE_PATH = `${BASE_URL}/user/resource_subscriptions`

export const RESOURCE_FAQS_PATH = `${BASE_URL}/manage/resource_faqs`
export const RESOURCE_FAQ_PATH = (id: string | number) =>
  `${RESOURCE_FAQS_PATH}/${id}`
export const SMTP_CREATION_PATH = `${BASE_URL}/manage/organization_notification_setting`
export const SMTP_TEST_PATH = `${BASE_URL}/manage/organization_notification_setting/inspect`

// User segment
export const USER_SEGMENTS_PATH = `${BASE_URL}/manage/segmentations`
export const USER_SEGMENT_PATH = (id: string | number) =>
  `${BASE_URL}/manage/segmentations/${id}`
export const PAGE_VIEW_PATH = `${BASE_URL}/page_views`
export const USER_SEGMENT_FAVORITE_PATH = (id: string | number) =>
  `${BASE_URL}/manage/filter_views/${id}/favourites`
// Enterprise
export const ENTERPRISE_CUSTOMIZATION_PATH = `${BASE_URL}/manage/organization/enterprise_customizations`

// Websockets
export const SYNC_SERVER_PATH = `${BASE_URL}/authentication/resource_sync`

export const CHAT_PATH = `${BASE_URL}/organizations/global_ai_search`
