import { type VariantProps, cva } from 'class-variance-authority'

// Common style groups
const commonStyles = {
  text: {
    default: 'text-gray12',
    light: 'text-gray12/70',
    primary: 'text-primary',
    snow: 'text-snow',
  },
  border: {
    default: 'border border-transparent',
    dashed: '!border-dashed !border-gray8',
    primary: 'border border-primary',
  },
  shadow: {
    default: 'shadow-tooltip hover:shadow-none',
    none: '!shadow-none',
  },
  background: {
    default: 'bg-fosblue',
    transparent: 'bg-transparent',
    primary: 'bg-primary',
    gray: 'bg-gray1',
  },

  hover: {
    primary: 'hover:bg-primary-dark',
    transparent: 'hover:bg-transparent',
    underline: 'hover:no-underline',
  },
}

type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'

// Size mappings for responsive styles
const sizeMappings: Record<
  ButtonSize,
  { padding: string; mdPaddingX: string; mdPaddingY: string; mdText: string }
> = {
  xxs: {
    padding: '0.5',
    mdPaddingX: '1.5',
    mdPaddingY: '[2px]',
    mdText: '[11px]',
  },
  xs: { padding: '1', mdPaddingX: '2.5', mdPaddingY: '[4px]', mdText: 'xs' },
  sm: {
    padding: '1.5',
    mdPaddingX: '3',
    mdPaddingY: '2',
    mdText: 'sm leading-4',
  },
  md: { padding: '2', mdPaddingX: '4', mdPaddingY: '2', mdText: 'sm' },
  lg: { padding: '2.5', mdPaddingX: '4', mdPaddingY: '2', mdText: 'base' },
  xl: { padding: '3', mdPaddingX: '6', mdPaddingY: '3', mdText: 'base' },
}

// Fab size mappings
const fabSizeMappings: Record<Exclude<ButtonSize, 'xxs'>, string> = {
  xs: '1',
  sm: '1.5',
  md: '2',
  lg: '2.5',
  xl: '3',
}

export const buttonVariants = cva(
  // Base styles
  [
    'inline-flex',
    'max-w-full',
    'shrink-0',
    'cursor-pointer',
    'appearance-none',
    'items-center',
    'space-x-1',
    'overflow-hidden',
    'whitespace-nowrap',
    'font-medium',
    'outline-0',
    'transition-colors',
    'duration-150',
    'focus:outline-none',
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
    commonStyles.border.default,
    commonStyles.hover.underline,
  ],
  {
    variants: {
      variant: {
        default: [
          commonStyles.background.default,
          commonStyles.text.snow,
          commonStyles.shadow.default,
        ],
        primary: [
          commonStyles.background.primary,
          commonStyles.text.snow,
          commonStyles.hover.primary,
          commonStyles.border.primary,
        ],
        secondary: [
          commonStyles.background.gray,
          commonStyles.text.default,
          '!border-gray5 hover:border-fosblue/50 hover:bg-fosblue/10 dark:bg-gray-dark dark:hover:bg-primary-dark',
        ],
        outline: [
          commonStyles.background.gray,
          commonStyles.text.default,
          'border !border-[rgba(15,15,15,0.15)] hover:bg-gray3 hover:shadow-none active:border-gray7',
        ],
        naked: [
          commonStyles.background.transparent,
          commonStyles.text.light,
          commonStyles.shadow.none,
          'hover:text-primary focus:outline-0',
        ],
        dashed: [
          commonStyles.background.transparent,
          commonStyles.border.dashed,
          'text-gray8 hover:border-gray9 hover:text-gray9',
        ],
        link: [
          commonStyles.background.transparent,
          commonStyles.text.primary,
          'transform duration-200 hover:bg-primary hover:bg-opacity-25 hover:text-snow dark:hover:text-primary-dark',
        ],
        text: 'bg-gray5 text-gray11 hover:bg-gray4 focus:ring-gray5',
        danger:
          'border-transparent bg-red10/10 text-red10 hover:border-red10/5',
        success:
          'border-green9 bg-green9 text-snow hover:bg-green10 hover:text-snow focus:ring-green8',
        warning: [
          commonStyles.border.default,
          'border-yellow8/50 bg-yellow7 text-gray12 hover:border-yellow8 hover:bg-yellow7/90 hover:text-gray10',
          '[text-shadow:rgb(0_0_0_/_15%)_0.1px_0.5px_0.5px]',
          '[box-shadow:rgb(0_0_0_/_15%)_0px_0.5px_1px_0px]',
          'dark:[text-shadow:rgb(0_0_0_/_35%)_0px_1px_1px]',
          'dark:[box-shadow:rgb(0_0_0_/_35%)_0px_1px_1px]',
        ],
      },
      size: {
        xxs: 'px-1.5 py-[2px] text-[11px]',
        xs: 'px-2.5 py-[4px] text-xs',
        sm: 'px-3 py-2 text-sm leading-4',
        md: 'px-4 py-2 text-sm',
        lg: 'px-5 py-2 text-base',
        xl: 'px-6 py-3 text-base',
      },
      rounded: {
        xxs: 'rounded-sm',
        xs: 'rounded-sm',
        sm: 'rounded',
        md: 'rounded-md',
        lg: 'rounded-lg',
        xl: 'rounded-xl',
        full: 'rounded-full',
      },
      fab: {
        true: '',
      },
      isResponsive: {
        true: '',
      },
      block: {
        true: 'flex w-full items-center justify-center',
      },
      textAlign: {
        left: 'justify-start',
        center: 'justify-center',
        right: 'justify-end',
      },
    },
    compoundVariants: [
      // Fab sizes - using a more systematic approach
      ...Object.entries(fabSizeMappings).map(([size, padding]) => ({
        fab: true,
        size: size as Exclude<ButtonSize, 'xxs'>,
        class: `p-${padding}`,
      })),
      // Responsive sizes - using a more systematic approach
      ...Object.entries(sizeMappings).map(
        ([size, { padding, mdPaddingX, mdPaddingY, mdText }]) => ({
          isResponsive: true,
          size: size as ButtonSize,
          class: `p-${padding} md:px-${mdPaddingX} md:py-${mdPaddingY} md:text-${mdText}`,
        })
      ),
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'sm',
      rounded: 'md',
      textAlign: 'center',
    },
  }
)

export type ButtonVariantProps = VariantProps<typeof buttonVariants>
