import {
  Code,
  Link,
  ListDashes,
  ListNumbers,
  TextBolder,
  TextHOne,
  TextHTwo,
  TextItalic,
  TextStrikethrough,
  TextUnderline,
} from '@phosphor-icons/react'
import React from 'react'

import EnhanceButton from '@/components/shared/components/editor/menu/buttons/EnhanceButton'
import ImageUploadButton from '@/components/shared/components/editor/menu/buttons/ImageUploadButton'
import VideoUploadButton from '@/components/shared/components/editor/menu/buttons/VideoUploadButton'
import Tooltip from '@/components/shared/ui/Tooltip/Tooltip'
import { useTranslations } from '@/hooks/useTranslations'
import type { IMenuPropTypes } from '@/types/editor'

import EditorStyle from '../Editor.module.scss'
import {
  ACTIVE_CLASS,
  DEFAULT_BUTTON_CLASS,
  ICON_CLASS,
} from '../editorConstants'

const EditorMenuBar = ({
  editor,
  className,
  actions,
  editable,
  onAIComplete,
  support,
}: IMenuPropTypes) => {
  const t = useTranslations('editor')

  if (!editor || !editable) {
    return null
  }

  return (
    <div className={className}>
      <div className={EditorStyle['hnui-menu-bar']}>
        <Tooltip asChild text={t('bold')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleMark('bold').run()}
            className={`${
              editor.isActive('bold') ? ACTIVE_CLASS : DEFAULT_BUTTON_CLASS
            }`}
          >
            <TextBolder weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('italic')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleMark('italic').run()}
            className={
              editor.isActive('italic') ? ACTIVE_CLASS : DEFAULT_BUTTON_CLASS
            }
          >
            <TextItalic weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('underline')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleMark('underline').run()}
            className={
              editor.isActive('underline') ? ACTIVE_CLASS : DEFAULT_BUTTON_CLASS
            }
          >
            <TextUnderline weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('strike_through')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleMark('strike').run()}
            className={
              editor.isActive('strike') ? ACTIVE_CLASS : DEFAULT_BUTTON_CLASS
            }
          >
            <TextStrikethrough weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('h1')} disableHoverableContent>
          <button
            type='button'
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            className={
              editor.isActive('heading', { level: 1 })
                ? ACTIVE_CLASS
                : DEFAULT_BUTTON_CLASS
            }
          >
            <TextHOne weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('h2')} disableHoverableContent>
          <button
            type='button'
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive('heading', { level: 2 })
                ? ACTIVE_CLASS
                : DEFAULT_BUTTON_CLASS
            }
          >
            <TextHTwo weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('code_block')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            className={
              editor.isActive('codeBlock') ? ACTIVE_CLASS : DEFAULT_BUTTON_CLASS
            }
          >
            <Code weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('list')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={
              editor.isActive('bulletList')
                ? ACTIVE_CLASS
                : DEFAULT_BUTTON_CLASS
            }
          >
            <ListDashes weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('list_numbers')} disableHoverableContent>
          <button
            type='button'
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={
              editor.isActive('orderedList')
                ? ACTIVE_CLASS
                : DEFAULT_BUTTON_CLASS
            }
          >
            <ListNumbers weight='bold' className='h-4 w-4' />
          </button>
        </Tooltip>
        <Tooltip asChild text={t('button.link')} disableHoverableContent>
          <button
            type='button'
            data-testid='link_button'
            className={`${DEFAULT_BUTTON_CLASS}`}
            onClick={() => actions.openLinkDialog()}
          >
            <Link weight='bold' className={ICON_CLASS} />
          </button>
        </Tooltip>
        <ImageUploadButton actions={actions} />
        <VideoUploadButton actions={actions} support={support} />
        {onAIComplete && (
          <EnhanceButton onClick={(action) => onAIComplete(editor, action)} />
        )}
      </div>
    </div>
  )
}
export default EditorMenuBar
