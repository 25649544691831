// import * as PopoverPrimitive from '@radix-ui/react-popover'
import { CaretDown, Check, X } from '@phosphor-icons/react'
import clsx from 'clsx'
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Label from '@/components/shared/ui/Label'
import SelectDialog from '@/components/shared/ui/Select/SelectDialog'
import SelectItem from '@/components/shared/ui/Select/SelectItem'
import SelectPopover from '@/components/shared/ui/Select/SelectPopover'
import type { ISelectInternalRef } from '@/components/shared/ui/Select/SelectTypes'
import Typography from '@/components/shared/ui/Typography'
import { useIsMobile } from '@/hooks/useIsMobile'
import { useTranslations } from '@/hooks/useTranslations'
import {
  addRootIndex,
  getItemIndexinObjectArray,
  getUniqueKeys,
  sortArrayOfObjectAlphabatic,
} from '@/lib/helpers/dataHelpers'
import { stringToSlug } from '@/lib/helpers/stringHelpers'

import SelectStyle from './Select.module.scss'

const DEBOUNCE_TIMER = 300

export type ISelectOptionGroup = ISelectOption | ISelectOption[] | undefined
export interface ISelectOption {
  [name: string]: any
  group?: string
  label: string
  subLabel?: string
  value: any
  id?: string | number
  isNew?: boolean
  rootIndex?: number
}
export interface ISelectPropTypes {
  children?: React.ReactNode
  inputProps?: React.HTMLAttributes<HTMLInputElement>
  options?: ISelectOption[]
  placeholder: string
  searchPlaceholder?: string
  defaultValue?: ISelectOption[] | null
  value?: ISelectOption[] | null
  multiple?: boolean
  disabled?: boolean
  onChange?: (
    selectedItems: ISelectOptionGroup,
    previousSelectedItems?: ISelectOptionGroup
  ) => any
  loading?: boolean
  group?: boolean
  clearable?: boolean
  searchable?: boolean
  showAvatar?: boolean
  variant?: 'blank' | 'outline' | 'dashed' | 'pilled'
  placeholderIcon?: React.ReactNode
  container?: HTMLElement
  width?: string
  creatable?: boolean
  emptyMessage?: string
  emptySearchMessage?: string
  loadOptions?: (search: string) => Promise<ISelectOption[]>
  showSelectedOptionsCount?: boolean
  selectAll?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  disableSelected?: boolean
  renderTrigger?: (props: ISelectPropTypes) => JSX.Element
  onFocusChange?: (focused: boolean) => void
  onOpen?: (open: boolean) => void
  minimumSearchCharacterLength?: number
  side?: 'top' | 'right' | 'bottom' | 'left'
  dataTestId?: string
  className?: string
  avoidSort?: boolean
  rounded?: boolean
  searchableKeys?: string[]
  itemFilter?: (item: ISelectOption) => boolean
}

export const Select = (props: ISelectPropTypes) => {
  const {
    options: _options,
    placeholder,
    searchPlaceholder,
    multiple = false,
    onChange,
    defaultValue = [],
    value,
    loading = false,
    disabled = false,
    group = false,
    searchable = false,
    // variant = 'outline',
    showAvatar = false,
    clearable = false,
    placeholderIcon,
    creatable = false,
    // container,
    // width,
    emptyMessage,
    emptySearchMessage,
    loadOptions,
    showSelectedOptionsCount = true,
    selectAll = true,
    size = 'sm',
    disableSelected = false,
    renderTrigger,
    minimumSearchCharacterLength = 0,
    side,
    dataTestId = '',
    className,
    avoidSort = false,
    rounded = true,
    searchableKeys = [],
    itemFilter = () => true,
  } = props

  const t = useTranslations()
  const isMobileScreen = useIsMobile()
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<ISelectOption[]>(
    addRootIndex(_options) || []
  )
  const [query, setQuery] = useState<string | null>(null)
  const previousSelectedItem = useRef<ISelectOptionGroup>()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const selectRef = useRef<ISelectInternalRef>(null)
  const [selectedItems, setSelectedItems] = useState<ISelectOption[]>(
    defaultValue || value || []
  )
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const debounceRef = useRef<NodeJS.Timeout>()
  const [isSearchLoading, setIsSearchLoading] = useState(false)

  const allOptions = useMemo(() => {
    return [...options, ...selectedItems].filter(
      (option, index, self) =>
        index === self.findIndex((s: ISelectOption) => s.value === option.value)
    )
  }, [options, selectedItems])

  const searchedOptions = useMemo(() => {
    return allOptions
      .filter((option) => {
        if (query) {
          return [...searchableKeys, 'label'].some((key) => {
            return option?.[key]?.toLowerCase().includes(query.toLowerCase())
          })
        }
        return true
      })
      .filter(itemFilter)
  }, [allOptions, query, searchableKeys])

  const itemsGroups = useMemo(() => {
    let lastIndex = -1
    return getUniqueKeys(searchedOptions).map((item) => {
      const items = avoidSort
        ? searchedOptions
        : sortArrayOfObjectAlphabatic(searchedOptions, 'label')

      const sortedItems = items
        .filter((opt) => opt.group === item)
        .map((itm) => {
          lastIndex += 1
          return { ...itm, rootIndex: lastIndex }
        })
      return { label: item, items: sortedItems }
    })
  }, [searchedOptions])

  const getHighlightedItem = () => {
    return itemsGroups
      .map((ig) => ig.items)
      .flat()
      .find((item) => item.rootIndex === highlightedIndex)
  }

  const handleReset = () => {
    if (onChange) {
      onChange(multiple ? [] : undefined, previousSelectedItem.current)
    }
    if (!value) {
      setSelectedItems([])
    }
  }

  const initialized = useRef<boolean>(false)

  // const isLargeScreenDevice = useMediaQuery({ width: 750 })

  const handleSelect = (e: any, option?: ISelectOption) => {
    if (!option) return
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    // if (searchInputRef.current) searchInputRef.current.blur()
    if (
      disableSelected &&
      getItemIndexinObjectArray(option, 'value', selectedItems) > -1
    )
      return
    setHighlightedIndex(highlightedIndex || option.rootIndex || 0)
    const optionPresent = selectedItems.find(
      (item) => item.value === option.value
    )

    if (option.isNew) {
      setQuery('')
    }
    if (multiple) {
      const newSelectedItems = optionPresent
        ? [...selectedItems].filter((item) => item.value !== option.value)
        : [...selectedItems, option]

      if (!value) setSelectedItems(newSelectedItems)
      if (onChange) onChange(newSelectedItems, previousSelectedItem.current)
    } else {
      const newSelectedItems = optionPresent ? [] : [option]
      if (!value) setSelectedItems(newSelectedItems)
      if (onChange) {
        if (clearable) {
          onChange(
            optionPresent ? undefined : option,
            previousSelectedItem.current
          )
        } else onChange(option, previousSelectedItem.current)
      }
      // setOpen(false)

      // searchInputRef.current?.focus()
      selectRef.current?.close()
    }
  }

  useEffect(() => {
    if (!initialized.current) return
    previousSelectedItem.current = selectedItems.length
      ? selectedItems[0]
      : undefined
  }, [selectedItems])

  useEffect(() => {
    if (value === undefined) return
    setSelectedItems(value || [])
  }, [value])

  useEffect(() => {
    if (loadOptions) {
      setIsSearchLoading(true)
      loadOptions(query || '')
        .then(setOptions)
        .catch(() => setOptions([]))
        .finally(() => {
          setIsSearchLoading(false)
        })
    }
    initialized.current = true
  }, [])

  useEffect(() => {
    if (loadOptions) {
      setIsSearchLoading(true)
      if (query && minimumSearchCharacterLength < query?.length) {
        if (debounceRef.current) clearTimeout(debounceRef.current)
        debounceRef.current = setTimeout(() => {
          setIsSearchLoading(true)
          return loadOptions(query)
            .then((opts: ISelectOption[] = []) => {
              if (query.length) {
                setOptions(addRootIndex(opts) as ISelectOption[])
              } else {
                setOptions(addRootIndex(_options) || [])
              }
            })
            .finally(() => setIsSearchLoading(false))
        }, DEBOUNCE_TIMER)
      } else {
        setOptions(_options || [])
      }
    }
    setHighlightedIndex(0)
  }, [query])

  useEffect(() => {
    setOptions(addRootIndex(_options) || [])
  }, [_options])

  useEffect(() => {
    if (!open) setQuery(null)
    if (!group) {
      setHighlightedIndex(
        selectedItems.length
          ? options.findIndex((item) => item.value === selectedItems[0]?.value)
          : 0
      )
    } else {
      const items = itemsGroups.map((ig) => ig.items).flat()
      setHighlightedIndex(
        items.length
          ? items.findIndex((item) => item.value === selectedItems[0]?.value)
          : 0
      )
    }
  }, [open])

  useEffect(() => {
    const higlightedEl = document.querySelector(
      `[data-focus-id="select-item-${highlightedIndex}"`
    ) as HTMLElement | null
    if (higlightedEl) {
      higlightedEl.focus()
      searchInputRef.current?.focus()
    }
  }, [highlightedIndex])

  const handleSelectAll = (selected: boolean) => {
    if (selected) {
      const newSelectedItems = options
      if (!value) setSelectedItems(newSelectedItems)
      if (onChange) onChange(newSelectedItems, previousSelectedItem.current)
    } else {
      handleReset()
    }
  }

  const getNewValue = (q: string) => ({
    label: `${q}`,
    value: q,
    isNew: true,
  })

  const renderSelect = (option: ISelectOption, i: number) => {
    const Icon = option.icon
    const isSelected =
      selectedItems.findIndex((item) => item.value === option.value) > -1 ||
      option.selected
    const isHighlighted = highlightedIndex === option.rootIndex
    return (
      <SelectItem
        key={i}
        onSelect={(e) => handleSelect(e, option)}
        disabled={!multiple && Boolean(isSelected) && !clearable}
        className={isSelected ? 'bg-gray2 !text-primary ' : ''}
        RightSlot={
          multiple && !showAvatar && isSelected && <Check weight='bold' />
        }
        icon={Icon && Icon}
        endIcon={option.endIcon}
        itemSubValue={option.subLabel}
        itemvalue={
          option.isNew
            ? t('ui.select.add', {
                data: {
                  text: `"${option.label}"`,
                },
              })
            : option.label
        }
        dataTestId={`${dataTestId}-item-${stringToSlug(
          option.value.toString()
        )}`}
        avatarUrl={option?.imageURL}
        showAvatar={showAvatar}
        isHighlighted={isHighlighted}
        onHover={() => setHighlightedIndex(option.rootIndex || 0)}
        index={option.rootIndex || 0}
      />
    )
  }

  const renderLabel = () => {
    if (loading) return 'Loading...'
    if (!multiple && selectedItems.length && showSelectedOptionsCount) {
      return (
        <div className='flex w-full items-center space-x-1 truncate font-medium'>
          {selectedItems.map((item, index) => {
            const itemLabel = item.label
            if (item?.icon)
              return (
                <>
                  <span>{item.icon}</span>
                  <p data-testid={`${dataTestId}-trigger-label`}>{itemLabel}</p>
                </>
              )
            return (
              <span
                key={index}
                className='truncate text-gray12'
                data-testid={`${dataTestId}-trigger-label`}
              >
                {itemLabel}
              </span>
            )
          })}
        </div>
      )
    }
    if (multiple && selectedItems.length && showSelectedOptionsCount) {
      return (
        <div className='flex w-full items-center space-x-1 truncate font-medium'>
          <span className='truncate'>
            {selectedItems.length < 2
              ? selectedItems.map((item) => item.label)
              : `${selectedItems.length} selected`}
          </span>
        </div>
      )
    }
    return (
      <div className='flex items-center space-x-1 truncate'>
        {placeholderIcon && <span className='shrink-0'>{placeholderIcon}</span>}
        <span
          className='truncate text-gray10 group-hover:text-primary/20 dark:text-gray10 dark:group-hover:text-primary/20'
          data-testid={`${dataTestId}-trigger-placeholder`}
        >
          {placeholder}
        </span>
      </div>
    )
  }

  const renderGroupOptions = () => {
    let lastIndex = -1

    if (!group)
      return searchedOptions
        .map((itm) => {
          lastIndex += 1
          return { ...itm, rootIndex: lastIndex }
        })
        .map(renderSelect)

    return (
      <>
        {itemsGroups.map(({ label, items }) => {
          if (!items.length) return <></>
          return (
            <Fragment key={label}>
              <Label className={clsx(SelectStyle['hnui-Select-label'])}>
                {label}
              </Label>
              {items.map(renderSelect)}
            </Fragment>
          )
        })}
      </>
    )
  }

  const SelectWrapper = isMobileScreen ? SelectDialog : SelectPopover

  return (
    <SelectWrapper
      ref={selectRef}
      disabled={disabled}
      side={side}
      searchable={searchable}
      onOpenChange={setOpen}
      dataTestId={`${dataTestId}-trigger`}
      trigger={
        renderTrigger ? (
          renderTrigger(props)
        ) : (
          <div
            tabIndex={1}
            className={clsx(
              'group flex w-full items-center justify-between space-x-2 truncate border border-gray7 bg-snow font-medium text-gray12/80 transition duration-200 placeholder:text-gray12/70 hover:border-primary hover:bg-primary/5 hover:text-primary focus:outline-none focus:ring-primary dark:bg-gray-dark dark:placeholder:text-gray9 ',
              SelectStyle[`hnui-Select--${size}`],
              disabled ? 'cursor-not-allowed opacity-50' : '',
              loading ? 'cursor-progress opacity-50' : '',
              rounded ? 'rounded-full' : 'rounded-md',
              className
            )}
          >
            {renderLabel()}
            {Boolean(
              !clearable ||
                !selectedItems.length ||
                selectedItems[0]?.label === placeholder
            ) && <CaretDown weight='bold' className='shrink-0' />}
            {Boolean(
              clearable &&
                selectedItems?.length &&
                selectedItems[0]?.label !== placeholder &&
                !disabled
            ) && (
              <X
                weight='bold'
                className='right-0 h-3 w-3 shrink-0 text-gray7 transition duration-200 hover:text-gray8'
                data-testid={`${dataTestId}-clear`}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleReset()
                }}
              />
            )}
          </div>
        )
      }
    >
      {Boolean(searchable || creatable) && (
        <div className='sticky top-0 z-[1] border-b border-gray5 bg-snow dark:border-gray-dark-border dark:bg-gray-dark-hover'>
          <input
            ref={searchInputRef}
            className='w-full bg-transparent px-3 py-2.5 text-sm hover:ring-0 focus:outline-none focus:ring-0 focus-visible:outline-none'
            placeholder={searchPlaceholder || t('ui.select.placeholder')}
            value={query || ''}
            {...props.inputProps}
            onChange={(e) => {
              e.stopPropagation()
              setQuery(e.target.value)
            }}
            autoFocus={false}
            onKeyDown={(e) => {
              if (e.key === 'ArrowDown') {
                e.preventDefault()
                if (highlightedIndex === searchedOptions.length - 1) return
                setHighlightedIndex(highlightedIndex + 1)
              }
              if (e.key === 'ArrowUp') {
                e.preventDefault()
                if (highlightedIndex === 0) return
                setHighlightedIndex(highlightedIndex - 1)
              }
              if (e.key === 'Enter' && highlightedIndex !== -1) {
                e.preventDefault()
                const highlightedItem = getHighlightedItem()
                if (highlightedItem) {
                  handleSelect(e, highlightedItem)
                } else if (query?.length && creatable) {
                  handleSelect(e, getNewValue(query))
                  setQuery('')
                }
              }
              if (e.key === 'Escape') {
                e.preventDefault()
                setOpen(false)
              }
            }}
          />
        </div>
      )}
      {loading && (
        <Typography.Text className='flex items-center p-4'>
          {t('common.labels.loaders.loading')}
        </Typography.Text>
      )}
      {!loading && (
        <div className={clsx(searchable ? 'mt-2' : 'mt-0.5', 'min-w-full')}>
          {Boolean(selectAll && multiple && Boolean(options?.length)) && (
            <div className='rounded-lg px-1 py-2 hover:bg-gray5 '>
              <Checkbox
                checkboxClassName='!hidden'
                onCheckedChange={handleSelectAll}
                checked={options.length === selectedItems.length}
                label={
                  options?.length === selectedItems?.length
                    ? t('common.unselectAll')
                    : t('common.selectAll')
                }
              />
            </div>
          )}
          <>
            {!searchedOptions.length &&
              !query &&
              Boolean(minimumSearchCharacterLength === 0) && (
                <span className='flex select-none items-center justify-center px-3 py-2  text-center text-sm font-medium'>
                  {/* TODO Needs translation */}
                  {emptyMessage || t('common.noItems')}
                </span>
              )}
            {!searchedOptions.length &&
              query &&
              !creatable &&
              !isSearchLoading && (
                <span className='flex select-none items-center justify-center px-3 py-2 text-center text-sm font-medium'>
                  {emptySearchMessage || emptyMessage || t('common.noItems')}
                </span>
              )}
            {!searchedOptions.length &&
              query &&
              creatable &&
              renderSelect(getNewValue(query), 0)}
            {renderGroupOptions()}
            {query && !creatable && isSearchLoading && (
              <span className='flex select-none items-center justify-center px-3 py-2 text-center text-sm font-medium'>
                {t('common.labels.loaders.searching')}
              </span>
            )}
          </>
        </div>
      )}
    </SelectWrapper>
  )
}
