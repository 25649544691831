import clsx from 'clsx'
import React from 'react'

import UserAvatar from '@/components/shared/components/UserAvatar'
import Tooltip from '@/components/shared/ui/Tooltip/'

import AvatarGroupStyle from './AvatarGroup.module.scss'

interface IAvatarPropType {
  avatars: {
    id: string
    name?: string
    profile_picture?: {
      url?: string
    }
  }[]
  limit?: number
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  rounded?: boolean
  handleClick?: () => void
  totalCounts: number
  dataTestId?: string
  extras: number
}

export default function AvatarGroup({
  avatars,
  limit = 2,
  size = 'xs',
  rounded,
  handleClick,
  totalCounts,
  dataTestId = '',
  extras = 0,
}: IAvatarPropType) {
  const renderAvatar = (user: any, index: number) => {
    return (
      index < limit && (
        <Tooltip key={user?.id} text={user?.name}>
          <UserAvatar user={user} rounded={rounded} size={size} isMulti />
        </Tooltip>
      )
    )
  }

  const renderCount = () => {
    if (totalCounts > limit && extras > 0) {
      return (
        <span
          className={clsx(
            'z-[1] flex cursor-pointer items-center justify-center rounded-full bg-gray5 ring-2 ring-snow transition-transform duration-200  ',
            AvatarGroupStyle[`hnui-avatar--${size}`]
          )}
        >{`+${extras}`}</span>
      )
    }
    return false
  }

  return (
    <div
      className='relative flex items-center space-x-[-7%]'
      data-testid={dataTestId}
      onClick={handleClick}
    >
      {avatars?.map(renderAvatar)}
      {renderCount()}
    </div>
  )
}
