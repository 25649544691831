import * as HoverCardPrimitive from '@radix-ui/react-hover-card'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import HoverCardStyle from './HoverCard.module.scss'

interface IHoverCardContentProps
  extends HoverCardPrimitive.HoverCardContentProps {
  children?: React.ReactNode
  className?: string
}

interface IHoverCardProps extends HoverCardPrimitive.HoverCardProps {
  children?: React.ReactNode
  className?: string
  asChild?: boolean
}

export const HoverCardContent: FC<IHoverCardContentProps> = ({
  children,
  align,
  className,
  ...props
}) => (
  <HoverCardPrimitive.Portal>
    <HoverCardPrimitive.Content
      {...props}
      sideOffset={5}
      alignOffset={5}
      align={align}
      className={clsx(HoverCardStyle['hnui-hovercard'], className)}
    >
      {children}
    </HoverCardPrimitive.Content>
  </HoverCardPrimitive.Portal>
)

export const HoverCardTrigger: FC<IHoverCardProps> = ({
  children,
  className,
  asChild = false,
  ...props
}) => (
  <HoverCardPrimitive.Trigger
    className={className}
    asChild={asChild}
    {...props}
  >
    {children}
  </HoverCardPrimitive.Trigger>
)

export const HoverCard: FC<IHoverCardProps> = ({
  children,
  openDelay,
  ...props
}) => (
  <HoverCardPrimitive.Root
    {...props}
    openDelay={openDelay || 0}
    closeDelay={100}
  >
    {children}
  </HoverCardPrimitive.Root>
)

HoverCard.displayName = 'HoverCard'
