import { Label } from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import React, { useRef, useState } from 'react'

import GitlabIssuesList from '@/components/posts/singlePost/postActions/integrations/gitlab/GitlabIssuesList'
import Input from '@/components/shared/ui/Input'
import Spinner from '@/components/shared/ui/Loader'
import { Select } from '@/components/shared/ui/Select/Select'
import { useTranslations } from '@/hooks/useTranslations'
import { convertObjForSelect } from '@/lib/helpers/dataHelpers'
import { searchGitlabProjects } from '@/models/Integration'
import { searchGitlabIssues } from '@/models/integration/Gitlab'
import { linkPostAddon } from '@/models/Post'
import type { IGitlabSearchIssue } from '@/types/integration/gitlab'
import type { IPost } from '@/types/post'
import type { IPostAddon } from '@/types/post/postAddon'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  onLink: (data: IPostAddon) => void
}
export default function LinkGitlabIssue({ post, onLink }: IPropTypes) {
  const t = useTranslations(`post.integrations.gitlab`)
  const timer = useRef<any>()
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState(``)
  const [issues, setIssues] = useState<IGitlabSearchIssue[]>([])
  const [filter, setFilter] = useState<{
    project_id: string
  }>({
    project_id: '',
  })

  const handleRepoSearch = (q: string) => {
    return searchGitlabProjects({ query: q }).then((data) => {
      return convertObjForSelect(data || [], 'name', 'id')
    })
  }

  const handleSearch = (value: string) => {
    if (value.length > 0) {
      setIsLoading(true)
      setQuery(value)
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        searchGitlabIssues({
          query: value,
          project_id: filter.project_id,
          feature_request_id: post.id,
        })
          .then((data) => setIssues(data))
          .catch((err) => toaster.error({ message: err.message }))
          .finally(() => setIsLoading(false))
      }, 500)
    } else {
      setQuery(query)
      setIssues([])
      if (timer.current) clearTimeout(timer.current)
      setIsLoading(false)
    }
  }

  const handleSelect = (value: IGitlabSearchIssue) => {
    const task_data = {
      issue_id: value.issue_id.toString(),
      project_id: value.project_id.toString(),
      provider: 'gitlab',
      number: value.issue_id.toString(),
      reference_id: value.issue_id.toString(),
    }
    linkPostAddon(post.slug, task_data)
      .then((data) => onLink(data))
      .then(() => toaster.success({ message: t(`postLinkedToGitlab`) }))
      .catch((err) => toaster.error({ message: err.message }))
  }

  return (
    <div className='mb-4 mt-3 space-y-4'>
      <div>
        <Label>{t(`form.project.title`)}</Label>
        <Select
          searchable
          clearable
          placeholder={t(`form.project.placeholder`)}
          searchPlaceholder={t(`form.project.searchPlaceholder`)}
          options={[]}
          loadOptions={handleRepoSearch}
          rounded={false}
          emptyMessage='Please search for a project'
          emptySearchMessage='No Projects'
          onChange={(selectedValue) => {
            const selectedProject = Array.isArray(selectedValue)
              ? selectedValue[0]
              : selectedValue
            if (selectedProject) {
              setFilter({ ...filter, project_id: selectedProject?.value })
            } else {
              setFilter({ ...filter, project_id: '' })
            }
          }}
        />
      </div>
      <div
        className={clsx({
          'opacity-50': !filter.project_id,
        })}
      >
        <p
          className='pb-2 text-gray10'
          dangerouslySetInnerHTML={{ __html: t(`searchText`) }}
        />
        <Input
          autoFocus
          placeholder={t(`searchPlaceholder`)}
          disabled={!filter.project_id}
          readOnly={!filter.project_id}
          onChange={(e) => handleSearch((e.target as HTMLInputElement).value)}
        />
      </div>
      <div className='mt-2.5 rounded transition'>
        {isLoading ? (
          <Spinner />
        ) : (
          <GitlabIssuesList
            issues={issues}
            onSelect={handleSelect}
            query={query}
          />
        )}
      </div>
    </div>
  )
}
