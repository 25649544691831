import { CircleWavyCheck, Star } from '@phosphor-icons/react'
import * as AvatarPrimitive from '@radix-ui/react-avatar'
import clsx from 'clsx'
import type { FC } from 'react'
import React, { memo, useMemo } from 'react'

import AvatarStyles from './Avatar.module.scss'

export interface IAvatarProps {
  name: string | undefined
  url?: string
  isMulti?: boolean
  showdot?: boolean
  starred?: boolean
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | number
  rounded?: boolean
  showBadge?: boolean
  className?: string
}

export type IAvatarPropsWithoutName = Omit<IAvatarProps, 'name'>

const Avatar: FC<IAvatarProps> = ({
  url = '',
  name = '',
  size = 'sm',
  rounded,
  isMulti = false,
  starred,
  showdot = false,
  showBadge = false,
  className,
  // className = '',
}) => {
  const AvatarClass = [AvatarStyles['hnui-avatar']]
  const AvatarimgClass = [AvatarStyles['hnui-avatar-img']]
  const AvatarClassFallback = [AvatarStyles['hnui-avatar-fallback']]
  if (rounded) {
    AvatarimgClass.push(AvatarStyles[`hnui-avatar-rounded`])
    AvatarClass.push(AvatarStyles[`hnui-avatar-rounded`])
    AvatarClassFallback.push(AvatarStyles[`hnui-avatar-rounded`])
  } else {
    AvatarClass.push(AvatarStyles['hnui-avatar-squer'])
    AvatarimgClass.push(AvatarStyles[`hnui-avatar-squer`])
    AvatarClassFallback.push(AvatarStyles[`hnui-avatar-squer`])
  }
  if (size) AvatarClass.push(AvatarStyles[`hnui-avatar--${size}`])
  if (isMulti) AvatarClass.push(AvatarStyles['hnui-avatar-multi'])

  const dimension = useMemo(() => {
    if (size === 'xxs') return '20'
    if (size === 'xs') return '24'
    if (size === 'sm') return '32'
    if (size === 'md') return '40'
    if (size === 'lg') return '48'
    if (size === 'xl') return '64'
    return size
  }, [size])

  return (
    <AvatarPrimitive.Root className={clsx(AvatarClass.join(' '), className)}>
      <AvatarPrimitive.Image
        className={AvatarimgClass.join(' ')}
        src={url}
        alt={name}
        height={dimension}
        width={dimension}
      />
      <AvatarPrimitive.Fallback
        className={clsx(
          AvatarClassFallback.join(' '),
          `h-[${dimension}] w-[${dimension}]`
        )}
        delayMs={0}
      >
        {name?.charAt(0).toUpperCase() || '*'}
      </AvatarPrimitive.Fallback>
      {Boolean(starred) && !showBadge && (
        <Star
          weight='fill'
          data-testid='ui_avatar_show_star'
          className='absolute -bottom-1 -right-1 h-3.5 w-3.5 text-yellow9'
        />
      )}
      {Boolean(showBadge) && (
        <CircleWavyCheck
          className='absolute bottom-[-3px] right-[-3px] h-3.5 w-3.5 rounded-full bg-snow text-green8'
          weight='fill'
        />
      )}
      {Boolean(showdot) && (
        <span className='absolute -right-0 top-0.5 h-1.5 w-1.5 rounded-full bg-red8 ring-1 ring-snow' />
      )}
    </AvatarPrimitive.Root>
  )
}

Avatar.displayName = 'Avatar'

export default memo(Avatar)
