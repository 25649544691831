/** eslint-disable unused-imports/no-unused-vars */
import { ArrowLeft, SquareHalf } from '@phosphor-icons/react'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'

import AuthenticatedView from '@/components/auth/shared/AuthenticatedView'
import SinglePostCustomFields from '@/components/posts/customFields/SinglePostCustomFields'
import DownvoteButton from '@/components/posts/DownvoteButton'
import AdminSinglePostHeader from '@/components/posts/singlePost/AdminSinglePostHeader'
import SinglePostModerationAlert from '@/components/posts/singlePost/alert/SinglePostModerationAlert'
import SinglePostMergedPostsList from '@/components/posts/singlePost/mergedPostsList/SinglePostMergedPostsList'
import SinglePostPriorityRating from '@/components/posts/singlePost/postActions/SinglePostPriorityRating'
import PostAddonsList from '@/components/posts/singlePost/PostAddonsList'
import PostMatchingKBArticles from '@/components/posts/singlePost/postInsights/PostMatchingKBArticles'
import SinglePostActions from '@/components/posts/singlePost/SinglePostActions'
import SinglePostAttachments from '@/components/posts/singlePost/SinglePostAttachments'
import SinglePostTATSummary from '@/components/posts/tat/SinglePostTATSummary'
import UpvoteButton from '@/components/posts/UpvoteButton'
import type { IPropsActionType } from '@/components/shared/common/AsideActions'
import AsideActions from '@/components/shared/common/AsideActions'
import PageTitle from '@/components/shared/common/PageTitle'
import RichTextEditor from '@/components/shared/components/editor/RichTextEditor'
import ErrorPage from '@/components/shared/components/ErrorPage'
import HTMLViewer from '@/components/shared/components/HTMLViewer'
import Alert from '@/components/shared/ui/Alert/Alert'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import UnstyledLink from '@/components/shared/ui/Links/UnstyledLink'
import Spinner from '@/components/shared/ui/Loader'
import UserNotificationBanner from '@/components/users/profile/notifications/UserNotificationBanner'
import { HNAdminPostContext } from '@/context/HNAdminPostContext'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { getNewPostMentions } from '@/lib/helpers/modules/postHelper'
import { articleSuggestionEnaledCheck } from '@/lib/helpers/organizationHelper'
import { getPost, getPostFromDB } from '@/models/Post'
import FourNotFour from '@/pages/404'
import postStore from '@/stores/PostListStore'
import type { IBoardDetails } from '@/types/board'
import type { IEditorRef } from '@/types/editor'
import type {
  IIntegrations,
  IOrganizationData,
  IOrganizationPlan,
} from '@/types/organization'
import type { INewPost, IPost } from '@/types/post'
import type { IUserProfile } from '@/types/user'
import toaster from '@/utils/toast'

import SinglePostTab from '../posts/SinglePostTab'

interface IPropTypes {
  post?: IPost
  showHeader?: boolean
}
const UserPostSingle = observer(({ post: incomingPost }: IPropTypes) => {
  const t = useTranslations()
  const {
    userProfile,
    buckets,
    organization,
    embedType,
    default_module,
    integrations,
    organizationPlan,
    show_knowledge_base_tab,
  } = useContext(HNContext) as {
    userSignedIn: boolean
    noHeader: boolean
    userProfile: IUserProfile
    buckets: IBoardDetails[]
    organization: IOrganizationData
    embedType: string
    default_module: string
    integrations: IIntegrations
    organizationPlan: IOrganizationPlan
    show_knowledge_base_tab: boolean
    isAdmin: boolean
  }
  const router = useRouter()
  const { postSlug } = router.query as { postSlug: string }
  const [post, setPost] = useState(incomingPost)
  const [loading, setLoading] = useState(!incomingPost)
  const [error, setError] = useState(null)
  const actionsRef = useRef<IPropsActionType>(null)
  const [editing, setEditing] = useState(false)
  const [postData, setPostData] = useState<INewPost>({
    title: post?.title,
    description_html: post?.description_html,
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const descriptionEditorRef = useRef<IEditorRef>(null)

  const isRoadmapPath =
    router.pathname.includes('/roadmap') ||
    router.pathname.includes('/r') ||
    Boolean(default_module === 'roadmap' && router.pathname === '/')
  const postFromStore = postStore.posts.find((p) => {
    const postToFind =
      incomingPost || post?.slug === postSlug
        ? post
        : { slug: postSlug, id: undefined }
    return (
      String(postToFind?.id) === String(p.id) ||
      postToFind?.slug === p?.slug ||
      postToFind?.slug === p?.oldSlug
    )
  })

  const isArticleSuggestionEnaled = useMemo(
    () =>
      articleSuggestionEnaledCheck(
        organizationPlan,
        organization,
        show_knowledge_base_tab
      ),
    [organizationPlan]
  )

  const handleChange = (key: string, value: string) => {
    setPostData((_prevData) => ({ ..._prevData, [key]: value }))
  }

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (e) e.preventDefault()
    if (
      descriptionEditorRef.current &&
      !descriptionEditorRef.current?.isReadyToSubmit()
    ) {
      return toaster.info({
        message: t('messages.imagesUploading', { ignorePrefix: true }),
      })
    }
    if (!post) return null
    setSubmitting(true)
    return postStore
      .edit(post.slug, postData, false)
      .then((response) => {
        setPost({ ...post, ...response })
        setEditing(false)
        const oldSlug = post.slug
        postStore.updateSinglePost(oldSlug, {
          ...response,
          oldSlug: post.oldSlug || post.slug,
        })
        toaster.success({ message: t('post.messages.edit.success') })
      })
      .catch((err) => {
        toaster.error({ message: err.message })
      })
      .finally(() => setSubmitting(false))
  }

  const handleToggleEdit = () => setEditing(!editing)

  const fetchData = () => {
    if (!postSlug) return
    setLoading(true)
    getPost(postSlug)
      .then((newPost) => {
        setPost(newPost)
        postStore.appendPost({ ...newPost, fullData: true }, true)
      })
      .catch((err) => {
        return postStore.removeSinglePost(postSlug).then(() => {
          setError(err)
        })
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (incomingPost) return postStore.appendPost(incomingPost)
    getPostFromDB(postSlug).then((newPost) => {
      if (newPost) postStore.appendPost(newPost, true)
    })
    return fetchData()
  }, [router.query.postSlug])

  useEffect(() => {
    if (postFromStore) {
      setPost({ ...post, ...postFromStore })
    }
  }, [postFromStore])

  const handleShortcut = ({ action }: { action: string }) => {
    if (action === 'EDIT') {
      if (userProfile?.is_csm_of_organization) setEditing(true)
    }
  }

  const handleClose = () => {
    router.back()
  }

  useEffect(() => {
    EventEmitter.subscribe('SHORTCUT_TRIGGERED', handleShortcut)
    return () => {
      EventEmitter.unsubscribe('SHORTCUT_TRIGGERED', handleShortcut)
      EventEmitter.dispatch('CONFIG_UPDATE', {
        actionType: EVENT_ACTION_TYPES.UPDATE,
        entity: ENTITIES.HEADER_BOARD_NAME,
        data: {
          data: { boardId: null },
        },
      })
    }
  }, [])

  useEffect(() => {
    if (!post) return
    EventEmitter.dispatch('CONFIG_UPDATE', {
      actionType: EVENT_ACTION_TYPES.UPDATE,
      entity: ENTITIES.HEADER_BOARD_NAME,
      data: {
        data: { boardId: post.bucket_id },
      },
    })
  }, [post])

  const renderPriorityRating = () => {
    if (post && post.show_priority) {
      return <SinglePostPriorityRating post={post} />
    }
    return false
  }

  const renderMergePostAlert = () => {
    if (post && post.merged) {
      return (
        <div className='mx-auto my-2 max-w-7xl px-4'>
          <Alert
            type='info'
            rounded
            message={t('alerts.margedWith', {
              data: {
                title: `<b>${post?.parent_details?.title}</b>`,
              },
            })}
            actionText={'Go to parent post'}
            actionLink={post?.parent_details?.slug}
            target='_blank'
          />
        </div>
      )
    }
    return false
  }

  const renderHiddenAndModerationAlert = () => {
    if (
      post &&
      (post.hidden || post.approval_status === 'pending') &&
      post.status.value !== 'closed'
    ) {
      return <SinglePostModerationAlert post={post} />
    }
    return false
  }

  const renderPostDetail = () => {
    if (editing) {
      return (
        <form
          className='w-full space-y-3 overflow-hidden'
          onSubmit={handleSubmit}
        >
          <Input
            name='title'
            className='w-full border-none bg-transparent px-0 !text-xl !shadow-none focus:ring-0'
            borderless={false}
            defaultValue={post?.title}
            disabled={submitting}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange('title', e.target.value.trim())
            }
            autoFocus
          />
          <RichTextEditor
            ref={descriptionEditorRef}
            disabled={submitting}
            borderless={false}
            defaultValue={post?.description_html}
            onChange={(value) => handleChange('description_html', value)}
            mentionsConfig={[
              {
                indexName: 'FeatureRequest',
                filter: getNewPostMentions(userProfile, buckets, organization),
              },
            ]}
            support={{
              video: true,
            }}
            resource={{
              type: 'FeatureRequest',
              id: post?.id,
            }}
          />
          <div className='flex w-full justify-end gap-2 pt-2'>
            <Button
              size='xs'
              variant='text'
              type='button'
              disabled={submitting}
              onClick={() => setEditing(false)}
            >
              {t('buttons.cancel')}
            </Button>
            <Button type='submit' size='xs' disabled={submitting}>
              {t('buttons.update')}
            </Button>
          </div>
        </form>
      )
    }
    if (!editing && post) {
      return (
        <div className='mt-3 w-[calc(100%-50px)]'>
          <div className='flex items-center justify-between'>
            <h1 className='text-lg font-semibold'>{post?.title}</h1>
            {embedType !== 'postWidget' && (
              <div className='lg:hidden'>
                <Button
                  variant='outline'
                  fab
                  size='xs'
                  onClick={() => actionsRef?.current?.toggle?.()}
                >
                  <SquareHalf weight='duotone' className='h-4 w-4' />
                </Button>
              </div>
            )}
          </div>
          <HTMLViewer html={post?.description_html} />
        </div>
      )
    }
    return <></>
  }

  const renderBackButton = () => {
    if (!router?.query?.no_header || isRoadmapPath) return null
    return (
      <UnstyledLink
        href='/'
        className='-mb-2 flex items-center gap-1 pt-2'
        onClick={(e) => {
          e.preventDefault()
          router.back()
        }}
      >
        <ArrowLeft /> {t('buttons.back')}
      </UnstyledLink>
    )
  }

  if (loading && !post) return <Spinner />
  if (error) return <ErrorPage error={error} />
  if (!post) return <FourNotFour />

  return (
    <>
      <HNAdminPostContext.Provider
        value={{
          ...post,
          // eslint-disable-next-line prettier/prettier
          updateContext: () => {},
        }}
      >
        <UserNotificationBanner />
        <PageTitle title={post.title} description={post.short_description} />
        <div>
          {isRoadmapPath && (
            <AdminSinglePostHeader
              post={post}
              onEdit={() => setEditing(!editing)}
              onClose={() => router.back()}
            />
          )}

          {renderMergePostAlert()}
          {renderHiddenAndModerationAlert()}

          <div
            itemProp='itemListElement'
            itemScope
            itemType='https://schema.org/ListItem'
            className='mx-auto grid max-w-7xl grid-cols-1 space-x-0 rounded-md bg-snow px-2 scrollbar-hide md:grid-cols-12 md:px-4'
          >
            <div className='relative col-span-8 w-full space-y-4 px-1 scrollbar-hide md:px-2'>
              {renderBackButton()}
              <div className='flex flex-col gap-4 rounded-md'>
                <div className='mb-3 flex flex-nowrap space-x-3 rounded-md'>
                  <div className='sticky top-24 mt-3 flex h-full min-w-[35px] shrink-0 flex-col space-y-1'>
                    <UpvoteButton post={post} />
                    <DownvoteButton post={post} />
                  </div>
                  {renderPostDetail()}
                </div>
                <SinglePostCustomFields />
                <div className='flex flex-col gap-3'>
                  <AuthenticatedView
                    shouldShow={userProfile?.is_csm_of_organization}
                  >
                    <SinglePostTATSummary post={post} />
                  </AuthenticatedView>
                  {isArticleSuggestionEnaled && <PostMatchingKBArticles />}
                  <SinglePostAttachments post={post} />
                  <SinglePostMergedPostsList post={post} />
                  {renderPriorityRating()}
                  {integrations?.can_manage_post_addons && (
                    <PostAddonsList post={post} />
                  )}
                </div>
              </div>
              <div className='gap-4 rounded-md'>
                {!post.merged && <SinglePostTab post={post} />}
              </div>
            </div>
            <>
              {embedType !== 'postWidget' && (
                <AsideActions ref={actionsRef}>
                  <SinglePostActions
                    post={post}
                    toggleEdit={handleToggleEdit}
                    onClose={handleClose}
                    onCloseMenu={() => actionsRef?.current?.toggle?.()}
                  />
                </AsideActions>
              )}
            </>
          </div>
        </div>
      </HNAdminPostContext.Provider>
    </>
  )
})

export default UserPostSingle
