/* eslint-disable no-nested-ternary */
import {
  DotsThreeOutlineVertical,
  Envelope,
  EnvelopeOpen,
  Eye,
  EyeSlash,
  FloppyDiskBack,
  GitMerge,
  PushPin,
  PushPinSlash,
  Sparkle,
  Trash,
  X,
} from '@phosphor-icons/react'
import React, { useContext, useMemo, useRef, useState } from 'react'

import type { IBulkMergeDialogRef } from '@/components/admin/posts/merge/BulkMergeDialog'
import BulkMergeDialog from '@/components/admin/posts/merge/BulkMergeDialog'
import CannedResponseDropdown from '@/components/posts/comments/CannedResponseDropdown'
import RichTextEditor from '@/components/shared/components/editor/RichTextEditor'
import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Checkbox from '@/components/shared/ui/Checkbox/Checkbox'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/shared/ui/Dialog/Dialog'
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import type {
  ISelectOption,
  ISelectOptionGroup,
} from '@/components/shared/ui/Select/Select'
import Tooltip from '@/components/shared/ui/Tooltip'
import Typography from '@/components/shared/ui/Typography'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { pluralize } from '@/lib/helpers/appHelpers'
import { featureIsEnabled } from '@/lib/helpers/FeatureEnabledHelper'
import {
  getBulkPostUpdateContent,
  getBulkPostUpdateTranslationKey,
} from '@/lib/helpers/modules/postHelper'
import { bulkDelete, bulkUpdate } from '@/models/Post'
import postStore from '@/stores/PostListStore'
import type { IBoardDetails } from '@/types/board'
import type { IBulkSelectConfig, IFilters } from '@/types/common'
import type { IEditorRef } from '@/types/editor'
import type {
  ICustomState,
  IOrganizationPlan,
  ITagData,
} from '@/types/organization'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

import AdminPostAssigneeFilter from './filters/AdminPostAssigneeFilter'
import AdminPostBoardFilter from './filters/AdminPostBoardFilter'
import AdminPostStatusFilter from './filters/AdminPostStatusFilter'
import AdminPostTagsFilter from './filters/AdminPostTagsFilter'
import AdminPostModerationMoveToActionButton from './moderation/AdminModerationMoveToActionButton'

interface IPropTypes {
  selection: IBulkSelectConfig
  onSelectAll: (selected: boolean) => void
  totalCount: number
  onComplete: (data: any) => void
  onDelete: (data: any) => void
  isModeration?: boolean
  moderationAction?: string
  showSelectAll?: boolean
}

export interface IBulkActions {
  bucket_id?: [number]
  tag_id?: [number] | string
  state?: [string]
  assignee_id?: [number]
}

export interface IBulkUpdatePayload extends IBulkActions {
  feature_request_ids: string[]
  blacklist_ids: string[]
  filters: IFilters
  select_all: boolean
  viewed?: boolean
  read?: boolean
  fromTab?: string
  toTab?: string
  actionType?: string
  post?: IPost
  approval_status?: string
}

export default function AdminPostBulkSelect({
  selection,
  onSelectAll,
  totalCount,
  onComplete,
  isModeration = false,
  moderationAction,
  onDelete,
  showSelectAll = true,
}: IPropTypes) {
  const t = useTranslations('')
  const {
    custom_states,
    assignee,
    buckets,
    tags,
    userProfile,
    organizationPlan,
  } = useContext(HNContext)
  const [actions, setActions] = useState<IBulkActions>({})
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const statusChangeHTML = useRef<string | null>('')
  const bulkMergeDialogRef = useRef<IBulkMergeDialogRef>(null)
  const editorRef = useRef<IEditorRef>(null)
  const [selectedCannedResponse, setSelectedCannedResponse] =
    useState<ISelectOption | null>(null)
  const [doNotNotify, setDoNotNotify] = useState<boolean>(false)

  const totalSelectionCount = useMemo(() => {
    if (!selection.selectedAll) {
      return selection.selectedIds.length
    }
    return totalCount - selection.ignoredIds.length
  }, [selection.selectedIds.length, selection.ignoredIds.length, totalCount])
  const handleChange = (_key: string, _value: any) => {
    setActions((_actions: IBulkActions) => ({ ..._actions, [_key]: _value }))
  }

  const isActionSelected = () => {
    const objectKeys = Object.keys(actions)
    return objectKeys.some(
      (key) =>
        actions[key as keyof IBulkActions] &&
        actions[key as keyof IBulkActions]?.length
    )
  }

  const isFeatureEnabled =
    userProfile?.is_member_of_organization &&
    featureIsEnabled('ai_feature', organizationPlan as IOrganizationPlan)

  // Pin, Unpin, Hide, Unhide, Read, Unread all these actions handled here
  const handleSingleAction = (key: string, value: boolean) => {
    const payload = {
      feature_request_ids: selection.selectedIds,
      blacklist_ids: selection.ignoredIds,
      filters: { ...postStore.filters, ...postStore.globalFilters },
      select_all: selection.selectedAll,
      [key]: value,
    }
    setDisabled(true)
    return bulkUpdate(payload)
      .then((response) => {
        setShowDialog(false)
        setActions({})
        if (key === 'sentiment_regenerate') {
          toaster.info({
            message: t('posts.messages.bulkActionAIInfo'),
          })
        } else toaster.success({ message: t('posts.messages.postUpdated') })
        onComplete({
          bulkUpdateData: {
            ...payload,
            status: response?.hidden ? 'hidden' : 'hidden',
          },
          type: 'update',
          response,
        })
        onSelectAll(false)
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => {
        setDisabled(false)
        setShowDialog(false)
      })
  }

  // Board Change, Status Change, Assignee Change and Add Tag these actions handled here
  const handleBulkAction = (_doNotNotify?: boolean) => {
    const payload = {
      feature_request_ids: selection.selectedIds,
      blacklist_ids: selection.ignoredIds,
      filters: { ...postStore.filters, ...postStore.globalFilters },
      select_all: selection.selectedAll,
      do_not_notify: _doNotNotify,
      comment_html: statusChangeHTML.current,
    }
    Object.keys(actions).map((key: any) => {
      if (Object.hasOwnProperty.call(actions, key)) {
        // @ts-ignore
        if (actions[key] && actions[key].length) {
          // @ts-ignore
          // eslint-disable-next-line prefer-destructuring
          payload[key] = actions[key][0]
        }
      }
      return true
    })
    setDisabled(true)
    return bulkUpdate(payload)
      .then((response) => {
        statusChangeHTML.current = ''
        setShowDialog(false)
        setActions({})
        toaster.success({ message: t('posts.messages.postUpdated') })
        onComplete({
          bulkUpdateData: {
            ...payload,
            status: response?.custom_status?.status,
          },
          type: 'update',
          response,
        })
        onSelectAll(false)
      })
      .catch((err) => toaster.error({ message: err.message }))
      .finally(() => {
        setDisabled(false)
        setShowDialog(false)
      })
  }

  const handleDelete = () => {
    setDisabled(true)
    const payload = {
      feature_request_ids: selection.selectedIds,
      blacklist_ids: selection.ignoredIds,
      filters: { ...postStore.filters, ...postStore.globalFilters },
      select_all: selection.selectedAll,
    }
    bulkDelete(payload)
      .then(() => onDelete({ bulkUpdateData: payload, type: 'delete' }))
      .then(() => {
        onSelectAll(false)
        toaster.success({ message: t('posts.messages.postDeleted') })
      })
      .catch((err) => {
        toaster.error({ message: err.message })
      })
      .finally(() => {
        setDisabled(false)
        setShowDeleteDialog(false)
      })
  }

  const handleClose = () => {
    setActions({})
    onSelectAll(false)
  }

  const getBulkUpdateDescription = () => {
    const selectedIdsCount = selection?.selectedIds?.length
    const ignoredIdsCount = selection?.ignoredIds?.length
    const counts =
      selectedIdsCount ||
      (ignoredIdsCount ? +totalCount - ignoredIdsCount : totalCount)
    const selectedStatus = custom_states?.find(
      (status: ICustomState) => status.slug === actions?.state?.[0]
    )
    const selectedAssignee = assignee?.find(
      (user: any) => user.id === actions?.assignee_id?.[0]
    )
    const selectedTags = tags?.find(
      (tag: ITagData) => tag.id === actions?.tag_id?.[0]
    )
    const selectedBucket = buckets?.find(
      (bucket: IBoardDetails) => bucket.id === actions?.bucket_id?.[0]
    )

    const description = t(`${getBulkPostUpdateTranslationKey(actions)}`, {
      data: {
        count: counts,
        status: `'${selectedStatus?.name}'`,
        assignee: `'${selectedAssignee?.name} (${selectedAssignee?.email})'`,
        bucket: `'${selectedBucket?.display_name}'`,
        tag: `'${selectedTags?.name}'`,
        updatedKeys: getBulkPostUpdateContent(actions, t('posts.savedFilters')),
        updatePluralize: pluralize(
          counts,
          t('common.labels.update').toLowerCase(),
          t('common.labels.updates').toLowerCase()
        ),
        postPluralize: pluralize(
          counts,
          t('common.labels.post').toLowerCase(),
          t('common.labels.posts').toLowerCase()
        ),
      },
    })
    return description
  }

  const getBulkDeleteDescription = () => {
    const selectedIdsCount = selection?.selectedIds?.length
    const ignoredIdsCount = selection?.ignoredIds?.length
    const counts =
      selectedIdsCount ||
      (ignoredIdsCount ? +totalCount - ignoredIdsCount : totalCount)
    return t('posts.bulkSelect.deleteAlertDescription', {
      data: {
        postCount: counts,
        postPluralize: pluralize(
          counts,
          t('common.labels.post').toLowerCase(),
          t('common.labels.posts').toLowerCase()
        ),
      },
    })
  }

  const handleCannedResponse = (
    selected: ISelectOptionGroup,
    previous?: ISelectOptionGroup
  ) => {
    const selectedValue = (selected as ISelectOption)?.content || ''
    const previousValue = (previous as ISelectOption)?.content
    editorRef.current?.replaceOrInsertValue(selectedValue, previousValue)
    setSelectedCannedResponse(selected as ISelectOption)
  }

  const renderStatusChangeNote = () => {
    if (
      actions?.state?.length &&
      postStore.globalFilters.approval_status === 'pending'
    ) {
      return (
        <div className='mb-4 space-x-1 text-xs text-gray11'>
          <span className='font-semibold'>{t('common.labels.note')}</span>
          <span>
            {t('posts.bulkSelect.statusChangeNote', {
              data: {
                postPluralize: pluralize(
                  selection?.selectedIds?.length,
                  t('common.labels.post').toLowerCase(),
                  t('common.labels.posts').toLowerCase()
                ),
              },
            })}
          </span>
        </div>
      )
    }
    return <></>
  }

  const renderDescriptionComponent = () => {
    if (actions.state && actions.state.length) {
      return (
        <div className='flex flex-col gap-2'>
          <p>{t('post.statusChangeNoteAlertTexts.description')}</p>
          <RichTextEditor
            showBubbleMenu={false}
            showFloatingMenu={false}
            placeholder={t('post.statusChangeNoteAlertTexts.editorPlaceholder')}
            onChange={(content) => {
              statusChangeHTML.current = content
            }}
            ref={editorRef}
          />
          <div className='z-10'>
            {userProfile?.is_csm_of_organization &&
              organizationPlan?.allow_basic_features && (
                <CannedResponseDropdown
                  onSelect={handleCannedResponse}
                  selectedResponse={selectedCannedResponse as ISelectOption}
                />
              )}
          </div>
        </div>
      )
    }
    return null
  }

  const renderAIRegenerateButton = () => {
    if (!isFeatureEnabled) return null
    return (
      <AlertDialog
        title={t('posts.aiAnalysis.bulkReprocess.alertTexts.title')}
        description={t(
          'posts.aiAnalysis.bulkReprocess.alertTexts.description',
          {
            data: { credits: totalSelectionCount },
          }
        )}
        onConfirm={() => handleSingleAction('sentiment_regenerate', true)}
      >
        <div>
          <Tooltip text={t('common.labels.processUsingAI')} asChild>
            <Button fab variant='outline' as='span'>
              <Sparkle
                weight='bold'
                className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
              />
            </Button>
          </Tooltip>
        </div>
      </AlertDialog>
    )
  }

  const renderHideAndUnhideButton = () => {
    if (
      postStore.globalFilters?.show_hidden &&
      !isModeration &&
      !postStore.globalFilters?.bucket_id
    ) {
      return (
        <Tooltip text={t('common.labels.unHide')}>
          <Button
            variant='outline'
            fab
            onClick={() => handleSingleAction('hidden', false)}
          >
            <Eye
              weight='bold'
              className='h-4 w-4 text-gray11 group-hover:text-gray12'
            />
          </Button>
        </Tooltip>
      )
    }
    return (
      <Tooltip text={t('common.labels.hide')}>
        <Button
          variant='outline'
          className={'group !text-gray12/80 hover:text-gray12'}
          fab
          onClick={() => handleSingleAction('hidden', true)}
        >
          <EyeSlash
            weight='bold'
            className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
          />
        </Button>
      </Tooltip>
    )
  }

  if (!selection.selectedAll && selection.selectedIds.length === 0) {
    return <></>
  }

  return (
    <div
      data-testid='bulk_select'
      className='pointer-events-none absolute bottom-10 z-[3] mx-auto hidden w-full md:block'
    >
      {/* <div className='pointer-events-auto relative mx-auto flex max-w-5xl items-center space-x-2 rounded-lg border border-gray5 bg-gray3 px-4 py-3 drop-shadow-2xl transition  '> */}
      <div className='pointer-events-auto relative mx-auto flex max-w-5xl items-center justify-center gap-1 rounded-[5px] bg-snow py-1 shadow-[rgba(15,15,15,0.1)_0px_0px_0px_1px,rgba(15,15,15,0.1)_0px_2px_4px]'>
        <div className='inline-flex shrink-0 items-center px-2'>
          {!selection.selectedAll && (
            <div className='flex items-center gap-2'>
              <span>
                <Typography.Text className='!text-xs !text-primary'>
                  {selection.selectedIds.length}{' '}
                  {t('common.labels.bulkSelect.selected')}
                </Typography.Text>
              </span>
              <div className='h-5 w-0.5 bg-gray5' />
              {showSelectAll && (
                <Button
                  size='xs'
                  className={'!px-0'}
                  variant='naked'
                  onClick={() => onSelectAll(true)}
                >
                  {`${t('common.labels.bulkSelect.selectAll')} ${totalCount}`}
                </Button>
              )}
            </div>
          )}
          {selection.selectedAll && (
            <div className='flex items-center gap-2'>
              <span className='border-r border-gray6'>
                <Typography.Text>
                  {+(totalCount - selection.ignoredIds.length)}{' '}
                  {t('common.labels.bulkSelect.selected')}
                </Typography.Text>
              </span>
              <Button
                size='xs'
                variant='naked'
                className={'!px-0'}
                onClick={() => onSelectAll(false)}
              >
                {t('common.labels.bulkSelect.unselectAll')}
              </Button>
            </div>
          )}
        </div>
        <div className='grid grid-cols-4 gap-1 sm:gap-1'>
          <AdminPostBoardFilter
            onChange={handleChange}
            filters={{}}
            placeholder={t('posts.bulkSelect.updateBucketPlaceholder')}
            multiple={false}
            behaviour='filter'
            clearable
            selectProps={{
              rounded: false,
            }}
          />
          <AdminPostAssigneeFilter
            onChange={handleChange}
            filters={{}}
            placeholder={t('posts.bulkSelect.updateAssigneePlaceholder')}
            multiple={false}
            behaviour='filter'
            clearable
            selectProps={{
              rounded: false,
            }}
          />
          <AdminPostStatusFilter
            onChange={handleChange}
            filters={{}}
            placeholder={t('posts.bulkSelect.updateStatusPlaceholder')}
            multiple={false}
            behaviour='filter'
            clearable
            selectProps={{
              rounded: false,
            }}
          />
          <AdminPostTagsFilter
            onChange={handleChange}
            filters={{}}
            placeholder={t('posts.bulkSelect.updateTagsPlaceholder')}
            multiple={false}
            behaviour='filter'
            clearable
            selectProps={{
              rounded: false,
            }}
          />
        </div>
        {!isModeration && (
          <div>
            <Dropdown>
              <DropdownTrigger asChild>
                <div>
                  <Tooltip text={t('common.labels.more')} asChild>
                    <Button
                      fab
                      variant='outline'
                      className={'group !text-gray12/80 hover:text-gray12'}
                    >
                      <DotsThreeOutlineVertical
                        weight='fill'
                        className='h-3.5 w-3.5 text-gray11 group-hover:text-gray12'
                      />
                    </Button>
                  </Tooltip>
                </div>
              </DropdownTrigger>
              <DropdownContent>
                <DropdownItem
                  onSelect={() => handleSingleAction('viewed', true)}
                  itemValue={t('buttons.read')}
                  icon={<EnvelopeOpen />}
                />
                <DropdownItem
                  onSelect={() => handleSingleAction('viewed', false)}
                  itemValue={t('buttons.unread')}
                  icon={<Envelope />}
                />
                <DropdownItem
                  onSelect={() => handleSingleAction('pinned', true)}
                  itemValue={t('buttons.pin')}
                  icon={<PushPin />}
                />
                <DropdownItem
                  onSelect={() => handleSingleAction('pinned', false)}
                  itemValue={t('buttons.unpin')}
                  icon={<PushPinSlash />}
                />
              </DropdownContent>
            </Dropdown>
          </div>
        )}
        {renderHideAndUnhideButton()}
        <Tooltip text={t('post.mergePost.mergeButtonText')} asChild>
          <Button
            icon={<GitMerge weight='fill' className='h-3.5 w-3.5' />}
            disabled={totalSelectionCount <= 1}
            fab
            isResponsive
            as='span'
            variant='outline'
            onClick={() => {
              bulkMergeDialogRef.current?.openWithPosts(
                postStore.posts.filter((post) =>
                  selection.selectedIds.includes(post.id)
                )
              )
            }}
          />
        </Tooltip>
        <BulkMergeDialog ref={bulkMergeDialogRef} onComplete={handleClose} />
        {renderAIRegenerateButton()}
        {isModeration && moderationAction && (
          <AdminPostModerationMoveToActionButton
            approvalStatus={
              moderationAction === 'approve' ? 'approved' : 'pending'
            }
            onSelectAll={() => onSelectAll(false)}
            onActionComplete={(data) => onComplete(data)}
          />
        )}
        <span className='mx-1 h-7 w-[1px] bg-gray5' />
        <Tooltip text={t('buttons.save')} asChild>
          <Button
            disabled={!isActionSelected()}
            fab
            onClick={() => setShowDialog(true)}
            icon={<FloppyDiskBack weight='fill' className='h-3.5 w-3.5' />}
            isResponsive
          >
            {t('buttons.save', { ignorePrefix: true })}
          </Button>
        </Tooltip>
        <AlertDialog
          type='danger'
          open={showDeleteDialog}
          onClose={() => setShowDeleteDialog(false)}
          confirmText={t('buttons.delete')}
          title={t('posts.bulkSelect.alertTitle')}
          description={getBulkDeleteDescription()}
          onConfirm={handleDelete}
        >
          <Tooltip text={t('buttons.delete')} asChild>
            <Button
              icon={<Trash weight='bold' className='h-3.5 w-3.5' />}
              disabled={disabled}
              fab
              variant='danger'
              isResponsive
              className={'mx-1.5'}
              onClick={() => setShowDeleteDialog(true)}
            />
          </Tooltip>
        </AlertDialog>

        <div className='mr-auto flex items-center justify-end'>
          <Button
            variant='naked'
            size='xs'
            disabled={disabled}
            onClick={() => handleClose()}
          >
            <X weight='bold' className='h-3.5 w-3.5' />
          </Button>
        </div>
      </div>
      <Dialog open={showDialog} onOpenChange={setShowDialog}>
        <DialogContent backdrop>
          <DialogTitle>{t('posts.bulkSelect.alertTitle')}</DialogTitle>
          <DialogDescription>
            <span>{getBulkUpdateDescription()}</span>
            {renderStatusChangeNote()}
          </DialogDescription>
          <DialogClose />
          {renderDescriptionComponent()}
          <div className='flex items-center justify-between  pt-4'>
            <Checkbox
              label={t('common.doNotNotify', { ignorePrefix: true })}
              checked={doNotNotify}
              onCheckedChange={(checked) => setDoNotNotify(checked)}
            />
            <div className='flex items-center gap-2'>
              <Button
                onClick={() => {
                  setShowDialog(false)
                  statusChangeHTML.current = ''
                }}
                size='xs'
                variant='outline'
              >
                {t('buttons.cancel')}
              </Button>
              <Button onClick={() => handleBulkAction(doNotNotify)} size='xs'>
                {t('buttons.confirm')}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
