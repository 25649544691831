import {
  Bell,
  BookOpen,
  ChatCentered,
  GearSix,
  MagnifyingGlass,
  MegaphoneSimple,
  Signpost,
  Stack,
  User,
  UsersThree,
} from '@phosphor-icons/react'
import Link from 'next/link'

import AdminNotificationsPopover from '@/components/admin/shared/notifications/AdminNotificationsPopover'
import UserProfileDropdown from '@/components/users/shared/UserProfileDropdown'
import {
  boardNavigation,
  boardNavigationGroups,
} from '@/config/sidebar/adminBoardSidebarNavigations'
import {
  changelogNavigation,
  changelogNavigationGroups,
} from '@/config/sidebar/adminChangelogSidebarNavigations'
import {
  knowledgeBaseNavigation,
  knowledgeBaseNavigationGroups,
} from '@/config/sidebar/adminKBSidebarNavigations'
import {
  peopleNavigation,
  peopleNavigationGroups,
} from '@/config/sidebar/adminPeopleNavigations'
import {
  roadmapNavigation,
  roadmapNavigationGroups,
} from '@/config/sidebar/adminRoadmapSidebarNavigations'
import {
  settingsNavigation,
  settingsNavigationGroups,
} from '@/config/sidebar/adminSettingsSidebarNavigations'
import { kbURL } from '@/lib/helpers/modules/articleHelpers'
import type { IOrganizationData } from '@/types/organization'

export const allApps = [
  {
    icon: <MagnifyingGlass size={18} />,
    isActive: false,
    key: 'explore',
    href: '/admin/global-search',
    name: 'GlobalSearch',
    labelKey: 'sidebar.items.globalSearch',
    items: [],
    groups: [],
    availableRoles: ['admin', 'member', 'csm'],
    wrapper: Link,
  },
  {
    icon: <Stack size={18} />,
    isActive: false,
    key: 'boards',
    href: '/admin',
    name: 'Boards',
    labelKey: 'sidebar.items.allBoards',
    useSideLink: '/b',
    items: boardNavigation,
    groups: boardNavigationGroups,
    availableRoles: ['admin', 'member', 'csm'],
    wrapper: Link,
  },

  {
    icon: <Signpost size={18} />,
    isActive: false,
    key: 'roadmap',
    href: '/admin/r',
    name: 'Roadmap',
    useSideLink: '/roadmap',
    labelKey: 'sidebar.items.roadmap',
    items: roadmapNavigation,
    groups: roadmapNavigationGroups,
    availableRoles: ['admin', 'member'],
    wrapper: Link,
  },
  {
    icon: <MegaphoneSimple size={18} />,
    isActive: false,
    key: 'changelog',
    href: '/admin/changelog',
    anotherHrefs: ['/admin/c/[changelogSlug]'],
    name: 'Changelog',
    useSideLink: '/changelog',
    labelKey: 'sidebar.items.changelog',
    items: changelogNavigation,
    groups: changelogNavigationGroups,
    availableRoles: ['admin', 'member'],
    wrapper: Link,
  },
  {
    icon: <BookOpen size={18} />,
    isActive: true,
    key: 'Knowledge-base',
    href: '/admin/kb',
    name: 'Knowledge Base',
    useSideLink: ({ org }: { org: IOrganizationData }) => kbURL(org),
    labelKey: 'sidebar.items.kb',
    items: knowledgeBaseNavigation,
    groups: knowledgeBaseNavigationGroups,
    availableRoles: ['admin', 'member'],
    wrapper: Link,
  },

  {
    icon: <UsersThree size={18} />,
    isActive: true,
    key: 'people',
    href: '/admin/people',
    name: 'People',
    labelKey: 'sidebar.items.people',
    items: peopleNavigation,
    groups: peopleNavigationGroups,
    availableRoles: ['admin'],
    wrapper: Link,
  },
  {
    icon: <ChatCentered size={18} />,
    isActive: false,
    key: 'chat',
    href: '/admin/ai-chat',
    name: 'AI Chat',
    labelKey: 'sidebar.items.chat',
    items: [],
    groups: [],
    availableRoles: ['admin', 'member', 'csm'],
    wrapper: Link,
  },
  {
    icon: <GearSix size={18} />,
    isActive: false,
    key: 'settings',
    href: '/admin/settings',
    name: 'Organization Settings',
    labelKey: 'sidebar.items.settings',
    items: settingsNavigation,
    groups: settingsNavigationGroups,
    position: 'bottom',
    wrapper: Link,
    availableRoles: ['admin'],
  },
  {
    icon: <Bell size={18} />,
    isActive: false,
    key: 'notifications',
    href: '/admin/notifications',
    name: 'Notifications',
    position: 'bottom',
    labelKey: 'sidebar.items.notifications',
    availableRoles: ['admin', 'member', 'csm'],
    wrapper: Link,
    noToolTip: true,
    appRenderer: () => (
      <div className='flex'>
        <AdminNotificationsPopover />
      </div>
    ),
  },
  {
    icon: <User size={18} />,
    isActive: false,
    key: 'profile',
    href: '/profile',
    name: 'Profile',
    position: 'bottom',
    labelKey: 'sidebar.items.profile',
    availableRoles: ['admin', 'member', 'csm'],
    wrapper: Link,
    appRenderer: () => (
      <div className='flex w-full justify-center py-2'>
        <UserProfileDropdown
          size='xs'
          side='right'
          sideOffset={12}
          className='flex h-[44px] w-[44px] cursor-pointer items-center justify-center gap-2 border-l-2 border-transparent text-gray12 hover:border-primary hover:bg-gray5'
        />
      </div>
    ),
  },
]
