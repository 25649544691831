import { ArrowRight } from '@phosphor-icons/react'
import { useEffect, useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Dialog from '@/components/shared/ui/Dialog'
import {
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@/components/shared/ui/Dialog/Dialog'
import EmptyState from '@/components/shared/ui/EmptyState'
import Spinner from '@/components/shared/ui/Loader'
import Typography from '@/components/shared/ui/Typography'
import { useTranslations } from '@/hooks/useTranslations'
import { ENTITIES, EVENT_ACTION_TYPES, EventEmitter } from '@/lib/eventEmitter'
import { pluralize } from '@/lib/helpers/appHelpers'
import postStore from '@/stores/PostListStore'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

import UserPostItem from '../../UserPostItem'

interface IPropTypes {
  post: IPost
}

export default function SinglePostUnmergeModal({ post }: IPropTypes) {
  const t = useTranslations()
  const [show, toggleShow] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchMergedPosts = () => {
    setIsLoading(true)
    return postStore
      .mergedPosts(post)
      .catch(toaster.error)
      .finally(() => setIsLoading(false))
  }

  const handleUpdateMergePosts = (unmergePost: IPost, updatedPost: IPost) => {
    postStore.updateSinglePost(post.slug, {
      ...post,
      ...updatedPost,
    })
    // Append unmerged post in post listing
    postStore.updateSinglePost(unmergePost.slug, {
      ...unmergePost,
    })
  }

  const handleUnmerge = (unmergePost: IPost) => {
    return postStore
      .unmerge(post.slug, unmergePost.id)
      .then((response) => {
        handleUpdateMergePosts(unmergePost, response)
        EventEmitter.dispatch('ENTITY_UPDATE', {
          actionType: EVENT_ACTION_TYPES.UPDATE,
          entity: ENTITIES.POSTS,
          data: {
            data: post.merged_posts.filter((p) => p.id !== unmergePost.id),
          },
        })
        return toaster.success({
          message: t('post.unmergePost.messages.success'),
        })
      })
      .catch((err) => toaster.error({ message: err.message }))
  }

  useEffect(() => {
    if (show && post.merged_posts_count > 0) fetchMergedPosts()
  }, [post.merged_posts_count, show])

  if (post.merged_posts_count === 0) return <></>

  const renderMergedPost = (mergedPost: IPost) => (
    <div className='flex flex-col divide-y divide-gray5 rounded-md border border-gray5 '>
      <UserPostItem post={mergedPost} openInNewTab />
      <div className='flex items-center justify-between p-2'>
        <p className='mb-0 text-xs font-medium text-gray10 '>
          {t('post.unmergePost.mergedByText')} {mergedPost.merged_by}{' '}
          {mergedPost.merged_at}
        </p>
        {post.can_unmerge && (
          <AlertDialog
            confirmText={t('post.unmergePost.alertTexts.confirmButtonText')}
            title={t('post.unmergePost.alertTexts.title')}
            description={t('post.unmergePost.alertTexts.description')}
            onConfirm={() => handleUnmerge(mergedPost)}
          >
            <Button size='xs' variant='text'>
              {t('post.unmergePost.alertTexts.confirmButtonText')}
            </Button>
          </AlertDialog>
        )}
      </div>
    </div>
  )

  const renderContent = () => {
    if (post.merged_posts && post.merged_posts.length) {
      return (
        <div data-testid='unmerge-modal-body'>
          <ul className='mt-3 space-y-4'>
            {post?.merged_posts?.map(renderMergedPost)}
          </ul>
        </div>
      )
    }
    return <EmptyState />
  }

  return (
    <div className='grid grid-cols-5 items-center gap-4 px-4 empty:hidden'>
      <Typography.Text className='col-span-2 font-medium'>
        {t('post.labels.mergedPosts')}
      </Typography.Text>
      <span className='col-span-3'>
        <Dialog
          open={show}
          onOpen={() => toggleShow(true)}
          onClose={() => toggleShow(false)}
        >
          <DialogTrigger>
            <Typography.Text className='flex w-full items-center justify-between gap-2 font-medium !text-gray12 dark:!text-gray-light'>
              {/* @ts-ignore */}
              {`${post?.merged_posts_count} ${pluralize(
                post?.merged_posts_count,
                t('post.unmergePost.titleSingular'),
                t('post.unmergePost.titlePlural')
              )}`}
              <ArrowRight />
            </Typography.Text>
          </DialogTrigger>
          <DialogContent size='lg'>
            <DialogTitle>
              {pluralize(
                post?.merged_posts_count,
                t('post.unmergePost.titleSingular'),
                t('post.unmergePost.titlePlural')
              )}
            </DialogTitle>
            {isLoading && !post.merged_posts?.length ? (
              <Spinner />
            ) : (
              renderContent()
            )}
          </DialogContent>
        </Dialog>
      </span>
    </div>
  )
}
