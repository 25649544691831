import { CaretDown } from '@phosphor-icons/react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import clsx from 'clsx'
import React from 'react'

import clsxm from '@/lib/clsxm'

import AccordionStyles from './Accordion.module.scss'

interface IAccordionTriggerProp
  extends AccordionPrimitive.AccordionTriggerProps {
  children?: React.ReactNode
  className?: string
}
interface IAccordionItemProp extends AccordionPrimitive.AccordionItemProps {
  children?: React.ReactNode
  value: string
  disabled?: boolean
}
interface IAccordionContentProp {
  children?: React.ReactNode
  value?: string
  className?: string
}

export const AccordionItem = ({ children, ...props }: IAccordionItemProp) => (
  <AccordionPrimitive.Item
    className={clsx({ '!cursor-not-allowed opacity-50': props.disabled })}
    {...props}
  >
    {children}
  </AccordionPrimitive.Item>
)

export const AccordionTrigger = ({
  children,
  className,
  ...props
}: IAccordionTriggerProp) => (
  <AccordionPrimitive.Header>
    <AccordionPrimitive.Trigger
      className={clsxm(AccordionStyles['hnui-accordion-trigger'], className)}
      {...props}
    >
      <>{children}</>
      <CaretDown className={AccordionStyles['hnui-accordion-icon']} />
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
)

export const AccordionContent = ({
  children,
  className,
  ...props
}: IAccordionContentProp) => (
  <AccordionPrimitive.Content
    className={clsxm(AccordionStyles['hnui-accordion-content'], className)}
    {...props}
  >
    {children}
  </AccordionPrimitive.Content>
)

export const Accordion = (
  props:
    | AccordionPrimitive.AccordionSingleProps
    | AccordionPrimitive.AccordionMultipleProps
) => <AccordionPrimitive.Root {...props} />

Accordion.displayName = 'Accordion'

export default Accordion
