import * as TabsPrimitive from '@radix-ui/react-tabs'
import type { FC } from 'react'
import React from 'react'

import clsxm from '@/lib/clsxm'

import TabsStyle from './Tabs.module.scss'

interface TabsPropstype extends TabsPrimitive.TabsProps {
  children?: React.ReactNode
  defaultValue?: string
  activationMode?: 'automatic' | 'manual'
  orientation?: 'horizontal' | 'vertical'
}

interface Tabsprops {
  value: string
  children?: React.ReactNode
  icon?: React.ReactNode
  className?: string
}

export const TabsContent = ({ value, ...props }: Tabsprops) => (
  <TabsPrimitive.Content
    {...props}
    value={value}
    className='mt-2 w-full pt-2 outline-none focus-visible:outline-none'
  />
)

export const TabsTrigger = ({ value, icon, children, ...props }: Tabsprops) => (
  <TabsPrimitive.Trigger
    {...props}
    value={value}
    className={clsxm(TabsStyle['hnui-tabs-trigger'], props.className)}
  >
    {icon}
    <span>{children}</span>
  </TabsPrimitive.Trigger>
)

export const TabsList = ({ ...props }: TabsPropstype) => (
  <TabsPrimitive.List
    {...props}
    className={clsxm(TabsStyle['hnui-tabs-list'], props.className)}
  />
)

export const Tabs: FC<TabsPropstype> = ({
  defaultValue,
  activationMode = 'automatic',
  orientation,
  className,
  ...props
}) => (
  <TabsPrimitive.Root
    {...props}
    defaultValue={defaultValue}
    activationMode={activationMode}
    orientation={orientation}
    className={clsxm('m-1', className)}
  />
)

Tabs.displayName = 'Tabs'
export default Tabs
