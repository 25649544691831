import {
  CalendarBlank,
  CheckCircle,
  Info,
  WarningCircle,
  X,
  XCircle,
} from '@phosphor-icons/react'
import clsx from 'clsx'

import ErrorString from '@/components/shared/common/ErrorString'

import Button from '../Button'
import AlertStyle from './Alert.module.scss'

interface IAlertPropsType {
  type: 'success' | 'error' | 'warning' | 'info' | 'calender'
  message: string | React.ReactNode
  children?: React.ReactNode
  description?: string
  rounded?: boolean
  className?: string
  actionText?: string
  onClose?: () => void
  actionLink?: string
  target?: string
  rightComponent?: React.ReactNode
  icon?: React.ReactNode
}

export default function Alert({
  type = 'info',
  message,
  rounded = true,
  description,
  actionText,
  actionLink,
  children,
  target = '_self',
  rightComponent,
  className = '',
  onClose,
  icon,
}: IAlertPropsType) {
  const renderIcon = () => {
    if (icon) return icon
    if (type === 'success') {
      return (
        <CheckCircle
          weight='bold'
          className={clsx(
            AlertStyle['hnui-alert-icon-size'],
            AlertStyle['hnui-alert-icon-success']
          )}
        />
      )
    }
    if (type === 'error') {
      return (
        <XCircle
          weight='bold'
          className={clsx(
            AlertStyle['hnui-alert-icon-size'],
            AlertStyle['hnui-alert-icon-error']
          )}
        />
      )
    }
    if (type === 'warning') {
      return (
        <WarningCircle
          weight='bold'
          className={clsx(
            AlertStyle['hnui-alert-icon-size'],
            AlertStyle['hnui-alert-icon-warning']
          )}
        />
      )
    }
    if (type === 'calender') {
      return (
        <CalendarBlank
          weight='bold'
          className={clsx(
            AlertStyle['hnui-alert-icon-size'],
            AlertStyle['hnui-alert-icon-info']
          )}
        />
      )
    }
    return (
      <Info
        weight='bold'
        className={clsx(
          AlertStyle['hnui-alert-icon-size'],
          AlertStyle['hnui-alert-icon-info']
        )}
      />
    )
  }

  const renderAction = () => {
    if (onClose)
      return (
        <Button fab size='sm' variant='text' onClick={onClose}>
          <X
            weight='bold'
            className={clsx(
              AlertStyle['hnui-alert-action'],
              AlertStyle[`hnui-alert-message-${type}`]
            )}
          />
        </Button>
      )
    if (actionText)
      return (
        <span className='text-sm md:ml-6 md:mt-0'>
          <a
            href={actionLink || '#'}
            className={clsx(
              AlertStyle['hnui-alert-action'],
              AlertStyle[`hnui-alert-message-${type}`]
            )}
            target={target}
          >
            {actionText} <span aria-hidden='true'>→</span>
          </a>
        </span>
      )
    if (rightComponent) return <>{rightComponent}</>
    return false
  }

  return (
    <div className={clsx(className)}>
      <div
        className={clsx(
          rounded ? 'rounded-md ' : '',
          AlertStyle['hnui-alert'],
          AlertStyle[`hnui-alert-${type}`]
        )}
      >
        <div
          className={clsx(
            'flex flex-1',
            rightComponent ? 'items-center' : 'items-start'
          )}
        >
          <div className='mt-0.5 shrink-0'>{renderIcon()}</div>
          <div className='ml-3 grow space-y-2'>
            <div className='items-center space-y-2 md:flex md:space-y-0'>
              {message &&
                (typeof message === 'string' ? (
                  <h3
                    className={clsx(
                      AlertStyle['hnui-alert-message'],
                      AlertStyle[`hnui-alert-message-${type}`],
                      '!font-semibold'
                    )}
                  >
                    <ErrorString message={message} />
                  </h3>
                ) : (
                  message
                ))}
              {renderAction()}
            </div>
            {description && (
              <div
                className={clsx(
                  AlertStyle['hnui-alert-description'],
                  AlertStyle[`hnui-alert-description-${type}`]
                )}
              >
                <p>{description}</p>
              </div>
            )}
            {children && children}
          </div>
        </div>
      </div>
    </div>
  )
}
