import { Circle, X } from '@phosphor-icons/react'
import clsx from 'clsx'
import type { FC } from 'react'
import React, { useState } from 'react'

import AlertDialog from '@/components/shared/ui/AlertDialog'
import Image from '@/components/shared/ui/Image/NextImage'
import { STATUS_COLORS } from '@/config/module/colorConstant'
import { useTranslations } from '@/hooks/useTranslations'
import { changeSpecialCharactersToNormal } from '@/lib/helpers/stringHelpers'

import Spinner from '../Loader'
import type { IVariantTypes } from '../types/element'
import BadgeStyles from './Badge.module.scss'

interface IAlertTexts {
  title: string
  description: string
  confirmButtonText?: string
  cancelButtonText?: string
}

interface BadgePropType {
  className?: string
  variant?: IVariantTypes
  value?: string | number | React.ReactNode
  size?: 'xxs' | 'xs' | 'md' | 'sm'
  close?: boolean
  rounded?: boolean
  showDot?: boolean
  dotColor?: IVariantTypes
  icon?: React.ReactNode
  suffixIcon?: React.ReactNode
  onClick?: (args: any) => any
  isLoading?: boolean
  data?: any
  onClose?: (args: any) => void
  imageLink?: string
  showAlertBox?: boolean
  alertTexts?: IAlertTexts
  customStyle?: React.CSSProperties
  testId?: string
  containerClassNames?: string
}

export const Badge: FC<BadgePropType> = ({
  variant = 'gray',
  value,
  size = 'xs',
  icon,
  close,
  showDot = false,
  rounded,
  onClick,
  isLoading,
  suffixIcon,
  data,
  onClose,
  className,
  dotColor = 'blue',
  imageLink,
  showAlertBox = false,
  alertTexts,
  customStyle = {},
  testId,
  containerClassNames = '',
}) => {
  const t = useTranslations('buttons')
  const BadgeClasses = [BadgeStyles['hnui-badge-container']]
  const [openAlertDialog, setOpenAlertDialog] = useState(false)

  const handleClick = () => {
    if (onClick && typeof onClick === 'function') onClick(data)
  }

  const handleClose = () => {
    if (showAlertBox && close && onClose && typeof onClose === 'function')
      return setOpenAlertDialog(true)
    if (close && onClose && typeof onClose === 'function') return onClose(data)
    return false
  }

  const handleOnConfirm = () => {
    if (onClose) return onClose(data)
    return null
  }

  const getValue = () => {
    if (value && typeof value === 'string') {
      return changeSpecialCharactersToNormal(value)
    }
    return value
  }

  const getTruncateClass = () => {
    if (value && typeof value === 'string' && value.length > 10) {
      return 'truncate'
    }
    return ''
  }

  if (variant)
    BadgeClasses.push(
      BadgeStyles[`hnui-badge-variant-${variant}`],
      BadgeStyles[`hnui-badge-color-${variant}`]
    )
  if (size) BadgeClasses.push(BadgeStyles[`hnui-badge-${size}`])
  if (className) BadgeClasses.push(className)
  if (rounded) BadgeClasses.push(BadgeStyles[`hnui-badge-rounded`])
  if (onClick && !close)
    BadgeClasses.push(BadgeStyles[`hnui-badge-cursor-pointer`])
  if (isLoading) return <Spinner />
  return (
    <span
      style={customStyle}
      className={clsx(containerClassNames, BadgeClasses)}
    >
      <span
        onClick={handleClick}
        data-testid={testId}
        className={clsx('flex items-center overflow-auto', getTruncateClass(), {
          'cursor-pointer': !!onClick,
        })}
      >
        {!close && <span className='absolute inset-0' />}
        {showDot && !icon && (
          <Circle
            weight='fill'
            color={STATUS_COLORS?.find((c) => c.value === dotColor)?.hex}
            className={clsx(
              BadgeStyles[`hnui-badge-color-${dotColor}`],
              'shrink-0'
            )}
          />
        )}
        {icon}
        {imageLink && (
          <Image
            src={imageLink}
            className='h-3 w-3'
            alt={value as string}
            height={8}
            width={8}
          />
        )}
        {value !== undefined && value !== null && (
          <span className={clsx(BadgeStyles['hnui-badge-value'], 'pr-4')}>
            {getValue()}
          </span>
        )}
        {suffixIcon}
      </span>
      {close && (
        <X
          data-testid={`${value}_badge_close_button`}
          className={BadgeStyles['hnui-badge-close']}
          onClick={handleClose}
        />
      )}
      <AlertDialog
        type='danger'
        open={openAlertDialog}
        title={alertTexts?.title}
        description={alertTexts?.description}
        confirmText={alertTexts?.confirmButtonText || t('confirm')}
        cancelText={alertTexts?.cancelButtonText || t('cancel')}
        onConfirm={() => handleOnConfirm()}
        onCancel={() => setOpenAlertDialog(false)}
      />
    </span>
  )
}
