import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import clsxm from '@/lib/clsxm'

import UnstyledLink from '../Links/UnstyledLink'
import DropdownStyle from './Dropdown.module.scss'

interface DropdownPropType
  extends DropdownMenuPrimitive.DropdownMenuContentProps {
  children?: React.ReactNode
  arrow?: boolean
  align?: 'start' | 'center' | 'end'
  asChild?: boolean
  className?: string
  open?: boolean
  onOpenChange?: (status: boolean) => void
  disabled?: boolean
  dataTestId?: string
  modal?: boolean
}

export const DropdownContent = ({
  children,
  arrow,
  align = 'center',
  dataTestId = 'dropdown_content',
  className = '',
  ...props
}: DropdownPropType) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      data-testid={dataTestId}
      className={clsxm(
        DropdownStyle['hnui-dropdown-content'],
        'animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className
      )}
      sideOffset={10}
      align={align}
      {...props}
    >
      {children}
      {arrow && (
        <DropdownMenuPrimitive.Arrow
          className={DropdownStyle['hnui-dropdown-arrow']}
        />
      )}
    </DropdownMenuPrimitive.Content>
  </DropdownMenuPrimitive.Portal>
)

export const DropdownSubContent = ({ children }: DropdownPropType) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.SubContent
      sideOffset={2}
      alignOffset={5}
      className={clsx(DropdownStyle['hnui-dropdown-content'])}
    >
      {children}
      {/* <DropdownMenuPrimitive.Arrow /> */}
    </DropdownMenuPrimitive.SubContent>
  </DropdownMenuPrimitive.Portal>
)

export const DropdownGroup = (asChild: boolean) => (
  <DropdownMenuPrimitive.Group asChild={asChild} />
)

export const DropdownTrigger = ({
  children,
  asChild = false,
  className,
  disabled,
  ...props
}: DropdownMenuPrimitive.DropdownMenuTriggerProps) => {
  return (
    <DropdownMenuPrimitive.Trigger
      asChild={asChild}
      className={clsx(DropdownStyle['hnui-dropdown-trigger'], className)}
      disabled={disabled}
      {...props}
    >
      {children}
    </DropdownMenuPrimitive.Trigger>
  )
}

export const DropdownSub = ({ ...props }: DropdownPropType) => (
  <DropdownMenuPrimitive.Sub {...props} />
)

export const DropdownLabel = ({ ...props }: DropdownPropType) => (
  <DropdownMenuPrimitive.Label
    {...props}
    className={clsxm(DropdownStyle['hnui-dropdown-label'], props.className)}
  />
)

export const DropdownSeparator = ({ ...props }: DropdownPropType) => (
  <DropdownMenuPrimitive.Separator
    {...props}
    className={DropdownStyle['hnui-dropdown-separator']}
  />
)

interface DropdownItemPropType {
  children?: React.ReactNode
  disabled?: boolean
  icon?: React.ReactNode
  itemValue?: React.ReactNode
  color?: string
  href?: string
  RightSlot?: React.ReactNode
  onSelect?: (e: any) => any
  target?: '_blank' | '_self' | '_parent' | '_top'
  dataTestId?: string
  className?: string
  textClassName?: string
}

export const DropdownItem = ({
  children,
  disabled,
  icon,
  itemValue,
  color,
  href,
  RightSlot,
  onSelect,
  dataTestId = 'dropdown_item',
  className = '',
  textClassName,
  ...props
}: DropdownItemPropType) => (
  <DropdownMenuPrimitive.Item
    data-testid={dataTestId}
    className={clsx(
      DropdownStyle['hnui-dropdown-item'],
      color
        ? DropdownStyle['hnui-dropdown-item-text-red']
        : DropdownStyle['hnui-dropdown-item-text-default'],
      className,
      disabled && DropdownStyle['hnui-dropdown-item-disabled']
    )}
    disabled={disabled}
    onSelect={onSelect}
    {...props}
  >
    {!children && !href && (
      <div className={`${DropdownStyle['hnui-dropdown-list-item']} w-full`}>
        <div
          className={clsx(
            DropdownStyle['hnui-dropdown-item-text'],
            textClassName
          )}
        >
          {icon && (
            <span className='mr-2 flex h-4 w-4 shrink-0 items-center'>
              {icon}
            </span>
          )}
          <span className='truncate'>{itemValue}</span>
        </div>
        <span className='shrink-0'>{RightSlot}</span>
      </div>
    )}
    {!children && href && (
      <UnstyledLink
        href={href}
        target={props.target}
        className={clsx(DropdownStyle['hnui-dropdown-list-item'], 'w-full')}
        {...props}
        onClick={(_e) => {
          // Don't call onSelect when href is provided to avoid double actions
          // onSelect?.(e)
        }}
      >
        <>
          <div
            className={clsx(
              DropdownStyle['hnui-dropdown-item-text'],
              'w-full',
              textClassName
            )}
          >
            {icon && (
              <span className='mr-2 flex h-4 w-4 shrink-0 items-center'>
                {icon}
              </span>
            )}
            <span className='truncate'>{itemValue}</span>
          </div>
          {RightSlot}
        </>
      </UnstyledLink>
    )}
    {Boolean(children) && children}
  </DropdownMenuPrimitive.Item>
)

export const Dropdown: FC<DropdownPropType> = ({ ...props }) => (
  <DropdownMenuPrimitive.Root {...props} modal={props.modal} />
)

Dropdown.displayName = 'Dropdown'
export default Dropdown
