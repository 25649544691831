import {
  CheckCircle,
  DotsThree,
  EyeSlash,
  PencilSimpleLine,
  PushPin,
  Trash,
  XCircle,
} from '@phosphor-icons/react'
import React, { useContext, useMemo, useRef, useState } from 'react'

import type { IPushJiraInsightsActions } from '@/components/posts/singlePost/postActions/integrations/jira/PushJiraInsights'
import PushJiraInsights from '@/components/posts/singlePost/postActions/integrations/jira/PushJiraInsights'
import AlertDialog from '@/components/shared/ui/AlertDialog/AlertDialog'
import Button from '@/components/shared/ui/Button'
import Dropdown from '@/components/shared/ui/Dropdown'
import {
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import { JiraIcon } from '@/components/shared/ui/Icons'
import { useCommentAnswered } from '@/context/HNCommentContext'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { isUserMember } from '@/lib/helpers/modules/userHelper'
import { deleteComment, updateComment } from '@/models/Comment'
import postStore from '@/stores/PostListStore'
import type { IComment } from '@/types/comment'
import type { IPost } from '@/types/post'
import toaster from '@/utils/toast'

interface IPropTypes {
  post: IPost
  comment: IComment
  onEdit: () => void
  onUpdate: (comment: IComment) => void
  onDelete: (comment: IComment) => void
  onAnswerSuccess: (comment: IComment) => void
}

export default function CommentAction({
  post,
  comment,
  onEdit,
  onUpdate,
  onDelete,
  onAnswerSuccess,
}: IPropTypes) {
  const t = useTranslations('comments.commentItem.actions')
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [hideDialogOpen, setHideDialogOpen] = useState(false)

  const { userProfile } = useContext(HNContext)
  const jiraInsightDialog = useRef<IPushJiraInsightsActions>(null)

  const context = useCommentAnswered()

  const handleAnswer = () => {
    const newIsSolution = !comment.is_solution
    const newPinnedState = newIsSolution

    updateComment(
      post.slug,
      comment.id,
      { is_solution: newIsSolution, pinned: newPinnedState },
      isUserMember(userProfile)
    )
      .then((res) => {
        onUpdate?.(res)
        postStore.updateSinglePost(post.slug, {
          ...post,
          has_solution: newIsSolution,
        })
        if (newIsSolution) {
          onAnswerSuccess(res)
        }
      })
      .catch((err) => {
        toaster.error({ message: err.message })
      })
  }
  const handleHide = () => {
    updateComment(
      post.slug,
      comment.id,
      { hidden: !comment.hidden },
      isUserMember(userProfile)
    )
      .then(onUpdate)
      .then(() => setHideDialogOpen(false))
      .catch((err) => {
        toaster.error({ message: err.message })
      })
  }
  const handlePin = () =>
    updateComment(
      post.slug,
      comment.id,
      { pinned: !comment.pinned },
      isUserMember(userProfile)
    )
      .then(onUpdate)
      .catch((err) => {
        toaster.error({ message: err.message })
      })

  const hideLocale = useMemo(() => {
    if (!comment.hidden) return 'hide'
    if (comment.internal) return 'unhidePrivate'
    return 'unhide'
  }, [comment.hidden])

  const handleDelete = () => deleteComment(post.slug, comment.id).then(onDelete)

  const openDeleteConfirmation = () => setDeleteDialogOpen(true)

  if (!comment.can_edit && !comment.can_delete && !comment.can_pin) return <></>

  return (
    <>
      <Dropdown>
        <DropdownTrigger>
          <Button
            fab
            size='xs'
            variant='naked'
            dataTestId='comment_action_dialog'
          >
            <DotsThree className='h-4 w-4' weight='bold' />
          </Button>
        </DropdownTrigger>
        <DropdownContent dataTestId='dropdown_contents'>
          {comment.can_edit && (
            <DropdownItem
              onSelect={onEdit}
              itemValue={t('buttons.edit', { ignorePrefix: true })}
              icon={<PencilSimpleLine />}
            />
          )}
          {userProfile?.is_csm_of_organization && comment.is_solution && (
            <DropdownItem
              onSelect={handleAnswer}
              icon={<XCircle />}
              itemValue={t(`unmark_as_answer`)}
              dataTestId='unmark_as_answer'
            />
          )}
          {userProfile?.is_csm_of_organization && !context?.hasAnswer && (
            <DropdownItem
              onSelect={handleAnswer}
              icon={<CheckCircle />}
              itemValue={t('mark_as_answer')}
              dataTestId='mark_as_answer'
            />
          )}
          {comment.can_pin && (
            <DropdownItem
              onSelect={handlePin}
              icon={<PushPin />}
              itemValue={t(`buttons.${comment.pinned ? 'unpin' : 'pin'}`, {
                ignorePrefix: true,
              })}
            />
          )}
          {userProfile?.is_member_of_organization && !comment.internal && (
            <DropdownItem
              color='red'
              onSelect={() => {
                setHideDialogOpen(true)
              }}
              itemValue={t(comment.hidden ? 'buttons.unhide' : 'buttons.hide', {
                ignorePrefix: true,
              })}
              icon={<EyeSlash />}
            />
          )}
          {post?.jira_info?.push_insight_to_jira && (
            <DropdownItem
              color='red'
              onSelect={() => {
                jiraInsightDialog.current?.toggle()
              }}
              itemValue={'Create Insight in Jira'}
              icon={<JiraIcon className='h-3 w-3' />}
            />
          )}
          {comment.can_delete && (
            <DropdownItem
              color='red'
              onSelect={openDeleteConfirmation}
              textClassName='!text-red10'
              itemValue={t('buttons.delete', { ignorePrefix: true })}
              icon={<Trash />}
            />
          )}
        </DropdownContent>
      </Dropdown>
      <AlertDialog
        open={deleteDialogOpen}
        type='danger'
        title={t('delete.alert.title')}
        description={t('delete.alert.description')}
        onConfirm={handleDelete}
        onCancel={() => setDeleteDialogOpen(false)}
      />
      <AlertDialog
        open={hideDialogOpen}
        type='danger'
        title={t(`${hideLocale}.alert.title`)}
        description={t(`${hideLocale}.alert.description`)}
        onConfirm={handleHide}
        onCancel={() => setHideDialogOpen(false)}
      />

      {post?.jira_info?.push_insight_to_jira && (
        <PushJiraInsights post={post} ref={jiraInsightDialog} comment={comment}>
          <></>
        </PushJiraInsights>
      )}
    </>
  )
}
