import { CaretDown, X } from '@phosphor-icons/react'
import clsx from 'clsx'
import { format, isSameDay } from 'date-fns'
import { de, enUS, es, fr, ja, ko, pt, ru } from 'date-fns/locale'
import React, { useContext, useEffect, useState } from 'react'
import type { Matcher } from 'react-day-picker'
import { DayPicker } from 'react-day-picker'

import Input from '@/components/shared/ui/Input'
import type { PopoverContentPropType } from '@/components/shared/ui/Popover/Popover'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shared/ui/Popover/Popover'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import clsxm from '@/lib/clsxm'
import { isValidDate, processDateRange } from '@/lib/helpers/dateHelpers'

import Button from '../Button'
import Dialog from '../Dialog'
import { DialogContent, DialogTrigger } from '../Dialog/Dialog'
import DatePickerStyle from './DatePicker.module.scss'

interface IPropTypes {
  mode: any
  value?: string
  onDaySelect: (day: any) => void
  placeholder: string
  disableBeforeToday?: boolean
  disableAfterToday?: boolean
  numberOfMonths: number
  range?: any
  options?: any
  disabled?: boolean
  open?: boolean
  onClose?: (open: boolean) => void
  trigger?: boolean
  showInPopOver?: boolean
  borderless?: boolean
  dateFormat?: string
  clearable?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  disableFromDate?: any
  disableToDate?: any
  popoverContentProps?: Partial<PopoverContentPropType>
  includeTime?: boolean
  inline?: boolean
  renderFooter?: () => React.ReactNode
  renderHeader?: () => React.ReactNode
  canResetOnClick?: boolean
  min?: number
  max?: number
  className?: string
  renderCustomTrigger?: React.ReactNode
  rounded?: boolean
}

const dateLocale = {
  en: enUS,
  fr,
  es,
  de,
  ru,
  pt,
  ja,
  ko,
}

export default function DatePicker(props: IPropTypes) {
  const {
    mode = 'single',
    onDaySelect,
    placeholder = '',
    disableBeforeToday,
    disableAfterToday,
    numberOfMonths = 1,
    range: _range,
    options,
    disabled,
    open: _open = false,
    onClose,
    trigger = true,
    showInPopOver = false,
    dateFormat = 'dd MMM, yyyy',
    clearable = false,
    size = 'sm',
    disableFromDate,
    disableToDate,
    popoverContentProps = {},
    includeTime,
    inline = false,
    renderFooter,
    renderHeader,
    canResetOnClick,
    min,
    max,
    className,
    renderCustomTrigger,
    rounded = true,
  } = props
  const t = useTranslations()
  const { organizationSetting } = useContext(HNContext)

  const [selected, setSelected] = useState<any>(processDateRange(_range))
  const [open, setOpen] = useState<boolean>(_open)
  const isRange = Boolean(mode === 'range')
  const [timeValue, setTimeValue] = useState<string>(
    selected?.from && isValidDate(selected.from)
      ? format(selected.from, 'HH:mm')
      : ''
  )

  const getDisabledDays = () => {
    let config: Matcher = false
    if (disableBeforeToday) {
      config = {
        before: new Date(),
      }
    }
    if (disableFromDate) {
      config = {
        ...config,
        before: new Date(disableFromDate),
      }
    }
    if (disableAfterToday) {
      config = {
        ...config,
        after: new Date(),
      }
    }
    if (disableToDate) {
      config = {
        ...config,
        after: new Date(disableToDate),
      }
    }
    return config || false
  }

  const handleReset = () => {
    setOpen(false)
    onDaySelect(null)
  }

  const handleDayChange = (day: any, stayOpen?: boolean) => {
    onDaySelect(day)
    setSelected(day)
    if (!stayOpen) setOpen(false)
  }

  const handelDaySelect = (day: any) => {
    if (!day) {
      if (!canResetOnClick) return
      if (isRange) {
        setSelected({
          from: null,
          to: null,
        })
        handleDayChange(null, true)
      } else {
        setSelected(null)
        handleDayChange(null, true)
      }
    }
    if (!isRange) {
      const newDate = new Date(day)
      if (includeTime && timeValue) {
        const [hours, minutes] = timeValue
          .split(':')
          .map((str) => parseInt(str, 10))
        const newSelectedDate = new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          hours,
          minutes
        )
        handleDayChange(newSelectedDate)
        setSelected(newSelectedDate)
      } else {
        handleDayChange(day)
        setSelected(day)
      }
    } else setSelected(day)
  }

  const handleTimeChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!includeTime || mode !== 'single') return
    const time = e.target.value

    const selectedDate = new Date(selected.from)
    if (!selectedDate) {
      setTimeValue(time)
      return
    }
    const [hours, minutes] = time.split(':').map((str) => parseInt(str, 10))
    const newSelectedDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      hours,
      minutes
    )

    handleDayChange(newSelectedDate, true)
    setSelected({
      from: newSelectedDate,
      to: selected.to,
    })
    setTimeValue(time)
  }

  const handleDialogOpen = () => {
    if (disabled) setOpen(false)
    else setOpen(true)
  }

  const getByLabelText = () => {
    try {
      if (selected?.from && selected?.to) {
        return (
          <>
            {`${format(selected?.from, 'yyyy/MM/dd')} -
              ${format(selected?.to, 'yyyy/MM/dd')}`}
          </>
        )
      }
      if (selected?.from) {
        return <>{format(selected?.from, dateFormat)}</>
      }

      return <span className='text-gray10'>{placeholder}</span>
    } catch (_err) {
      return <span className='text-gray10'>{placeholder}</span>
    }
  }

  const handleDialogClose = () => {
    if (onClose) onClose(false)
    setOpen(false)
  }

  const handleClear = (event: any) => {
    event.stopPropagation()
    handleReset()
  }

  useEffect(() => {
    if (_range !== undefined) {
      setSelected(processDateRange(_range))
      setTimeValue(_range?.from ? format(_range?.from, 'HH:mm') : timeValue)
    }
  }, [open, _range])

  useEffect(() => {
    if (_open !== undefined) setOpen(_open)
  }, [_open])

  const renderOption = (option: any, i: number) => {
    const isSelected =
      isSameDay(option?.value?.from, selected?.from) &&
      isSameDay(option?.value?.to, selected?.to)
    return (
      <p
        key={i}
        onClick={() => handleDayChange(option?.value)}
        className={clsx(
          'cursor-pointer px-6 py-1 hover:bg-primary-light hover:text-primary-dark dark:hover:bg-primary-light',
          isSelected ? 'bg-primary text-snow dark:bg-primary-dark' : ''
        )}
      >
        {option.label}
      </p>
    )
  }

  const renderClearButton = () => {
    if (clearable && selected?.from) {
      return (
        <span onClick={(event) => handleClear(event)}>
          <X weight='bold' className='shrink-0 text-gray8 ' />
        </span>
      )
    }
    return <CaretDown weight='bold' className='shrink-0' />
  }

  const renderPopoverTrigger = () => {
    if (renderCustomTrigger)
      return (
        <PopoverTrigger
          asChild
          disabled={disabled}
          className='cursor-pointer appearance-none'
        >
          {renderCustomTrigger}
        </PopoverTrigger>
      )
    if (trigger) {
      return (
        <PopoverTrigger asChild disabled={disabled} className='appearance-none'>
          <div
            className={clsx(
              'group flex w-full items-center justify-between space-x-2 truncate border border-gray7 bg-snow font-medium text-gray12/80 transition duration-200 placeholder:text-gray12/70 hover:border-primary hover:bg-primary/5 hover:text-primary focus:outline-none focus:ring-primary dark:bg-gray-dark dark:placeholder:text-gray9',
              DatePickerStyle[`hnui-datepicker--${size}`],
              disabled ? 'cursor-not-allowed opacity-50' : '',
              className,
              rounded ? 'rounded-full' : 'rounded-lg'
            )}
          >
            <div
              className={clsx('w-full cursor-pointer appearance-none truncate')}
            >
              {getByLabelText()}
            </div>
            {renderClearButton()}
          </div>
        </PopoverTrigger>
      )
    }
    return null
  }

  const renderTrigger = () => {
    if (renderCustomTrigger) return renderCustomTrigger
    if (trigger)
      return (
        <div
          className={clsx(
            'inline-flex w-full min-w-[8.5rem] grow cursor-pointer items-center justify-between truncate rounded-full border bg-snow px-4 py-1 text-[12px] font-medium text-gray10 shadow-sm transition duration-200 focus:ring-0 focus-visible:bg-gray5 focus-visible:outline-none dark:border-gray-dark-border ',
            disabled ? 'cursor-not-allowed opacity-50' : ''
          )}
        >
          <DialogTrigger
            disabled={disabled}
            asChild
            onOpen={() => handleDialogOpen()}
          >
            <div
              className={clsx('w-full cursor-pointer appearance-none truncate')}
            >
              {getByLabelText()}
            </div>
          </DialogTrigger>
        </div>
      )

    return <></>
  }

  const renderContent = () => {
    return (
      <div
        className={clsxm(
          'flex items-start',
          showInPopOver ? 'divide-x divide-gray5 ' : ''
        )}
      >
        {options && (
          <div className='empty:hidden'>
            {options &&
              Object.keys(options)?.length &&
              options.map(renderOption)}
          </div>
        )}
        <div className='flex flex-col'>
          <div className='flex flex-col px-1'>
            {renderHeader?.()}
            <DayPicker
              mode={mode}
              // @ts-ignore
              locale={dateLocale[organizationSetting?.locale || 'en']}
              numberOfMonths={numberOfMonths}
              selected={selected}
              onSelect={handelDaySelect}
              disabled={getDisabledDays() || disabled}
              defaultMonth={_range?.from ? new Date(_range?.from) : undefined}
              min={min}
              max={max}
            />
            {includeTime && mode === 'single' ? (
              <Input
                type='time'
                value={timeValue}
                onChange={handleTimeChange}
                className='mb-2'
              />
            ) : null}
          </div>
          {isRange && (
            <div className='flex items-center justify-between border-t border-gray5 px-4 py-3 '>
              <Button
                variant='secondary'
                size='xxs'
                onClick={handleReset}
                type='button'
              >
                {t('common.reset')}
              </Button>
              <Button
                type='button'
                disabled={!(selected?.from && selected?.to)}
                size='xxs'
                onClick={() => handleDayChange(selected)}
              >
                {t('common.set')}
              </Button>
            </div>
          )}
          {renderFooter?.()}
        </div>
      </div>
    )
  }

  if (inline) return renderContent()
  if (showInPopOver) {
    return (
      <Popover open={open} onOpenChange={(show) => setOpen(show)}>
        {renderPopoverTrigger()}
        <PopoverContent
          className='!max-h-[100%] !w-auto !rounded-lg !p-0 md:!max-h-[24rem]'
          align='end'
          sideOffset={6}
          collisionPadding={8}
          {...popoverContentProps}
        >
          {renderContent()}
        </PopoverContent>
      </Popover>
    )
  }
  return (
    <Dialog
      open={open}
      onOpen={() => handleDialogOpen()}
      onClose={() => handleDialogClose()}
    >
      {renderTrigger()}
      <DialogContent size={isRange ? 'md' : 'xs'}>
        {renderContent()}
      </DialogContent>
    </Dialog>
  )
}
