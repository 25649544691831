import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import type { FC, ReactNode, Ref } from 'react'
import React from 'react'

import TooltipStyle from './Tooltip.module.scss'

const TooltipArrow: FC<any> = React.forwardRef(
  ({ children, ...props }, forwardedRef: Ref<any>) => (
    <TooltipPrimitive.Arrow
      {...props}
      ref={forwardedRef}
      className='fill-current text-gray4 '
    >
      {children}
    </TooltipPrimitive.Arrow>
  )
)

interface TooltipTriggerPropType {
  wrapperClass?: string
  asChild?: boolean
  children?: ReactNode
}
const TooltipTrigger: FC<TooltipTriggerPropType> = React.forwardRef(
  ({ wrapperClass, children, ...props }, forwardedRef: Ref<any>) => (
    <TooltipPrimitive.Trigger
      ref={forwardedRef}
      {...props}
      className={wrapperClass}
    >
      <div>{children}</div>
    </TooltipPrimitive.Trigger>
  )
)

interface TooltipContentPropType {
  text?: string | ReactNode
  side?: 'left' | 'right' | 'top' | 'bottom'
  customtext?: ReactNode
}
const TooltipContent: FC<TooltipContentPropType> = React.forwardRef(
  ({ text, ...props }, forwardedRef: Ref<any>) => (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        ref={forwardedRef}
        sideOffset={5}
        className={clsx(TooltipStyle['hnui-tooltip-content'])}
        {...props}
      >
        {text}
        {!text && props?.customtext}
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  )
)

interface TooltipPropType {
  text?: string | ReactNode
  placement?: 'left' | 'right' | 'top' | 'bottom'
  wrapperClass?: string
  asChild?: boolean
  children?: ReactNode
  show?: boolean
  customtext?: ReactNode
  disableHoverableContent?: boolean
}
const Tooltip: FC<TooltipPropType> = ({
  children,
  text,
  placement = 'bottom',
  wrapperClass,
  asChild = false,
  show = true,
  customtext,
  ...props
}: TooltipPropType) => {
  if (!show) return <>{children}</>
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root {...props} delayDuration={0}>
        <TooltipTrigger wrapperClass={wrapperClass} asChild={asChild}>
          {children}
        </TooltipTrigger>
        <TooltipContent side={placement} text={text} customtext={customtext} />
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

Tooltip.displayName = 'Tooltip'
TooltipTrigger.displayName = 'TooltipTrigger'
TooltipContent.displayName = 'TooltipContent'
TooltipArrow.displayName = 'TooltipArrow'
export default Tooltip
