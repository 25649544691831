import { CaretRight } from '@phosphor-icons/react'
import * as ContextMenuPrimitive from '@radix-ui/react-context-menu'
import clsx from 'clsx'
import type { FC } from 'react'
import React from 'react'

import clsxm from '@/lib/clsxm'

import UnstyledLink from '../Links/UnstyledLink'
import ContextMenuStyle from './ContextMenu.module.scss'

interface IContextMenu {
  children?: React.ReactNode
  className?: string
}

interface IContextMenuSubContentProps
  extends ContextMenuPrimitive.ContextMenuSubContentProps {}

export const ContextSubContent: FC<IContextMenuSubContentProps> = ({
  children,
  ...props
}) => (
  <ContextMenuPrimitive.Portal {...props}>
    <ContextMenuPrimitive.ContextMenuSubContent
      sideOffset={2}
      alignOffset={5}
      className={clsx(ContextMenuStyle['hnui-contextmenu-content'])}
    >
      {children}
    </ContextMenuPrimitive.ContextMenuSubContent>
  </ContextMenuPrimitive.Portal>
)

interface IContextMenuSubTriggerProps
  extends ContextMenuPrimitive.ContextMenuSubTriggerProps {
  icon?: React.ReactNode
  itemValue?: React.ReactNode
  RightIcon?: React.ReactNode
  color?: 'default' | 'red'
}

export const ContextSubTrigger: FC<IContextMenuSubTriggerProps> = ({
  children,
  color = 'default',
  disabled,
  onSelect,
  icon,
  itemValue,
  RightIcon,
  ...props
}) => (
  <ContextMenuPrimitive.SubTrigger
    className={clsx(
      ContextMenuStyle['hnui-contextmenu-item'],
      color === 'red'
        ? ContextMenuStyle['hnui-contextmenu-item-text-red']
        : ContextMenuStyle['hnui-contextmenu-item-text-default']
    )}
    disabled={disabled}
    onSelect={onSelect}
    {...props}
  >
    {!children && (
      <div className={ContextMenuStyle['hnui-contextmenu-list-item']}>
        <div className={ContextMenuStyle['hnui-contextmenu-item-text']}>
          <span className='shrink-0'>{icon}</span>
          <span className='truncate'>{itemValue}</span>
        </div>
        <div>
          <span className='shrink-0'>{RightIcon}</span>
          <CaretRight />
        </div>
      </div>
    )}
    {Boolean(children) && children}
  </ContextMenuPrimitive.SubTrigger>
)

interface IContextMenuSubProps
  extends ContextMenuPrimitive.ContextMenuSubProps {}

export const ContextSub: FC<IContextMenuSubProps> = ({
  children,
  ...props
}) => <ContextMenuPrimitive.Sub {...props}>{children}</ContextMenuPrimitive.Sub>

export const ContextSeparator: FC<IContextMenu> = ({
  children,
  className,
  ...props
}) => (
  <ContextMenuPrimitive.Group
    {...props}
    className={clsx(ContextMenuStyle['hnui-contextmenu-separator'], className)}
  >
    {children}
  </ContextMenuPrimitive.Group>
)

export const ContextGroup: FC<IContextMenu> = ({ children, ...props }) => (
  <ContextMenuPrimitive.Separator {...props}>
    {children}
  </ContextMenuPrimitive.Separator>
)

interface IContextMenuContentItemProps
  extends ContextMenuPrimitive.ContextMenuItemProps {
  color?: 'default' | 'red'
  icon?: React.ReactNode
  itemValue?: React.ReactNode
  href?: string
  RightIcon?: React.ReactNode
  target?: '_blank' | '_self' | '_parent' | '_top'
}

export const ContextItem: FC<IContextMenuContentItemProps> = ({
  children,
  color = 'default',
  disabled,
  onSelect,
  icon,
  itemValue,
  href,
  RightIcon,
  target,
  ...props
}) => (
  <ContextMenuPrimitive.Item
    {...props}
    className={clsx(
      color === 'red'
        ? ContextMenuStyle['hnui-contextmenu-item-text-red']
        : ContextMenuStyle['hnui-contextmenu-item-text-default'],
      !children && href ? '' : ContextMenuStyle['hnui-contextmenu-item']
    )}
    disabled={disabled}
    onSelect={onSelect}
    // asChild={Boolean(!children && href)}
  >
    {!children && !href && (
      <div className={ContextMenuStyle['hnui-contextmenu-list-item']}>
        <div className={ContextMenuStyle['hnui-contextmenu-item-text']}>
          <span className='shrink-0'>{icon}</span>
          <span className='truncate'>{itemValue}</span>
        </div>
        <span className='shrink-0'>{RightIcon}</span>
      </div>
    )}
    {!children && href && (
      <UnstyledLink
        href={href}
        target={target}
        className={clsxm(
          ContextMenuStyle['hnui-contextmenu-list-item'],
          ContextMenuStyle['hnui-contextmenu-item']
        )}
        // {...props}
      >
        <>
          <div className={ContextMenuStyle['hnui-contextmenu-item-text']}>
            <span className='shrink-0'>{icon}</span>
            <span className='truncate'>{itemValue}</span>
          </div>
          <span className='shrink-0'>{RightIcon}</span>
        </>
      </UnstyledLink>
    )}
    {Boolean(children) && children}
  </ContextMenuPrimitive.Item>
)

export const ContextLabel: FC<IContextMenu> = ({ children, ...props }) => (
  <ContextMenuPrimitive.Label
    {...props}
    className={ContextMenuStyle['hnui-contextmenu-label']}
  >
    {children}
  </ContextMenuPrimitive.Label>
)

interface IContextMenuContentProps
  extends ContextMenuPrimitive.ContextMenuContentProps {}

export const ContextContent: FC<IContextMenuContentProps> = ({
  children,
  ...props
}) => (
  <ContextMenuPrimitive.Portal {...props}>
    <ContextMenuPrimitive.Content
      className={clsx(ContextMenuStyle['hnui-contextmenu-content'])}
    >
      {children}
    </ContextMenuPrimitive.Content>
  </ContextMenuPrimitive.Portal>
)

interface IContextMenuTriggerProps
  extends ContextMenuPrimitive.ContextMenuTriggerProps {}

export const ContextTrigger: FC<IContextMenuTriggerProps> = ({
  children,
  ...props
}) => (
  <ContextMenuPrimitive.Trigger
    {...props}
    className={clsx(
      ContextMenuStyle['hnui-contextmenu-trigger'],
      props.className
    )}
  >
    {children}
  </ContextMenuPrimitive.Trigger>
)

interface IContextMenuProps extends ContextMenuPrimitive.ContextMenuProps {}

export const ContextMenu: FC<IContextMenuProps> = ({ children, ...props }) => (
  <ContextMenuPrimitive.Root {...props}>{children}</ContextMenuPrimitive.Root>
)

ContextMenu.displayName = 'ContextMenu'
export default ContextMenu
