import {
  Bell,
  Cookie,
  Moon,
  SignOut,
  User,
  UserSwitch,
} from '@phosphor-icons/react'
import router from 'next/router'
import React, { useContext } from 'react'

import SignoutTrigger from '@/components/shared/components/module/auth/SignoutTrigger'
import UserAvatar from '@/components/shared/components/UserAvatar'
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownLabel,
  DropdownSeparator,
  DropdownTrigger,
} from '@/components/shared/ui/Dropdown/Dropdown'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { EventEmitter } from '@/lib/eventEmitter'
import { pluralize } from '@/lib/helpers/appHelpers'
import { userNotificationsPath } from '@/lib/helpers/pathHelpers'
import { setTheme } from '@/lib/theming'
import { toggleUserTheme } from '@/models/User'

interface IPropTypes {
  size?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  side?: string
  sideOffset?: number
  className?: string
}
export default function UserProfileDropdown(props: IPropTypes) {
  const {
    notificationsCount,
    isAdmin,
    userProfile,
    organizationCustomization,
    theme,
    updateContext,
  } = useContext(HNContext)
  const { size, className } = props
  const t = useTranslations('header.profileDropdown')

  const handleRedirect = (slug: string) => {
    router.push(slug)
  }
  const notificationPath = userNotificationsPath()

  const handleThemeToggle = () => {
    toggleUserTheme().then(() => {
      if (theme) {
        const newTheme = { ...theme, darkMode: !theme.darkMode }
        setTheme(newTheme)
        if (updateContext) updateContext({ theme: newTheme })
      }
    })
  }
  if (!userProfile) return <></>
  return (
    <Dropdown>
      <DropdownTrigger asChild={true}>
        <div className={className}>
          <UserAvatar
            user={userProfile}
            showdot={!!notificationsCount && !isAdmin}
            rounded
            size={size}
            hideTooltip={isAdmin}
          />
        </div>
      </DropdownTrigger>
      <DropdownContent side={props.side as any} sideOffset={props.sideOffset}>
        <DropdownLabel>{userProfile?.name}</DropdownLabel>
        <DropdownItem
          itemValue={t('myProfile')}
          icon={<User size={15} />}
          onSelect={() => handleRedirect('/profile')}
        />
        <DropdownItem
          itemValue={`${notificationsCount || ''} ${pluralize(
            notificationsCount || 0,
            t('notification'),
            t('notifications')
          )}`}
          onSelect={() => handleRedirect(notificationPath)}
          icon={<Bell size={15} />}
          color={notificationsCount ? 'red' : ''}
        />
        <DropdownItem
          icon={<Moon size={15} />}
          onSelect={handleThemeToggle}
          itemValue={theme?.darkMode ? t('lightMode') : t('darkMode')}
        />
        <DropdownSeparator />
        {organizationCustomization?.user_profile_preferences
          ?.hide_switch_accounts &&
        !userProfile?.is_csm_of_organization ? null : (
          <>
            <DropdownItem
              href={`//portal.${process.env.NEXT_PUBLIC_HOSTED_DOMAIN}`}
              target='_blank'
              icon={<UserSwitch size={15} />}
              itemValue={t('switchOrg')}
            />
            <DropdownSeparator />
          </>
        )}
        <DropdownItem
          onSelect={() => {
            EventEmitter.dispatch('OPEN_COOKIES_MODAL', {})
          }}
          icon={<Cookie size={15} />}
          itemValue={'Cookie Settings'}
        />
        <DropdownSeparator />
        <SignoutTrigger as='div'>
          <DropdownItem
            icon={<SignOut size={15} />}
            itemValue={t('auth.labels.signOut', { ignorePrefix: true })}
          />
        </SignoutTrigger>
      </DropdownContent>
    </Dropdown>
  )
}
