import type { IVariantTypes } from '@/components/shared/ui/types/element'
import { ENV } from '@/config/environment'
import type { IKeyValueMap } from '@/types/common'
import type { IRoadmapStatus } from '@/types/roadmap'
import type { IUserRole } from '@/types/user'
import type { IWebhookEventGroup } from '@/types/webhook'

export const { HN_SECRET } = process.env
export const SERVER_USER_AGENT =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 13_1) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.1 Safari/605.1.15'

export const APP_NAME = 'FeatureOS'

export const PORTAL_URL = `//portal.${process.env.NEXT_PUBLIC_HOSTED_DOMAIN}`
export const APP_VERSION = 2467
export const SSO_TOKEN_MIN_LENGTH = 30

export const DOMAIN =
  ENV.APP_ENV === 'production' ? 'featureos.app' : 'featureos.app'
export const KB_DOMAIN =
  ENV.APP_ENV === 'production' ? 'featureos.help' : 'featureos.help'
export const SUPPORT_EMAIL = `support@featureos.app`
export const HELP_PAGE_DOMAIN = `help.featureos.app`

export const WIDGET_URL = `https://widgets-v3.${DOMAIN}/widget.js`
// Session key used for storing the auth token of the logged in user
export const HN_SESSION_KEY = 'hn-session'

// Resend timer
export const RESEND_OTP_TIMER = 30

// Pagination Threshold
export const POST_LIST_PAGINATION_THRESHOLD = 30
export const CHANGELOG_PAGINATION_THRESHOLD = 30

// Date constants
export const LETTERBOX_THRESHOLD_DATE = '2022-12-14'
export const TAT_SUMMARY_THRESHOLD_DATE = '2022-12-14'
export const V3_WIDGET_THRESHOLD_DATE = '2023-08-17'
export const DATE_FORMAT = 'yyyy-MM-dd'

export const ORG_HEADER_KEY = 'X-Organization'

export const THIRD_PARTY_APPS = {
  salesforce: {
    name: 'Salesforce',
    icon: `https://static.${DOMAIN}/logos/salesforce.svg`,
  },
  linear: {
    name: 'Linear',
    icon: `https://static.${DOMAIN}/logos/linear.svg`,
  },
  clickup: {
    name: 'ClickUp',
    icon: `https://static.${DOMAIN}/logos/clickup.svg`,
  },
  github: {
    name: 'GitHub',
    icon: `https://static.${DOMAIN}/logos/github.svg`,
  },
  gitlab: {
    name: 'GitLab',
    icon: `https://static.${DOMAIN}/logos/gitlab.svg`,
  },
  apple: {
    name: 'Apple',
    icon: `https://static.${DOMAIN}/logos/apple.svg`,
  },
  google: {
    name: 'Google',
    icon: `https://static.${DOMAIN}/logos/google.svg`,
  },
  azure: {
    name: 'Azure AD',
    icon: `https://static.${DOMAIN}/logos/azure.svg`,
  },
  sso: {
    name: 'SSO',
  },
  passkey: {
    name: 'Passkey',
  },
  discord: {
    name: 'Discord',
    icon: `https://static.${DOMAIN}/logos/discord.svg`,
  },
  slack: {
    name: 'Slack',
    icon: `https://static.${DOMAIN}/logos/slack.svg`,
  },
  zendesk: {
    name: 'Zendesk',
    icon: `https://static.${DOMAIN}/logos/zendesk.svg`,
  },
  jira: {
    name: 'Jira',
    icon: `https://static.${DOMAIN}/logos/jira.svg`,
  },
  intercom: {
    name: 'Intercom',
    icon: `https://static.${DOMAIN}/logos/intercom.svg`,
  },
  zapier: {
    name: 'Zapier',
    icon: `https://static.${DOMAIN}/logos/zapier.svg`,
  },
  asana: {
    name: 'Asana',
    icon: `https://static.${DOMAIN}/logos/asana.svg`,
  },
  patreon: {
    name: 'Patreon',
    icon: `https://static.${DOMAIN}/logos/patreon.svg`,
  },
}

// the default meta to use when the meta call fails
export const ROOT_META = {
  title: APP_NAME,
  favicon: '/favicon.png',
  image: `https://static.${DOMAIN}/opengraph/og.png?ref=newog`,
  description: `Collect, organize and respond to customer feedback and feature requests using ${APP_NAME}. Extremely simple and powerful.`,
  theme: {
    darkMode: null,
    brandColor: '#0061fe',
    brandColorLight: '#C8C8FF',
    brandColorDark: '#2B3FA3',
  },
  organization: {
    privacy: false,
    locale: 'en',
  },
}

export const CACHE_HASH_PREFIX = 'hn-cache#'

// Resource names
export const POST = 'FeatureRequest'
export const CHANGELOG = 'Changelog'

export const ROLES: IUserRole[] = ['admin', 'member', 'csm']
export const ROOT_STATUS = [
  'under_review',
  'planned',
  'in_progress',
  'completed',
  'closed',
]

interface ILanguage {
  label: string
  value: string
  otherValue?: string[]
}
export const LANGUAGES: ILanguage[] = [
  {
    label: 'English',
    value: 'en',
    otherValue: ['en-US'],
  },
  {
    label: 'Française',
    value: 'fr',
    otherValue: ['fr-FR'],
  },
  {
    label: 'Español',
    value: 'es',
    otherValue: ['es-ES'],
  },
  {
    label: 'Deutsch',
    value: 'de',
    otherValue: ['de-DE'],
  },
  {
    label: 'Русский',
    value: 'ru',
    otherValue: ['ru-RU'],
  },
  {
    label: 'Português',
    value: 'pt',
    otherValue: ['pt-PT'],
  },
  {
    label: '日本語',
    value: 'ja',
    otherValue: ['ja-JP'],
  },
  {
    label: '한국어',
    value: 'ko',
    otherValue: ['ko-KR'],
  },
  {
    label: '中文',
    value: 'zh',
    otherValue: ['zh-CN'],
  },
  {
    label: '中文（繁體）',
    value: 'zh-TW',
    otherValue: ['zh-HK', 'zh-MO'],
  },
  {
    label: 'Português (Brasil)',
    value: 'pt-BR',
    otherValue: ['pt-BR'],
  },
  {
    label: 'Italiano',
    value: 'it',
    otherValue: ['it-IT'],
  },
  {
    label: 'Nederlands',
    value: 'nl',
    otherValue: ['nl-NL'],
  },
  {
    label: 'Bahasa Indonesia',
    value: 'id',
    otherValue: ['id-ID'],
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
    otherValue: ['vi-VN'],
  },
  {
    label: 'ไทย',
    value: 'th',
    otherValue: ['th-TH'],
  },
  {
    label: 'Polski',
    value: 'pl',
    otherValue: ['pl-PL'],
  },
  {
    label: 'Svenska',
    value: 'sv',
    otherValue: ['sv-SE'],
  },
  {
    label: 'Bahasa Melayu',
    value: 'ms',
    otherValue: ['ms-MY'],
  },
  {
    label: 'Filipino',
    value: 'tl',
    otherValue: ['tl-PH'],
  },
  {
    label: 'Dansk',
    value: 'da',
    otherValue: ['da-DK'],
  },
]

// Convert LANGUAGES to a map with the value in array as teh value and the entire object as the value of new map

export const LANGUAGES_MAP: IKeyValueMap = LANGUAGES.reduce(
  (acc, lang) => ({
    ...acc,
    [lang.value]: lang,
  }),
  {}
)

export const APP_THEMES = [
  {
    labelKey: 'lightMode',
    value: 'light_mode',
  },
  {
    labelKey: 'darkMode',
    value: 'dark_mode',
  },
]

export const WEBHOOK_EVENTS: IWebhookEventGroup[] = [
  {
    titleKey: 'postEvents',
    events: [
      {
        id: '1',
        value: 'post_created',
        label: 'postCreated',
        description: 'Post Created',
      },
      {
        id: '1',
        value: 'post_updated',
        label: 'postUpdated',
        description: 'Post Updated',
      },
      {
        id: '1',
        value: 'post_completed',
        label: 'postCompleted',
        description: 'Post Completed',
      },
    ],
  },
  {
    titleKey: 'changelogEvents',
    events: [
      {
        id: '2',
        value: 'changelog_created',
        label: 'changelogCreated',
        description: 'Changelog Created',
      },
      {
        id: '2',
        value: 'changelog_updated',
        label: 'changelogUpdated',
        description: 'Changelog Updated',
      },
      {
        id: '2',
        value: 'changelog_published',
        label: 'changelogPublished',
        description: 'Changelog Published',
      },
    ],
  },
]

export const CLASSNAMES = {
  otherLayoutBox: 'border border-gray5  rounded p-5 my-5 mx-auto max-w-xs',
  settingTitle: 'font-medium !text-carbon  transition mb-1',
  settingsWrapper: 'w-full max-w-3xl mx-auto px-4 py-3 mb-6 rounded-lg',
  diffHover: 'hover:bg-gray3  transition',
  settingsButton:
    'rounded-md font-medium text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary dark:focus:ring-offset-gray12 mb-0 cursor-pointer transition disabled:opacity-25',
  listItem:
    'flex h-[38px] cursor-pointer items-center border-b-[0.5px] border-b-gray5 bg-snow transition-colors duration-200 hover:bg-gray4/80 focus-visible:bg-gray6/40 focus-visible:outline-none dark:border-b-gray-dark-border dark:bg-gray-dark dark:hover:bg-gray-dark-hover  font-medium',
}
export const appEnvironment = process.env.NEXT_PUBLIC_APP_ENV || 'development'

interface IStatusKeyPair {
  color: IVariantTypes
  label: string
  value: string
  style: string
}
export const KB_ARTICLE_STATUS: {
  [key: string]: IStatusKeyPair
} = {
  draft: {
    color: 'yellow',
    label: 'draft',
    value: 'in_progress',
    style: 'text-yellow11 bg-yellow3 dark:bg-opacity-75',
  },
  published: {
    color: 'green',
    label: 'published',
    value: 'completed',
    style: 'text-green11 bg-green3 dark:bg-opacity-75',
  },
  scheduled: {
    color: 'blue',
    label: 'scheduled',
    value: 'scheduled',
    style: 'text-purple11 bg-purple3 dark:bg-opacity-75',
  },
}

export const STATUS_COLORS_MAP: IKeyValueMap = {
  under_review: {
    style: 'text-blue11 bg-blue3 dark:bg-opacity-75',
    text: 'text-blue11 ',
    icon: 'text-blue10 ',
  },
  planned: {
    style: 'text-purple11 bg-purple3   dark:bg-opacity-75',
    text: 'text-purple11 ',
    icon: 'text-purple9 ',
  },
  in_progress: {
    style: 'text-yellow11 bg-yellow3 dark:bg-opacity-75',
    text: 'text-yellow11 ',
    icon: 'text-yellow8 ',
  },
  completed: {
    style: 'text-green11 bg-green3   dark:bg-opacity-75',
    text: 'text-green11 ',
    icon: 'text-green9 ',
  },
  closed: {
    style: 'text-red11 bg-red3 dark:bg-opacity-75',
    text: 'text-red11 ',
    icon: 'text-red9 ',
  },
}

export const CHANGELOG_STATUS_COLORS_MAP: IKeyValueMap = {
  draft: {
    style: 'text-gray11 bg-gray11 dark:bg-opacity-75',
    icon: 'bg-gray10',
    text: 'text-gray10 ',
  },
  published: {
    style: 'text-green11 bg-green11 dark:bg-opacity-75',
    icon: 'bg-green8',
    text: 'text-green9 ',
  },
  scheduled: {
    style: 'text-yellow11 bg-yellow11 dark:bg-opacity-75',
    icon: 'bg-yellow8 ',
    text: 'text-yellow8 ',
  },
  in_review: {
    style: 'text-blue11 bg-blue11 dark:bg-opacity-75',
    icon: 'bg-blue8',
    text: 'text-blue9 ',
  },
}

export const ROADMAP_STATUS_LIST_SORT_ORDER = [
  'backlog',
  'planned',
  'in_progress',
  'paused',
  'completed',
  'cancelled',
]

export const ROADMAP_STATUS_LIST: {
  value: IRoadmapStatus
  color: string
  iconClass: string
}[] = [
  {
    value: 'in_progress',
    color: 'blue',
    iconClass: 'text-yellow8 ',
  },
  {
    value: 'planned',
    color: 'yellow',
    iconClass: 'text-purple9 ',
  },
  {
    value: 'backlog',
    color: 'gray',
    iconClass: 'text-blue10 ',
  },
  {
    value: 'completed',
    color: 'green',
    iconClass: 'text-green9 ',
  },
  {
    value: 'paused',
    color: 'gray',
    iconClass: 'text-gray9 ',
  },
  {
    value: 'cancelled',
    color: 'red',
    iconClass: 'text-red9 ',
  },
]

export const PAGINATION_LIMIT = {
  comment: 10,
  roadmapItems: 30,
  votersList: 30,
  adminSearchPostsList: 30,
  userSearchPostsList: 30,
  adminPostsList: 100,
}

export const FILTER_KEYS = {
  domain: 'domains',
  tags: 'tag_ids',
  bucket: 'bucket_ids',
  status: 'states',
}

export const MIGRATE_TO_PADDLE_CONTACT_SUPPORT_CONTENT = `Hi Team,

I would like to migrate my {appName} subscription to Paddle. Can you help?

Thank you!`

export const ICON_URL_PREFIX = `https://assets.featureos.app/featureos/app-assets/icons`

export const allowedVideoFileTypes = ['.mp4', '.mov']
