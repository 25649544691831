import { CheckCircle, Confetti, Info, XCircle } from '@phosphor-icons/react'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import clsx from 'clsx'
import type { FC, ReactNode } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import Label from '@/components/shared/ui/Label/Label'
import { useTranslations } from '@/hooks/useTranslations'
import { isPromise } from '@/lib/helpers/appHelpers'

import Button from '../Button/Button'
import AlertDialogStyle from './AlertDialog.module.scss'

const renderIcon = (type: string) => {
  if (type === 'success')
    return (
      <CheckCircle
        weight='fill'
        className={AlertDialogStyle['hnui-alert-success']}
      />
    )
  if (type === 'danger')
    return (
      <XCircle
        weight='fill'
        className={AlertDialogStyle['hnui-alert-danger']}
      />
    )
  if (type === 'confetti')
    return (
      <Confetti
        weight='fill'
        className={AlertDialogStyle['hnui-alert-confetti']}
      />
    )
  return <Info weight='fill' className={AlertDialogStyle['hnui-alert-info']} />
}
// TODO Need to check on this usage
export type IAlertDialogVariant =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'link'
  | 'text'
export type IAlertDialogType = 'success' | 'danger' | 'info' | 'confetti'
interface AlertDialogPropTypes {
  type?: IAlertDialogType
  variant?: IAlertDialogVariant
  open?: boolean
  children?: ReactNode
  cancelText?: string
  confirmText?: string
  description?: string
  descriptionComponent?: ReactNode
  onlyCancel?: boolean
  waitForPromise?: boolean
  title?: string
  onConfirm?: (...args: any[]) => any
  onCancel?: (...args: any[]) => any
  onClose?: (...args: any[]) => any
  onOpen?: (...args: any[]) => any
  disabled?: boolean
  confirmValue?: any
  showCheckbox?: boolean
  checked?: boolean
  checkboxLabel?: string
}

const AlertDialog: FC<AlertDialogPropTypes> = ({
  type = 'info',
  variant = 'primary',
  open = false,
  children = null,
  cancelText = '',
  confirmText = '',
  description = '',
  descriptionComponent = null,
  onlyCancel = false,
  waitForPromise = true,
  title = 'Are you sure?',
  onConfirm = () => {},
  onCancel = () => {},
  onClose = () => {},
  onOpen = () => {},
  disabled = false,
  confirmValue: _confirmValue = null,
  showCheckbox = false,
  checked: _checked = false,
  checkboxLabel = '',
  ...props
}) => {
  const t = useTranslations()
  const confirmButtonText = confirmText || t('buttons.confirm')
  const cancelButtonText = cancelText || t('buttons.cancel')
  const [dialogOpen, setDialogOpen] = useState(open)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmValue, setConfirmValue] = useState(_confirmValue)
  const [checked, setChecked] = useState(_checked)
  const currentState = useRef(open)

  const handleConfirm = () => {
    if (onConfirm && typeof onConfirm === 'function') {
      const result = onConfirm(confirmValue, checked)
      if (isPromise(result) && waitForPromise) {
        setIsLoading(true)
        return result
          .then(() => setDialogOpen(false))
          .finally(() => setIsLoading(false))
      }
      setDialogOpen(false)
      return setIsLoading(false)
    }
    return false
  }

  const handleCancel = () => {
    setChecked(false)
    onCancel()
  }

  useEffect(() => {
    setDialogOpen(open)
  }, [open])

  useEffect(() => {
    return () => {
      setDialogOpen(false)
    }
  }, [])

  useEffect(() => {
    setConfirmValue(_confirmValue)
  }, [_confirmValue])

  useEffect(() => {
    if (dialogOpen && !currentState.current) {
      onOpen()
    }
    if (!dialogOpen && currentState.current) {
      onClose()
      setChecked(false)
    }
    currentState.current = dialogOpen
  }, [dialogOpen])

  return (
    <AlertDialogPrimitive.Root
      {...props}
      open={dialogOpen}
      onOpenChange={setDialogOpen}
    >
      <AlertDialogPrimitive.Trigger asChild {...props}>
        {children}
      </AlertDialogPrimitive.Trigger>
      <AlertDialogPrimitive.Portal {...props}>
        <AlertDialogPrimitive.Overlay
          className={AlertDialogStyle['hnui-alert-overlay']}
        />
        <AlertDialogPrimitive.Content
          {...props}
          data-testid='confirmation_modal'
          className={AlertDialogStyle['hnui-alert-content']}
        >
          <div className={AlertDialogStyle['hnui-alert-icon']} aria-hidden>
            {renderIcon(type)}
          </div>
          <div className={AlertDialogStyle['hnui-alert-flex']}>
            <AlertDialogPrimitive.Title
              {...props}
              className={AlertDialogStyle['hnui-alert-title']}
              data-testid='confirmation_modal_title'
            >
              {title}
            </AlertDialogPrimitive.Title>
            {!descriptionComponent && description && (
              <AlertDialogPrimitive.Description
                className={clsx(
                  AlertDialogStyle['hnui-alert-description'],
                  'w-full'
                )}
                dangerouslySetInnerHTML={{ __html: description }}
                {...props}
              />
            )}
            {descriptionComponent && !description && (
              <AlertDialogPrimitive.Description
                className={clsx(
                  AlertDialogStyle['hnui-alert-description'],
                  'w-full'
                )}
                {...props}
              >
                {descriptionComponent}
              </AlertDialogPrimitive.Description>
            )}
            <div
              className={clsx(
                AlertDialogStyle['hnui-alert-actions'],
                showCheckbox ? 'sm:justify-between ' : 'justify-end'
              )}
              data-testid='confirmation-modal'
            >
              {showCheckbox && (
                <div className='flex items-center justify-start space-x-1'>
                  <input
                    type='checkbox'
                    className='cursor-pointer rounded border border-gray6 bg-snow transition-opacity duration-200 checked:border-primary-dark checked:bg-brand hover:checked:border-primary-dark checked:hover:bg-brand focus:ring-transparent focus:ring-offset-0 checked:focus:bg-brand dark:checked:bg-brand'
                    id='alertCheck'
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                  <Label
                    htmlfor='alertCheck'
                    className='text whitespace-nowrap font-bold'
                  >
                    {checkboxLabel}
                  </Label>
                </div>
              )}
              <div className='flex items-center justify-end space-x-1'>
                <AlertDialogPrimitive.Cancel asChild>
                  <Button
                    variant='text'
                    onClick={handleCancel}
                    dataTestId='confirmation-modal-cancel-button'
                  >
                    {cancelButtonText}
                  </Button>
                </AlertDialogPrimitive.Cancel>
                {!onlyCancel && (
                  <Button
                    variant={type === 'danger' ? 'danger' : variant}
                    onClick={handleConfirm}
                    disabled={isLoading || disabled}
                    loading={isLoading}
                    dataTestId='confirmation-modal-confirm-button'
                  >
                    {confirmButtonText}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </AlertDialogPrimitive.Content>
      </AlertDialogPrimitive.Portal>
    </AlertDialogPrimitive.Root>
  )
}
export default AlertDialog
