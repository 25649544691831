import clsx from 'clsx'

import Avatar from '@/components/shared/ui/Avatar'

import SelectStyle from './Select.module.scss'

interface SelectItemPropType {
  children?: React.ReactNode
  disabled?: boolean
  icon?: React.ReactNode
  endIcon?: React.ReactNode
  itemvalue: string
  itemSubValue?: string
  RightSlot?: React.ReactNode
  onSelect?: (e: any) => any
  avatarUrl?: string
  showAvatar?: boolean
  className?: string
  isHighlighted?: boolean
  onHover?: (e: any) => any
  index: number
  dataTestId?: string
}

const SelectItem = ({
  children,
  disabled,
  icon,
  endIcon,
  RightSlot,
  onSelect,
  className,
  itemvalue,
  itemSubValue,
  avatarUrl,
  showAvatar,
  isHighlighted,
  onHover,
  index,
  dataTestId = '',
  ...props
}: SelectItemPropType) => (
  <div
    {...props}
    tabIndex={-1}
    className={clsx(SelectStyle['hnui-Select-item'], className, {
      'rounded-lg bg-gray4 text-gray11': isHighlighted,
    })}
    onClick={disabled ? undefined : onSelect}
    onMouseDown={(e) => e.preventDefault()}
    onMouseOver={onHover}
    data-focus-id={`select-item-${index}`}
    data-testid={dataTestId}
  >
    {!children && (
      <div className={SelectStyle['hnui-Select-list-item']}>
        <div className={SelectStyle['hnui-Select-item-text']}>
          {Boolean(icon && !showAvatar) && (
            <span className='flex h-4 w-4 shrink-0 items-center empty:hidden'>
              {icon}
            </span>
          )}
          {Boolean(showAvatar && !icon) && (
            <Avatar url={avatarUrl} name={itemvalue} size='xxs' rounded />
          )}
          <div className='flex flex-col'>
            <p className='truncate'>{itemvalue}</p>
            {itemSubValue && (
              <span className='truncate text-xs'>{itemSubValue}</span>
            )}
          </div>
          {endIcon && <span className='shrink-0'>{endIcon}</span>}
        </div>
        {RightSlot}
      </div>
    )}
    {children && children}
  </div>
)

export default SelectItem
